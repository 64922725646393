import { IonReactRouter } from '@ionic/react-router';
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import NewPassword from '../pages/auth/newPassword/newPassword';
import Assessment from '../pages/assessment/Assessment';
// Pages
const Login = lazy(() => import('../pages/auth/login/Login'))
const Register = lazy(() => import('../pages/auth/register/Register'))
const ResetPassword = lazy(() => import('../pages/auth/resetPassword/ResetPassword'))

const SignRoutes: React.FC = () => {
  return (
    <IonReactRouter >
      <Route path='/' component={Login} />
      <Route path='/criar-conta' component={Register} exact />
      <Route path='/resetar-senha' component={ResetPassword} />
      <Route path='/redefinir-senha/:token' component={NewPassword} />
      <Route path='/avaliacao/:id/:supplierId/:score' component={Assessment} exact />
    </IonReactRouter>
  )
}

export default SignRoutes;