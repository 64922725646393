import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { ILegacyEvent, INextEvents } from '../nextEvents.types'
import { formatDate } from '../../../utils/google-auth/dateFunctions'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import {
  alertOutline,
  calendarOutline,
  cashOutline,
  checkmarkOutline,
  listOutline,
} from 'ionicons/icons'
import { usePayments } from '../../wallet/hooks/usePayments'
import { useImageUpload } from '../../imageUpload/hooks/useImageUpload'
import { IPayments } from '../../wallet/wallet.types'
import axios from 'axios'
import { convertToBRACurrency } from '../../../utils/google-auth/currencyFunctions'

interface NextEventsPanelProps {
  nextEvents?: INextEvents[]
  legacyEvents?: ILegacyEvent[]
}

export const NextEventsPanel = ({ nextEvents, legacyEvents }: NextEventsPanelProps) => {
  // Hooks
  const { payments } = usePayments()
  const modal = useRef<HTMLIonModalElement>(null);

  // States
  const [selectedPdf, setSelectedPdf] = useState<File[]>([])
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const { uploadImage } = useImageUpload()
  const [paymentId, setPaymentId] = useState<string>('');
  // const isSubmitAble:boolean = teste()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<INextEvents | null>(null)

  // Legacy contracts from frankstein
  const legacyContractModal = useRef<HTMLIonModalElement>(null)
  const [legacyInvoiceFiles, setLegacyInvoiceFiles] = useState<any[]>([])



  function invoiceStatusName(status: string) {
    switch (status) {
      case 'rejected':
        return ['Rejeitada', 'danger']
        break

      case 'approved':
        return ['Aprovada', 'success']
        break

      default:
        return ['Aguardando aprovação', 'warning']
        break
    }
  }

  const handleOpenModal = (event: INextEvents) => {
    setSelectedEvent(event)
    setIsModalOpen(true)
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setIsModalOpen(false)
    setSelectedEvent(null)
    if (ev.detail.role === 'confirm') {
      // setMessage(`Hello, ${ev.detail.data}!`)
    }
    setLegacyInvoiceFiles([])
    return ev
  }

  // Event handler for image upload button
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // versao 1>
    const files = event.target.files
    if (files) {
      // Convert selected files into an array
      const selectedImagesArray = Array.from(files)
      // Set selected images state with the newly chosen images
      setSelectedPdf(selectedImagesArray)
      // Create an array of preview URLs from the selected images
      const previewImagesArray = selectedImagesArray.map((image) => URL.createObjectURL(image))
      // Set preview images state with the newly created preview URLs
      setPreviewImages(previewImagesArray)
    }
  }

  useEffect(() => {
    let id: string | undefined;  
    if (payments) {
      for (const payment of payments) {
        if (id === payment.id) {
          setPaymentId(id);
          break;
        }
      }
    }

  }, [payments]);

  const uploadSelectedImage = (eventSelectPaymentId: string) => {
    uploadImage(selectedPdf, 'pdf', eventSelectPaymentId)
    setTimeout(() => {
      history.go(0)
    }, 1500)
  }

  const handleSelectLegacyInvoiceFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      return;
    }
  
    const fileObj = fileInput.files[0];
    if (fileObj.type !== 'text/xml' && fileObj.type !== 'application/pdf') {
      alert('São aceitos apenas arquivos em PDF ou XML.');
      return;
    } else if (fileObj.size > 2000000) {
      alert('O tamanho máximo para cada arquivo é 2 MB.');
      return;
    } else {
      setLegacyInvoiceFiles([fileObj]);
    }
  };

  const submitLegacyInvoiceFile = async (supplierLegacyId: number, contractId: number) => {
    const formData = new FormData()
    legacyInvoiceFiles.forEach((file) => {
      formData.append('invoice[]', file)
    })
    axios
      .post(
        `${process.env.REACT_APP_LEGACY_API_URL}/supplier/${supplierLegacyId}/contracts/${contractId}/submit-invoice`,
        formData,
        { withCredentials: false },
      )
      .then(() => history.go(0))
      .catch(() => alert('Erro no envio do arquivo'))
  }

  return (
    <>
      {nextEvents &&
        nextEvents?.length > 0 &&
        nextEvents.map((events: INextEvents, index) => {
          return (
            <IonCol size='12' sizeMd='4' sizeLg='4' key={index}>
              <IonCard className='p-10' style={{ height: '32rem' }}>
                <IonCardHeader className='text-center' style={{ paddingTop: '35px' }}>
                  <IonCardTitle>Evento #{events.saleCode}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className='p-10'>
                  <p>
                    Data de entrega do serviço: <strong>{formatDate(events.deliveryDate)}</strong>
                  </p>
                  <p>
                    ID do Evento: <strong>#{events.saleCode}</strong>
                  </p>
                  <p>
                    Valor a ser recebido:{' '}
                    <strong>{convertToBRACurrency(events.totalSupplierRepasseValue)}</strong>
                  </p>

                  <p style={{ textAlign: 'center' }}>Notas fiscais enviadas </p>
                </IonCardContent>
                <div style={{ overflowY: 'scroll', height: '15rem' }}>
                  {payments.map((payment) => {
                    return (
                      payment.saleCode === events.saleCode &&
                      payment.invoices.map((invoice) => {
                        return (
                          <IonCard key={invoice.id}>
                            <IonCardContent>
                              <p>
                                Nome do arquivo: <strong>{invoice.fileName}</strong>
                              </p>
                              <p>
                                Enviado em: <strong>{formatDate(invoice.createdAt)}</strong>
                              </p>
                              <p>
                                Status:{' '}
                                <IonChip color={invoiceStatusName(invoice.status)[1]}>
                                  <strong>{invoiceStatusName(invoice.status)[0]}</strong>
                                </IonChip>
                              </p>
                              {invoice.status === 'rejected' && (
                                <p>
                                  <strong>{invoice.rejectedMessage}</strong>
                                </p>
                              )}
                            </IonCardContent>
                          </IonCard>
                        )
                      })
                    )
                  })}
                </div>

                <IonGrid className='text-center'>
                  {payments.map((payment: IPayments) => {
                    return (
                      payment.supplierConfirmationId === events.id && (
                        <div key={payment.id}>
                          {payment.status !== 'waitingInvoiceUpload' ? (
                            <>
                              <IonChip
                                color='success'
                                style={{ position: 'fixed', top: '-8px', right: '-9px' }}
                              >
                                <IonIcon icon={checkmarkOutline} color='success'></IonIcon>
                                <IonLabel>NF Enviada</IonLabel>
                              </IonChip>
                              <IonButton
                                color={'primary'}
                                onClick={() => handleOpenModal(events)}
                                expand='block'
                                style={{
                                  position: 'fixed',
                                  bottom: '10px',
                                  right: '10px',
                                  width: '50%',
                                  margin: 'auto',
                                }}
                              >
                                Ver Detalhes
                              </IonButton>
                            </>
                          ) : (
                            <>
                              <IonChip
                                color='warning'
                                style={{ position: 'fixed', top: '-8px', right: '-9px' }}
                              >
                                <IonIcon icon={alertOutline} color='warning'></IonIcon>
                                <IonLabel>Aguardando envio de nota fiscal </IonLabel>
                              </IonChip>
                              <IonButton
                                color={'primary'}
                                onClick={() => handleOpenModal(events)}
                                expand='block'
                                style={{
                                  position: 'fixed',
                                  bottom: '10px',
                                  right: '10px',
                                  width: '50%',
                                  margin: 'auto',
                                }}
                              >
                                Ver Detalhes
                              </IonButton>
                            </>
                          )}
                        </div>
                      )
                    )
                  })}
                </IonGrid>

                {selectedEvent && (
                  <>
                    <IonModal
                      ref={modal}
                      isOpen={isModalOpen}
                      onWillDismiss={(ev) => onWillDismiss(ev)}
                    >
                      <IonHeader>
                        <IonToolbar>
                          <IonButtons slot='start'>
                            <IonButton
                              style={{ color: 'var(--color-white)' }}
                              onClick={() => modal.current?.dismiss()}
                            >
                              Fechar
                            </IonButton>
                          </IonButtons>
                          <IonTitle style={{ color: 'var(--color-white)' }}>
                            Detalhes do Evento
                          </IonTitle>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent className='ion-padding'>
                        {selectedEvent && (
                          <IonCard key={index}>
                            <IonCardHeader className='text-center'>
                              <h3>Evento #{selectedEvent.saleCode}</h3>
                              <IonChip color='success'>
                                <IonIcon icon={calendarOutline} color='primary'></IonIcon>
                                <IonLabel>
                                  Data de Entrega: {formatDate(selectedEvent.deliveryDate)}
                                </IonLabel>
                              </IonChip>
                            </IonCardHeader>

                            <IonCardContent className='p-10'>
                              <IonText className='text-center'>
                                <h3>
                                  <strong>Atente-se as seguintes instruções:</strong>
                                </h3>
                              </IonText>
                              <IonCard>
                                <IonCardContent>
                                  <div className='text-center'>
                                    <IonText color='danger'>
                                      <h1>
                                        <strong>#{selectedEvent.saleCode}</strong>
                                      </h1>
                                    </IonText>
                                    <p>Informe na descrição da nota fiscal o id do evento.</p>
                                  </div>
                                </IonCardContent>
                              </IonCard>
                              <IonCard>
                                <IonCardContent>
                                  <div className='text-center'>
                                    <IonText color='danger'>
                                      <h1>
                                        <strong>
                                          {convertToBRACurrency(
                                            selectedEvent.totalSupplierRepasseValue,
                                          )}
                                        </strong>
                                      </h1>
                                    </IonText>
                                    <p>Emita a nota fiscal no valor acima.</p>
                                  </div>
                                </IonCardContent>
                              </IonCard>
                              <IonCard>
                                <IonCardContent>
                                  <div className='text-center'>
                                    <IonText color='danger'>
                                      <h1>
                                        <strong>01 ~ 25</strong>
                                      </h1>
                                    </IonText>
                                    <p>
                                      Envie a NF em{' '}
                                      <strong>PDF entre os dias 01 e 25 do mês.</strong>
                                      antes da entrega do serviço!
                                    </p>
                                  </div>
                                </IonCardContent>
                              </IonCard>
                              <IonCard>
                                <IonCardContent>
                                  <div className='text-center'>
                                    <IonText color='danger'>
                                      <h1>
                                        <strong>CNPJ</strong>
                                      </h1>
                                    </IonText>
                                    <p>
                                      Razão social:{' '}
                                      <strong>Celebrar Tecnologia e Intermediações Ltda</strong>
                                      <br />
                                      <strong>CNPJ:28.282.186/0001-38</strong>
                                    </p>
                                  </div>
                                </IonCardContent>
                              </IonCard>

                              <IonCard>
                                <IonCardHeader className='text-center'>
                                  <IonCardTitle>Detalhes do Evento</IonCardTitle>
                                </IonCardHeader>
                                {selectedEvent.items.map((item: any, index) => {
                                  return (
                                    <IonCard key={index}>
                                      <IonCardContent>
                                        <p>
                                          Nome do item: <strong>{item.name}</strong>
                                        </p>
                                        <p>
                                          Valor total do item:{' '}
                                          <strong>
                                            {convertToBRACurrency(item.totalSupplierSellValue)}
                                          </strong>
                                        </p>
                                        <p>
                                          Quantidade: <strong>{item.quantity}</strong>
                                        </p>
                                        <p>
                                          Valor de repasse do item:{' '}
                                          <strong>
                                            {convertToBRACurrency(item.totalSupplierRepasseValue)}
                                          </strong>
                                        </p>
                                        <p>
                                          Descrição: <strong>{item.description}</strong>
                                        </p>
                                      </IonCardContent>
                                    </IonCard>
                                  )
                                })}
                              </IonCard>

                              <IonCard>
                                <IonCardHeader className='text-center'>
                                  <IonCardTitle>Selecione a Nota Fiscal</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                  {payments.map((payment: IPayments) => {
                                    return (
                                      payment.supplierConfirmationId === selectedEvent.id && (
                                        <div key={payment.id}>
                                          <IonItem
                                            hidden={payment.status !== 'waitingInvoiceUpload'}
                                            className='custom'
                                          >
                                            <input
                                              type='file'
                                              accept='application/pdf,text/xml'
                                              multiple
                                              onChange={handleImageUpload}
                                            />
                                          </IonItem>
                                          {payment.status !== 'waitingInvoiceUpload' ? (
                                            <IonChip color='success'>
                                              <IonIcon
                                                icon={checkmarkOutline}
                                                color='primary'
                                              ></IonIcon>
                                              <IonLabel>NF Enviada</IonLabel>
                                            </IonChip>
                                          ) : null}
                                          <IonButton
                                            expand='block'
                                            disabled={payment.status !== 'waitingInvoiceUpload'}
                                            onClick={() => uploadSelectedImage(payment.id)}
                                          >
                                            Enviar NF
                                          </IonButton>
                                        </div>
                                      )
                                    )
                                  })}
                                </IonCardContent>
                              </IonCard>
                            </IonCardContent>
                          </IonCard>
                        )}
                      </IonContent>
                    </IonModal>
                  </>
                )}
              </IonCard>
            </IonCol>
          )
        })}

      {legacyEvents &&
        legacyEvents.length > 0 &&
        legacyEvents.map((event: ILegacyEvent, index: number) => {
          return (
            <IonCol size='12' sizeMd='4' sizeLg='4' key={index}>
              <IonCard id={`open-legacy-modal-${index}`} className='p-10' key={index}>
                <IonCardHeader className='text-center'>
                  <IonCardTitle>Evento #{event.sale_code}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className='p-10'>
                  <p>
                    Data de entrega do serviço:{' '}
                    <strong>{formatDate(event.delivery_estimated_at)}</strong>
                  </p>
                  <p>
                    ID do Evento: <strong>#{event.sale_code}</strong>
                  </p>
                  <p>
                    Valor a ser recebido: <strong>R${event.total_supplier_sale_price}</strong>
                  </p>
                </IonCardContent>
                <IonGrid className='text-center'>
                  <IonButton color={'primary'} expand='block'>
                    Enviar Nota Fiscal
                  </IonButton>
                </IonGrid>

                <IonModal
                  ref={legacyContractModal}
                  trigger={`open-legacy-modal-${index}`}
                  onWillDismiss={(ev) => onWillDismiss(ev)}
                >
                  <IonHeader>
                    <IonToolbar>
                      <IonButtons slot='start'>
                        <IonButton
                          style={{ color: 'var(--color-white)' }}
                          onClick={() => legacyContractModal.current?.dismiss()}
                        >
                          Fechar
                        </IonButton>
                      </IonButtons>
                      <IonTitle style={{ color: 'var(--color-white)' }}>
                        Detalhes do Evento
                      </IonTitle>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className='ion-padding'>
                    {event.items.map((item, index) => {
                      return (
                        <IonCard key={index}>
                          <IonCardHeader className='text-center'>
                            <h3>Evento #{event.sale_code}</h3>
                            <IonChip color='success'>
                              <IonIcon icon={calendarOutline} color='primary'></IonIcon>
                              <IonLabel>
                                Data de Entrega: {formatDate(item.delivery_estimated_at)}
                              </IonLabel>
                            </IonChip>
                          </IonCardHeader>

                          <IonCardContent className='p-10'>
                            <IonText className='text-center'>
                              <h3>
                                <strong>Atente-se as seguintes instruções:</strong>
                              </h3>
                            </IonText>
                            <IonCard>
                              <IonCardContent>
                                <div className='text-center'>
                                  <IonText color='danger'>
                                    <h1>
                                      <strong>#{event.sale_code}</strong>
                                    </h1>
                                  </IonText>
                                  <p>Informe na descrição da nota fiscal o id do evento.</p>
                                </div>
                              </IonCardContent>
                            </IonCard>
                            <IonCard>
                              <IonCardContent>
                                <div className='text-center'>
                                  <IonText color='danger'>
                                    <h1>
                                      <strong>R${event.total_supplier_sale_price}</strong>
                                    </h1>
                                  </IonText>
                                  <p>Emita a nota fiscal no valor acima.</p>
                                </div>
                              </IonCardContent>
                            </IonCard>
                            <IonCard>
                              <IonCardContent>
                                <div className='text-center'>
                                  <IonText color='danger'>
                                    <h1>
                                      <strong>01 ~ 25</strong>
                                    </h1>
                                  </IonText>
                                  <p>
                                    Envie a NF em <strong>PDF entre os dias 01 e 25 do mês.</strong>
                                    antes da entrega do serviço!
                                  </p>
                                </div>
                              </IonCardContent>
                            </IonCard>
                            <IonCard>
                              <IonCardContent>
                                <div className='text-center'>
                                  <IonText color='danger'>
                                    <h1>
                                      <strong>CNPJ</strong>
                                    </h1>
                                  </IonText>
                                  <p>
                                    Razão social:{' '}
                                    <strong>Celebrar Tecnologia e Intermediações Ltda</strong>
                                    <br />
                                    <strong>CNPJ:28.282.186/0001-38</strong>
                                  </p>
                                </div>
                              </IonCardContent>
                            </IonCard>
                            <IonCard>
                              <IonCardHeader className='text-center'>
                                <IonCardTitle>{item.name}</IonCardTitle>
                              </IonCardHeader>
                              <IonCardContent>
                                <IonGrid className='chips'>
                                  <IonChip color='success'>
                                    <IonIcon icon={listOutline} color='primary'></IonIcon>
                                    <IonLabel>Quantidade de Items: {item.quantity}</IonLabel>
                                  </IonChip>
                                  <IonChip color='success'>
                                    <IonIcon icon={cashOutline} color='primary'></IonIcon>
                                    <IonLabel>
                                      Valor Total da Venda: {item.final_sale_price}
                                    </IonLabel>
                                  </IonChip>
                                  <IonChip color='success'>
                                    <IonIcon icon={cashOutline} color='primary'></IonIcon>
                                    <IonLabel>
                                      Valor do Repasse: {item.final_supplier_sale_price}
                                    </IonLabel>
                                  </IonChip>
                                </IonGrid>

                                <hr style={{ backgroundColor: 'black' }} />
                                <IonGrid className='text-center'>
                                  <IonLabel>Descrição do Evento:</IonLabel>
                                  <IonTextarea
                                    autoGrow={true}
                                    disabled={true}
                                    value={item.description}
                                  ></IonTextarea>
                                </IonGrid>
                              </IonCardContent>
                            </IonCard>
                            <IonCard>
                              <IonCardHeader className='text-center'>
                                <IonCardTitle>Selecione a Nota Fiscal</IonCardTitle>
                              </IonCardHeader>
                              <IonCardContent>
                                <IonItem className='custom'>
                                  <input type='file' onChange={handleSelectLegacyInvoiceFiles} />
                                </IonItem>
                                <div>
                                  <IonButton
                                    expand='block'
                                    onClick={() =>
                                      submitLegacyInvoiceFile(event.supplier_id, event.id)
                                    }
                                  >
                                    Enviar NF
                                  </IonButton>
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </IonCardContent>
                        </IonCard>
                      )
                    })}
                  </IonContent>
                </IonModal>
              </IonCard>
            </IonCol>
          )
        })}
    </>
  )
}
