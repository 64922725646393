import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import api from '../services/api'
import useToast from '../hook/useToast'
import ReactPixel from 'react-facebook-pixel'

ReactPixel.init('1983852821715131')

// Create context
const AuthContext = createContext<IAuthContext>({} as IAuthContext)

// Interfaces
interface IAuthContext {
  signed: boolean
  supplierUserData: ISupplierUserData | null
  resetPasswordValidator: boolean
  Login: (props: ILoginCredentials) => void
  logOut: () => void
  Register: (props: IRegisterCredentials) => void
  registerCompany: (props: IRegisterCompany) => void
  registerCompanyAddress: (props: IRegisterCompanyAddress) => void
  ResetPassword: (props: IResetCredentials) => void
  ResetNewPassword: (props: IResetNewPasswordCredentials) => void
  registered: boolean
  finallizeRegister: () => void
  getSupplierData: () => void
}

interface ISupplierUserData {
  email: string
  role: string
  actingStates: string[]
  id: number
  isRegistrationComplete: boolean
  registeredCategories: []
}

interface IAuthProvider {
  children: React.ReactNode
}

interface ILoginCredentials {
  email: string
  password: string
}

interface IRegisterCredentials {
  email: string
  password: string
  confirmPassword: string
  acceptanceTerm: boolean
}

interface IRegisterCompany {
  cnpj: string
  companyName: string
  knownName: string
  indicatedBy: string
  managerName: string
  managerCpf: string
  phone: string
}

interface IRegisterCompanyAddress {
  cep: string
  street: string
  uf: string
  city: string
  neighborhood: string
  complement: string
  number: string | null
}

interface IResetCredentials {
  email: string
}

interface IResetNewPasswordCredentials {
  newPassword: string
  newPasswordConfirmation: string
  token: string | null
}

// Auth provider: provide login with credentials or google sigin
const AuthProvider: React.FC<IAuthProvider> = ({ children }: IAuthProvider) => {
  const [supplierUserData, setSupplierUserData] = useState<ISupplierUserData | null>(null)
  const [resetPasswordValidator, setPasswordReset] = useState(Boolean)
  const [registered, setRegistered] = useState(false)

  useEffect(() => {
    const userData: string | null = window.sessionStorage.getItem('user')
    if (userData) setSupplierUserData(JSON.parse(userData))
    else getSupplierData()
  }, [])

  // Toarster
  const { presentToast } = useToast()

  // auth credentials
  async function Login({ email, password }: ILoginCredentials) {
    const post = { email: email, password: password }
    try {
      loginSuplier(post)
    } catch (error) {
      console.log(error)
    } finally {
      console.log('login finalizado')
    }
  }

  async function loginSuplier(post: ILoginCredentials) {
    api
      .post('/users/supplier/signin', post, { withCredentials: true })
      .then(() => {
        getSupplierData()
        presentToast('Bem Vindo!', 'success')
      })
      .catch((error) => {
        const message = error.response.data.message
        switch (message) {
          case 'Email or password incorrect.':
            presentToast('Erro ao acessar, e-mail ou senha inválidos.', 'danger')
            break
          default:
            presentToast('Erro ao acessar, por favor tente novamente.', 'danger')
            break
        }
      })
  }

  function createSuplierAccount(post: ILoginCredentials) {
    api
      .post('/users/supplier', post, { withCredentials: true })
      .then((response) => {
        presentToast('Conta Criada com Sucesso', 'success')
        ReactPixel.track('CompleteRegistration')
        setRegistered(true);
      })
      .catch((error) => {
        setRegistered(false)
        const message = error.response.data.message
        switch (message) {
          case 'Email already registered.':
            presentToast('Erro ao criar conta, e-mail já existente.', 'danger')
            break
          default:
            presentToast('Erro ao criar conta, por favor tente novamente.', 'danger')
            break
        }
      })
  }

  function logOut() {
    api
      .post('/suppliers/signout')
      .then(() => window.sessionStorage.clear())
      .catch((err) => console.error(err))
  }

  function createCompanyRegister(post: IRegisterCompany) {
    api
      .post('/suppliers/company', post, { withCredentials: true })
      .then(() => {
        presentToast('Cadastro realizado com sucesso!', 'success')
      })
      .catch((error) => {
        presentToast('Favor preencher corretamente o formulário', 'danger')
        console.log(error)
      })
  }

  async function createCompanyRegisterAddress(post: IRegisterCompanyAddress) {
    api
      .post('/suppliers/company/address', post, { withCredentials: true })
      .then(() => {
        presentToast('Cadastro realizado com sucesso!', 'success')
        finallizeRegister()
      })
      .catch((error) => {
        if (post) {
          presentToast('Favor preencher os dados da empresa antes de colocar o endereço', 'danger')
          console.error(error)
        } else {
          presentToast('Favor preencher corretamente o formulário', 'danger')
        }

        console.error(error.AiosError)
      })
  }

  function finallizeRegister() {
    api
      .post('/suppliers/finish-registration', null, { withCredentials: true })
      .then(() => {
        presentToast('Cadastro Finalizado com sucesso', 'success')
        setTimeout(() => {
          getSupplierData()
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }, 1000)
      })
      .catch(() => presentToast('Erro ao completar cadastro', 'danger'))
  }

  // GET REGISTERED CATEGORIES AND USER REGISTER STATUS
  async function getSupplierData() {
    await api
      .get('/suppliers/me')
      .then((res) => {
        window.sessionStorage.setItem('user', JSON.stringify(res.data))
        setSupplierUserData(res.data)
      })
      .catch(() => console.log('Erro ao Captar Dados do Usuário'))
  }

  async function Register({ email, password }: IRegisterCredentials) {
    const post = { email: email, password: password }
    console.log(post)
    try {
      createSuplierAccount(post)
    } catch (error) {
      presentToast('Erro ao registrar usuario.', 'danger')
    } finally {
      console.log('login finalizado')
    }
  }

  async function registerCompany({
    cnpj,
    companyName,
    knownName,
    indicatedBy,
    managerName,
    managerCpf,
    phone,
  }: IRegisterCompany) {
    const post = {
      cnpj: cnpj,
      companyName: companyName,
      knownName: knownName,
      indicatedBy: indicatedBy,
      managerName: managerName,
      managerCpf: managerCpf,
      phone,
    }
    try {
      createCompanyRegister(post)
    } catch (error) {
      presentToast('Erro ao Adicionar', 'Danger')
    } finally {
      console.log('register company - ok')
    }
  }

  async function registerCompanyAddress({
    cep,
    street,
    uf,
    city,
    neighborhood,
    complement,
    number,
  }: IRegisterCompanyAddress) {
    const post = {
      cep: cep,
      street: street,
      uf: uf,
      city: city,
      neighborhood: neighborhood,
      complement: complement,
      number: number,
    }
    try {
      createCompanyRegisterAddress(post)
    } catch (error) {
      console.log(error)
    } finally {
      console.log('register company - ok')
    }
  }

  async function ResetPassword({ email }: IResetCredentials) {
    const post = { email: email }
    try {
      await axios.post('/suppliers/forgot-password', post).then(() => {
        presentToast(
          'Verifique sua caixa de entrada, você irá receber um link para alteração de senha!',
          'success',
        )
        setPasswordReset(true)
      })
    } catch (error) {
      presentToast('Erro ao redefinir senha', 'danger')
    } finally {
      console.log('login finalizado')
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
    }
  }

  async function ResetNewPassword({
    newPassword,
    newPasswordConfirmation,
    token,
  }: IResetNewPasswordCredentials) {
    const post = { newPassword: newPassword, newPasswordConfirmation: newPasswordConfirmation }
    try {
      await axios.post('/suppliers/reset-password/' + token, post).then(() => {
        presentToast('Senha alterada com sucesso!', 'success')
      })
    } catch (error) {
      presentToast('Erro ao redefinir senha', 'danger')
    } finally {
      console.log('login finalizado')
    }
  }
  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(supplierUserData),
        Login,
        logOut,
        Register,
        registerCompany,
        registerCompanyAddress,
        supplierUserData,
        getSupplierData,
        finallizeRegister,
        ResetPassword,
        resetPasswordValidator,
        ResetNewPassword,
        registered,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
