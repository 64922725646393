// CATEGORY --> ALIMENTAÇÃO E BEBIDAS
// SUB CATEGORY --> Serviço de Catering / Buffet
import AlmocoBrunch from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/AlmocoBrunch.png'
import CoffeeBreakWelcomeCoffee from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/CoffeeBreak_WelcomeCoffee.png'
import CoquetelHappyHourJantar from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/Coquetel_Happy_Hour_Jantar.png'
import RodíziodePizza from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/RodizioDePizza.png'
import HamburguerArtesanal from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/HamburguerArtesanal.png'
import VegetarianosouVeganos from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/VegetarianosOuVeganos.png'
import CulinariaInternacional from '../../../assets/img/categoriesImages/AlimentosEBebidas/ServicoDeCatering_Buffet/CulinariaInternacional.png'
// SUB CATEGORY --> Barracas / Carrinhos de Alimentação
// import Barracas from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao'
import Carrinhos from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/Carrinhos.png'
import Barracas from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/Barracas.png'
import FoodBikes from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/FoodBike.png'
import FoodTrucks from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/foodTruck.png'
import DrinkBikes from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/BikeDrink.png'
import DrinkTrucks from '../../../assets/img/categoriesImages/AlimentosEBebidas/Barracas_CarrinhosDeAlimentacao/DrinkTrucks.png'
// import DrinkTrucks from '../../../assets/img/categoriesImages/'
// SUB CATEGORY --> Kits
import KitLanche from '../../../assets/img/categoriesImages/AlimentosEBebidas/Kits/kitLanche.png'
import KitAlmoco from '../../../assets/img/categoriesImages/AlimentosEBebidas/Kits/kitAlmoco.png'
import Cestas from '../../../assets/img/categoriesImages/AlimentosEBebidas/Kits/Cestas.png'
// SUB CATEGORY --> Bebidas
import DrinksClassicos from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/DrinksClassicos.png'
import MixologiaEspecializada from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/mixologiaEspecializada.png'
import Chope from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/Chope.png'
import Cerveja from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/Cerveja.png'
import VinhoseEspumantes from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/VinhosEEspumantes.png'
import UísqueeOutrosDestilados from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/WhiskeyEOutrosDestilados.png'
import NaoAlcoolicos from '../../../assets/img/categoriesImages/AlimentosEBebidas/Bebidas/NaoAlcoalicos.png'
// SUB CATEGORY --> Confeitaria
import BolosTradicionais from '../../../assets/img/categoriesImages/AlimentosEBebidas/Confeitaria/BolosTradicionais.png'
import CakeDesignCupcakePersonalizado from '../../../assets/img/categoriesImages/AlimentosEBebidas/Confeitaria/CakeDesign_CupcakePersonalizado.png'
import ConfeitariaVegana from '../../../assets/img/categoriesImages/AlimentosEBebidas/Confeitaria/ConfeitariaVegana.png'
import DocinhosDiversos from '../../../assets/img/categoriesImages/AlimentosEBebidas/Confeitaria/DocinhosDiversos.png'
// END --> ALIMENTAÇÃO E BEBIDAS

// CATEGORY --> ESPAÇOS PARA EVENTOS
// SUB CATEGORY --> Clássicos
import Auditorios from '../../../assets/img/categoriesImages/Espacos/Classicos/Auditorio.png'
import SalasdeReuniao from '../../../assets/img/categoriesImages/Espacos/Classicos/SalaDeReuniao.png'
// SUB CATEGORY --> Estúdios
import EstudiosdeAudiovisual from '../../../assets/img/categoriesImages/Espacos/Estudios/EstudioAudiovisual.png'
import EstudiosdePodcast from '../../../assets/img/categoriesImages/Espacos/Estudios/EstudiosDePodcast.png'
import EstudiosMusicais from '../../../assets/img/categoriesImages/Espacos/Estudios/EstudioMusical.png'
// SUB CATEGORY --> Confraternizações
import EspacosdeFestas from '../../../assets/img/categoriesImages/Espacos/Confraternizacoes/EspacosParaFestas.png'
import CinemaseTeatros from '../../../assets/img/categoriesImages/Espacos/Confraternizacoes/Cinema.png'
import EspacosAlternativos from '../../../assets/img/categoriesImages/Espacos/Confraternizacoes/EspacosAlternativos.png'
import Pavilhoes from '../../../assets/img/categoriesImages/Espacos/Confraternizacoes/Pavilhao.png'
// END --> ESPAÇOS PARA EVENTOS

// CATEGORY --> STAFF TÉCNICA E OPERACIONAL
// SUB CATEORY --> Staff Apoio e Operações
import Recepcionista from '../../../assets/img/categoriesImages/Staff/Recepcionista.png'
import OrientacaodePublico from '../../../assets/img/categoriesImages/Staff/OrientadoDePublico.png'
import ControleDeAcesso from '../../../assets/img/categoriesImages/Staff/ControleDeAcesso.png'
import Seguranca from '../../../assets/img/categoriesImages/Staff/Seguranca.png'
import Limpeza from '../../../assets/img/categoriesImages/Staff/Limpeza.png'
import Carregador from '../../../assets/img/categoriesImages/Staff/carregador.png'
import MontagemEManuseioDeKits from '../../../assets/img/categoriesImages/Staff/manuseioDeKits.png'
import EquipeDeEnfermagemTesteDeCovidAmbulancia from '../../../assets/img/categoriesImages/Staff/EquipeDeEnfermagem_TesteDeCovid.png'
import EquipeDeBombeiros from '../../../assets/img/categoriesImages/Staff/bombeiro.png'
// SUB CATEGORY --> Audiovisual e Iluminação
import OperadorDeAudiovisual from '../../../assets/img/categoriesImages/Staff/OperadorDeAudiovisual.png'
import OperadorDeCameraVideo from '../../../assets/img/categoriesImages/Staff/OperadorDeCamera_Video.png'
import OperadorDeCorte from '../../../assets/img/categoriesImages/Staff/OperadorDeCorte.png'
import OperadorDeIluminacao from '../../../assets/img/categoriesImages/Staff/TecnicoDeLuz.png'
import TecnicoDeStreaming from '../../../assets/img/categoriesImages/Staff/TecnicoDeStreaming.png'
import Narracao from '../../../assets/img/categoriesImages/Staff/Narrador.png'
import Locucao from '../../../assets/img/categoriesImages/Staff/Locucao.png'
// SUB CATEGORY --> Acessibilidade
import InterpreteDeLibras from '../../../assets/img/categoriesImages/Staff/InterpreteDeLibras.png'
import Transcritor from '../../../assets/img/categoriesImages/Staff/Transcritor.png'
import Guia from '../../../assets/img/categoriesImages/Staff/Guia.png'
import Leitor from '../../../assets/img/categoriesImages/Staff/Leitor.png'
// SUB CATEGORY --> Idiomas
import TraducaoSimultanea from '../../../assets/img/categoriesImages/Staff/TraducaoSimultanea.png'
import TraducaoLegendada from '../../../assets/img/categoriesImages/Staff/traducaoLegendada.png'
import Interprete from '../../../assets/img/categoriesImages/Staff/Interprete_Idiomas.png'
// END --> STAFF TÉCNICA E OPERACIONAL

// CATEGORY --> PRODUÇÃO E CONTEÚDO
// SUB CATEGORY --> Produção E Direção
import ProdutorDeEventos from '../../../assets/img/categoriesImages/ProducaoDeConteudo/ProducaoEDirecao/ProdutorDeEventos.png'
import CoordenadorDeEventos from '../../../assets/img/categoriesImages/ProducaoDeConteudo/ProducaoEDirecao/CoordenadorDeEventos.png'
import DirecaoArtistica from '../../../assets/img/categoriesImages/ProducaoDeConteudo/ProducaoEDirecao/DirecaoArtistica.png'
import DirecaoTecnica from '../../../assets/img/categoriesImages/ProducaoDeConteudo/ProducaoEDirecao/DirecaoTecnica.png'
import Roteirizacao from '../../../assets/img/categoriesImages/ProducaoDeConteudo/ProducaoEDirecao/Roteirizacao.png'
// SUB CATEGORY --> Conteúdo
import Palestrante from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/Palestrante.png'
import TeamBuilder from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/TeamBuilder.png'
import CuradorDeConteudo from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/CuradorDeConteudo.png'
import Mentor from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/Mentor.png'
import MestreDeCerimonias from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/MestreDeCerimonia.png'
import ComunicacaoeCriativo from '../../../assets/img/categoriesImages/ProducaoDeConteudo/Conteudo/ComunicacaoECriativo.png'
// END --> PRODUÇÃO E CONTEÚDO

// CATEGORY --> MUSICA E ENTRETENIMENTO
// SUB CATEGORY --> Música, Arte e Cultura
import DJ from '../../../assets/img/categoriesImages/Entretenimento/Dj.png'
import MusicaAoVivo from '../../../assets/img/categoriesImages/Entretenimento/MusicaAoVivo.png'
import AtracoesGastronomicas from '../../../assets/img/categoriesImages/Entretenimento/atracoesGastronomicas.png'
import Teatro from '../../../assets/img/categoriesImages/Entretenimento/Teatro.png'
import Danca from '../../../assets/img/categoriesImages/Entretenimento/Danca.png'
// SUB CATEGORY --> Atrações Diversas
import AtracoesEsportivas from '../../../assets/img/categoriesImages/Entretenimento/AtracoesEsportivas.png'
import MassagemETerapias from '../../../assets/img/categoriesImages/Entretenimento/MassagemETerapias.png'
import GinasticaLaboral from '../../../assets/img/categoriesImages/Entretenimento/GinasticaLaboral.png'
import TotemDoInstagram from '../../../assets/img/categoriesImages/Entretenimento/totemdoinstagram.png'
import CabineDeFotos from '../../../assets/img/categoriesImages/Entretenimento/CabineDeFotos.png'
import Spinner360 from '../../../assets/img/categoriesImages/Entretenimento/spinner360.png'
// import Spinner360 from '../../../assets/img/categoriesImages/Entretenimento/'
// SUB CATEGORY --> Locação De Jogos
import Videogames from '../../../assets/img/categoriesImages/Entretenimento/videogame.png'
import Fliperama from '../../../assets/img/categoriesImages/Entretenimento/Fliperama.png'
import Videoke from '../../../assets/img/categoriesImages/Entretenimento/Videoke.png'
import JogosDeTabuleiro from '../../../assets/img/categoriesImages/Entretenimento/JogosDeTabuleiro.png'
import BrinquedosInflaveis from '../../../assets/img/categoriesImages/Entretenimento/BrinquedosInflaveis.png'
// SUB CATEGORY --> Recreação
import Infantil from '../../../assets/img/categoriesImages/Entretenimento/RecreacaoInfantil.png'
import Adulto from '../../../assets/img/categoriesImages/Entretenimento/RecreacaoAdulto.png'
// END --> MUSICA E ENTRETENIMENTO

// CATEGORY --> BRINDES PERSONALIZADOS
// SUB CATEGORY -->Têxtil E Costuráveis
import Camisetas from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/Camiseta.png'
import Calcas from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/Calcas.png'
import Meias from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/Meia.png'
import BlusasEMoletons from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/BlusasEMoletons.png'
import SacolasEMochilas from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/SacolasEMochilas.png'
import BonesEChapeus from '../../../assets/img/categoriesImages/ItensPersonalizados/TextilECosturaveis/BonesEChapeusBrindes.png'
// SUB CATEGORY --> Brindes
import ButtonsPin from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/Buttons_Pin.png'
import Chaveiros from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/Chaveiro.png'
import CoposEGarrafas from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/CoposEGarrafas.png'
import Canetas from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/Canetas.png'
import Caneca from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/Caneca.png'
import Eletronicos from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/Eletronicos.png'
import ItensDeEscritorio from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/ItensDeEscritorio.png'
import SaudeEBemEstar from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/SaudeEBemEstar.png'
import BrindesSustentaveis from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/BrindesSustentaveis.png'
import LinhaPet from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/LinhaPet.png'
import CordoesELanyards from '../../../assets/img/categoriesImages/ItensPersonalizados/Brindes/CordoesELanyards.png'
// END --> BRINDES PERSONALIZADOS

// CATEGORY --> LOGISTICA E MOVIMENTOS
// SUB CATEGORY --> Logística De Passageiros / Shuttle
import Van from '../../../assets/img/categoriesImages/Logistica/Passageiros/Van.png'
import Onibus from '../../../assets/img/categoriesImages/Logistica/Passageiros/Onibus.png'
import Carro from '../../../assets/img/categoriesImages/Logistica/Passageiros/Carro.png'
// SUB CATEGORY --> Logística De Cargas / Entregas
import Nacional from '../../../assets/img/categoriesImages/Logistica/Cargas/LogisticaDeCargas.png'
import Internacional from '../../../assets/img/categoriesImages/Logistica/Cargas/LogísticaDeCargasInternacional.png'
// END --> LOGISTICA E MOVIMENTOS

// CATEGORY --> DECORAÇÃO E COMUNICAÇÃO
// SUB CATEGORY --> Cenografia
import Mobiliario from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Mobiliario.png'
import Lycra from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Lycra.png'
import FloresPaisagismo from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Flores_Paisagismo.png'
import Baloes from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Baloes.png'
import LetraCaixa from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/letraCaixa.png'
import Acrilicos from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Acrilico.png'
import Paineis from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/paineis.png'
import Madeira from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Madeira.png'
import Stands from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Cenografia/Stands.png'
// SUB CATEGORY --> Comunicação Gráfica
import Adesivos from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/ComunicacaoGrafica/Adesivo.png'
import Papelaria from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/ComunicacaoGrafica/Papelaria.png'
import LonasEBanners from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/ComunicacaoGrafica/LonasEBanners.png'
import Backdrop from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/ComunicacaoGrafica/Backdrop.png'
import CaixasEmbalagensPersonalizadas from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/ComunicacaoGrafica/Caixas_EmbalagensPersonalizadas.png'
// SUB CATEGORY --> Identificação
import CredenciaisPersonalizadas from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Identificacao/CredenciaisPersonalizadas.png'
import CordaoDeCracha from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Identificacao/CordaoDeCracha.png'
import PulseiraDeAcesso from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Identificacao/PulseiraDeAcesso.png'
import TotemdeInformacoes from '../../../assets/img/categoriesImages/DecoracaoEComunicacao/Identificacao/TotemDeInformacoes.png'
// END --> DECORAÇÃO E COMUNICAÇÃO

// CATEGORY --> AUDIOVISUAL E INFRAESTRUTURA
// SUB CATEGORY --> Infraestrutura
import PalcoPraticavel from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Infraestrutura/Palco_Praticavel.png'
import Tendas from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Infraestrutura/Tendas.png'
import Boxtruss from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Infraestrutura/Boxtruss.png'
import Estacionamento from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Infraestrutura/Estacionamento.png'
// SUB CATEGORY --> Audiovisual
import EquipamentoDeSom from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/EquipamentoDeSom.png'
import EquipamentoDeIluminacao from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/EquipamentoDeIluminacao.png'
import EquipamentoDeVideo from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/EquipamentoDeVideo.png'
import StreamingTransmissaoAoVivo from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/Streaming_TransmissaoAoVivo.png'
import LocacaoDeEletronicos from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/locacaoDeEquipamentos.png'
import InternetEWifi from '../../../assets/img/categoriesImages/AudiovisualEInfraestrutura/Audiovisual/Internet.png'
// END --> AUDIOVISUAL E INFRAESTRUTURA

// CATEGORY --> FOTOGRAFIA E FILMAGEM
// SUB CATEGORY --> Fotografia
import FotografoA from '../../../assets/img/categoriesImages/FotosEVideos/Fotos/Fotografa.png'
import EnsaioFotografico from '../../../assets/img/categoriesImages/FotosEVideos/Fotos/EnsaioFotografico.png'
import EdicaoDeFotos from '../../../assets/img/categoriesImages/FotosEVideos/Fotos/EdicaoDeFotos.png'
// SUB CATEGORY --> Vídeo
import VideoMaker from '../../../assets/img/categoriesImages/FotosEVideos/Videos/Videomaker.png'
import EdicaoDeVídeo from '../../../assets/img/categoriesImages/FotosEVideos/Videos/EdicaoDeVideo.png'
import EquipeDeFilmagem from '../../../assets/img/categoriesImages/FotosEVideos/Videos/EquipeAudiovisual.png'
// END --> FOTOGRAFIA E FILMAGEM

export const categoriesImages = [

    // CATEGORY --> ALIMENTAÇÃO E BEBIDAS
    // SUB CATEGORY --> Serviço de Catering / Buffet
    {
        id: 1,
        name: 'Coffee break / Welcome coffee',
        categoryId: 30,
        url: CoffeeBreakWelcomeCoffee
    },
    {
        id: 2,
        name: 'Almoço / Brunch',
        categoryId: 31,
        url: AlmocoBrunch
    },
    {
        id: 3,
        name: 'Coquetel / Happy hour / Jantar',
        categoryId: 32,
        url: CoquetelHappyHourJantar
    },
    {
        id: 4,
        name: 'Rodízio de pizza',
        categoryId: 33,
        url: RodíziodePizza
    },
    {
        id: 5,
        name: 'Hambúrguer Artesanal',
        categoryId: 34,
        url: HamburguerArtesanal
    },
    {
        id: 6,
        name: 'Vegetarianos ou Veganos',
        categoryId: 35,
        url: VegetarianosouVeganos
    },
    {
        id: 7,
        name: 'Culinária Internacional',
        categoryId: 36,
        url: CulinariaInternacional
    },

    // SUB CATEGORY --> Barracas / Carrinhos de Alimentação
    {
        id: 8,
        name: 'Barracas',
        categoryId: 38,
        url: Barracas
    },
    {
        id: 9,
        name: 'Carrinhos',
        categoryId: 39,
        url: Carrinhos
    },
    {
        id: 10,
        name: 'Food Bikes',
        categoryId: 40,
        url: FoodBikes
    },
    {
        id: 11,
        name: 'Food Trucks',
        categoryId: 41,
        url: FoodTrucks
    },
    {
        id: 12,
        name: 'Drink Bikes',
        categoryId: 42,
        url: DrinkBikes
    },
    {
        id: 13,
        name: 'Drink Trucks',
        categoryId: 43,
        url: DrinkTrucks
    },

    // SUB CATEGORY --> Kits
    {
        id: 14,
        name: 'Kit Lanche',
        categoryId: 45,
        url: KitLanche
    },
    {
        id: 15,
        name: 'Kit Almoço',
        categoryId: 46,
        url: KitAlmoco
    },
    {
        id: 16,
        name: 'Cestas',
        categoryId: 47,
        url: Cestas
    },

    // SUB CATEGORY --> Bebidas
    {
        id: 17,
        name: 'Drinks Clássicos',
        categoryId: 49,
        url: DrinksClassicos
    },
    {
        id: 18,
        name: 'Mixologia Especializada',
        categoryId: 50,
        url: MixologiaEspecializada
    },
    {
        id: 19,
        name: 'Chope',
        categoryId: 51,
        url: Chope
    },
    {
        id: 20,
        name: 'Cerveja',
        categoryId: 52,
        url: Cerveja
    },
    {
        id: 21,
        name: 'Vinhos e Espumantes',
        categoryId: 53,
        url: VinhoseEspumantes
    },
    {
        id: 21,
        name: 'Uísque e Outros Destilados',
        categoryId: 54,
        url: UísqueeOutrosDestilados
    },
    {
        id: 23,
        name: 'Não Alcoolicos',
        categoryId: 55,
        url: NaoAlcoolicos
    },
    // SUB CATEGORY --> Confeitaria
    {
        id: 24,
        name: 'Bolos Tradicionais',
        categoryId: 57,
        url: BolosTradicionais
    },
    {
        id: 25,
        name: 'Cake Design / Cupcake Personalizado',
        categoryId: 58,
        url: CakeDesignCupcakePersonalizado
    },
    {
        id: 26,
        name: 'Confeitaria Vegana',
        categoryId: 59,
        url: ConfeitariaVegana
    },
    {
        id: 27,
        name: 'Docinhos Diversos',
        categoryId: 60,
        url: DocinhosDiversos
    },
    // END --> ALIMENTAÇÃO E BEBIDAS

    // CATEGORY --> ESPAÇOS PARA EVENTOS
    // SUB CATEGORY --> Clássicos
    {
        id: 28,
        name: 'Auditórios',
        categoryId: 63,
        url: Auditorios
    },
    {
        id: 29,
        name: 'Salas de Reunião',
        categoryId: 64,
        url: SalasdeReuniao
    },

    // SUB CATEGORY --> Estúdios
    {
        id: 30,
        name: 'Estúdios de Audiovisual',
        categoryId: 66,
        url: EstudiosdeAudiovisual
    },
    {
        id: 31,
        name: 'Estúdios de Podcast',
        categoryId: 67,
        url: EstudiosdePodcast
    },
    {
        id: 32,
        name: 'Estúdios Musicais',
        categoryId: 68,
        url: EstudiosMusicais
    },

    // SUB CATEGORY --> Confraternizações
    {
        id: 32,
        name: 'Espaços de Festas',
        categoryId: 70,
        url: EspacosdeFestas
    },
    {
        id: 33,
        name: 'Cinemas e Teatros',
        categoryId: 71,
        url: CinemaseTeatros
    },
    {
        id: 34,
        name: 'Espaços Alternativos',
        categoryId: 72,
        url: EspacosAlternativos
    },
    {
        id: 35,
        name: 'Pavilhões',
        categoryId: 73,
        url: Pavilhoes
    },
    // END --> ESPAÇOS PARA EVENTOS

    // CATEGORY --> STAFF TÉCNICA E OPERACIONAL
    // SUB CATEGORY --> Staff Apoio e Operações
    {
        id: 36,
        name: 'Recepcionista',
        categoryId: 76,
        url: Recepcionista
    },
    {
        id: 37,
        name: 'Orientação de Público',
        categoryId: 77,
        url: OrientacaodePublico
    },
    {
        id: 38,
        name: 'Controle de Acesso',
        categoryId: 78,
        url: ControleDeAcesso
    },
    {
        id: 39,
        name: 'Segurança',
        categoryId: 79,
        url: Seguranca
    },
    {
        id: 40,
        name: 'Limpeza',
        categoryId: 80,
        url: Limpeza
    },
    {
        id: 41,
        name: 'Carregador',
        categoryId: 81,
        url: Carregador
    },
    {
        id: 42,
        name: 'Montagem e Manuseio de Kits',
        categoryId: 82,
        url: MontagemEManuseioDeKits
    },
    {
        id: 43,
        name: 'Equipe De Enfermagem / Teste De Covid / Ambulância',
        categoryId: 83,
        url: EquipeDeEnfermagemTesteDeCovidAmbulancia
    },
    {
        id: 44,
        name: 'Equipe De Bombeiros',
        categoryId: 84,
        url: EquipeDeBombeiros
    },

    // SUB CATEGORY --> Audiovisual e Iluminação
    {
        id: 45,
        name: 'Operador de Audiovisual',
        categoryId: 86,
        url: OperadorDeAudiovisual
    },
    {
        id: 46,
        name: 'Operador de Câmera / Vídeo',
        categoryId: 87,
        url: OperadorDeCameraVideo
    },
    {
        id: 47,
        name: 'Operador de Corte',
        categoryId: 88,
        url: OperadorDeCorte
    },
    {
        id: 48,
        name: 'Operador de Iluminação',
        categoryId: 89,
        url: OperadorDeIluminacao
    },
    {
        id: 49,
        name: 'Técnico de Streaming',
        categoryId: 90,
        url: TecnicoDeStreaming
    },
    {
        id: 50,
        name: 'Narração',
        categoryId: 91,
        url: Narracao
    },
    {
        id: 51,
        name: 'Locução',
        categoryId: 92,
        url: Locucao
    },

    // SUB CATEGORY --> Acessibilidade
    {
        id: 52,
        name: 'Intérprete de Libras',
        categoryId: 94,
        url: InterpreteDeLibras
    },
    {
        id: 53,
        name: 'Transcritor',
        categoryId: 95,
        url: Transcritor
    },
    {
        id: 54,
        name: 'Guia',
        categoryId: 96,
        url: Guia
    },
    {
        id: 55,
        name: 'Leitor',
        categoryId: 97,
        url: Leitor
    },

    // SUB CATEGORY --> Idiomas
    {
        id: 56,
        name: 'Tradução Simultânea',
        categoryId: 99,
        url: TraducaoSimultanea
    },
    {
        id: 57,
        name: 'Tradução Legendada',
        categoryId: 100,
        url: TraducaoLegendada
    },
    {
        id: 58,
        name: 'Intérprete',
        categoryId: 101,
        url: Interprete
    },
    // END --> STAFF TÉCNICA E OPERACIONAL

    // CATEGORY --> PRODUÇÃO E CONTEÚDO
    // SUB CATEGORY --> Produção E Direção
    {
        id: 59,
        name: 'Produtor de Eventos',
        categoryId: 104,
        url: ProdutorDeEventos
    },
    {
        id: 60,
        name: 'Coordenador de Eventos',
        categoryId: 105,
        url: CoordenadorDeEventos
    },
    {
        id: 61,
        name: 'Direção Artística',
        categoryId: 106,
        url: DirecaoArtistica
    },
    {
        id: 62,
        name: 'Direção Técnica',
        categoryId: 107,
        url: DirecaoTecnica
    },
    {
        id: 63,
        name: 'Roteirização',
        categoryId: 108,
        url: Roteirizacao
    },

    // SUB CATEGORY --> Conteúdo
    {
        id: 64,
        name: 'Palestrante',
        categoryId: 110,
        url: Palestrante
    },
    {
        id: 65,
        name: 'Team Builder',
        categoryId: 111,
        url: TeamBuilder
    },
    {
        id: 66,
        name: 'Curador De ConteUdo',
        categoryId: 112,
        url: CuradorDeConteudo
    },
    {
        id: 67,
        name: 'Mentor',
        categoryId: 113,
        url: Mentor
    },
    {
        id: 68,
        name: 'Mestre De Cerimônias',
        categoryId: 114,
        url: MestreDeCerimonias
    },
    {
        id: 69,
        name: 'Comunicação e Criativo',
        categoryId: 115,
        url: ComunicacaoeCriativo
    },
    // END --> PRODUÇÃO E CONTEÚDO

    // CATEGORY --> MUSICA E ENTRETENIMENTO
    // SUB CATEGORY --> Música, Arte e Cultura
    {
        id: 70,
        name: 'DJ',
        categoryId: 118,
        url: DJ
    },
    {
        id: 71,
        name: 'Música Ao Vivo',
        categoryId: 119,
        url: MusicaAoVivo
    },
    {
        id: 72,
        name: 'Atrações Gastronômicas',
        categoryId: 120,
        url: AtracoesGastronomicas
    },
    {
        id: 73,
        name: 'Teatro',
        categoryId: 121,
        url: Teatro
    },
    {
        id: 74,
        name: 'Dança',
        categoryId: 122,
        url: Danca
    },

    // SUB CATEGORY --> Atrações Diversas
    {
        id: 75,
        name: 'Atrações Esportivas',
        categoryId: 124,
        url: AtracoesEsportivas
    },
    {
        id: 76,
        name: 'Massagem e Terapias',
        categoryId: 125,
        url: MassagemETerapias
    },
    {
        id: 77,
        name: 'Ginástica Laboral',
        categoryId: 126,
        url: GinasticaLaboral
    },
    {
        id: 78,
        name: 'Totem Do Instagram',
        categoryId: 127,
        url: TotemDoInstagram
    },
    {
        id: 79,
        name: 'Cabine De Fotos',
        categoryId: 128,
        url: CabineDeFotos
    },
    {
        id: 90,
        name: 'Spinner 360',
        categoryId: 129,
        url: Spinner360
    },

    // SUB CATEGORY --> Locação De Jogos
    {
        id: 91,
        name: 'Videogames',
        categoryId: 131,
        url: Videogames
    },
    {
        id: 92,
        name: 'Fliperama',
        categoryId: 132,
        url: Fliperama
    },
    {
        id: 93,
        name: 'Videokê',
        categoryId: 133,
        url: Videoke
    },
    {
        id: 94,
        name: 'Jogos De Tabuleiro',
        categoryId: 134,
        url: JogosDeTabuleiro
    },
    {
        id: 95,
        name: 'Brinquedos Infláveis',
        categoryId: 135,
        url: BrinquedosInflaveis
    },

    // SUB CATEGORY --> Recreação
    {
        id: 96,
        name: 'Infantil',
        categoryId: 137,
        url: Infantil
    },
    {
        id: 97,
        name: 'Adulto',
        categoryId: 138,
        url: Adulto
    },
    // END --> MUSICA E ENTRETENIMENTO

    // CATEGORY --> BRINDES PERSONALIZADOS
    // SUB CATEGORY -->Têxtil E Costuráveis
    {
        id: 98,
        name: 'Camisetas',
        categoryId: 141,
        url: Camisetas
    },
    {
        id: 99,
        name: 'Calças',
        categoryId: 142,
        url: Calcas
    },
    {
        id: 100,
        name: 'Meias',
        categoryId: 143,
        url: Meias
    },
    {
        id: 101,
        name: 'Blusas e Moletons',
        categoryId: 144,
        url: BlusasEMoletons
    },
    {
        id: 102,
        name: 'Sacolas e Mochilas',
        categoryId: 145,
        url: SacolasEMochilas
    },
    {
        id: 103,
        name: 'Bonés e Chapéus',
        categoryId: 146,
        url: BonesEChapeus
    },

    // SUB CATEGORY --> Brindes
    {
        id: 104,
        name: 'Buttons / Pin',
        categoryId: 148,
        url: ButtonsPin
    },
    {
        id: 105,
        name: 'Chaveiros',
        categoryId: 149,
        url: Chaveiros
    },
    {
        id: 106,
        name: 'Caneca',
        categoryId: 150,
        url: Caneca
    },
    {
        id: 107,
        name: 'Copos E Garrafas',
        categoryId: 151,
        url: CoposEGarrafas
    },
    {
        id: 108,
        name: 'Canetas',
        categoryId: 152,
        url: Canetas
    },
    {
        id: 109,
        name: 'Eletrônicos',
        categoryId: 153,
        url: Eletronicos
    },
    {
        id: 110,
        name: 'Itens De Escritório',
        categoryId: 154,
        url: ItensDeEscritorio
    },
    {
        id: 111,
        name: 'Saúde e Bem-estar',
        categoryId: 155,
        url: SaudeEBemEstar
    },
    {
        id: 111,
        name: 'Brindes Sustentáveis',
        categoryId: 156,
        url: BrindesSustentaveis
    },
    {
        id: 111,
        name: 'Linha Pet',
        categoryId: 157,
        url: LinhaPet
    },
    {
        id: 111,
        name: 'Cordões e Lanyards',
        categoryId: 158,
        url: CordoesELanyards
    },
    // END --> BRINDES PERSONALIZADOS

    // CATEGORY --> LOGISTICA E MOVIMENTOS
    // SUB CATEGORY --> Logística De Passageiros / Shuttle
    {
        id: 112,
        name: 'Van',
        categoryId: 161,
        url: Van
    },
    {
        id: 113,
        name: 'Ônibus',
        categoryId: 162,
        url: Onibus
    },
    {
        id: 114,
        name: 'Carro',
        categoryId: 163,
        url: Carro
    },

    // SUB CATEGORY --> Logística De Cargas / Entregas
    {
        id: 115,
        name: 'Nacional',
        categoryId: 165,
        url: Nacional
    },
    {
        id: 116,
        name: 'Internacional',
        categoryId: 166,
        url: Internacional
    },
    // END --> LOGISTICA E MOVIMENTOS

    // CATEGORY --> DECORAÇÃO E COMUNICAÇÃO
    // SUB CATEGORY --> Cenografia
    {
        id: 117,
        name: 'Mobiliário',
        categoryId: 169,
        url: Mobiliario
    },
    {
        id: 118,
        name: 'Lycra',
        categoryId: 170,
        url: Lycra
    },
    {
        id: 119,
        name: 'Flores / Paisagismo',
        categoryId: 171,
        url: FloresPaisagismo
    },
    {
        id: 120,
        name: 'Balões',
        categoryId: 172,
        url: Baloes
    },
    {
        id: 121,
        name: 'Letra Caixa',
        categoryId: 173,
        url: LetraCaixa
    },
    {
        id: 122,
        name: 'Acrílicos',
        categoryId: 174,
        url: Acrilicos
    },
    {
        id: 123,
        name: 'Paineis',
        categoryId: 175,
        url: Paineis
    },
    {
        id: 124,
        name: 'Madeira',
        categoryId: 176,
        url: Madeira
    },
    {
        id: 125,
        name: 'Stands',
        categoryId: 177,
        url: Stands
    },

    // SUB CATEGORY --> Comunicação Gráfica
    {
        id: 126,
        name: 'Adesivos',
        categoryId: 179,
        url: Adesivos
    },
    {
        id: 127,
        name: 'Papelaria',
        categoryId: 180,
        url: Papelaria
    },
    {
        id: 128,
        name: 'Lonas E Banners',
        categoryId: 181,
        url: LonasEBanners
    },
    {
        id: 129,
        name: 'Backdrop',
        categoryId: 182,
        url: Backdrop
    },
    {
        id: 130,
        name: 'Caixas / Embalagens Personalizadas',
        categoryId: 183,
        url: CaixasEmbalagensPersonalizadas
    },

    // SUB CATEGORY --> Identificação
    {
        id: 131,
        name: 'Credenciais Personalizadas',
        categoryId: 185,
        url: CredenciaisPersonalizadas
    },
    {
        id: 132,
        name: 'Cordão De Crachá',
        categoryId: 186,
        url: CordaoDeCracha
    },
    {
        id: 133,
        name: 'Pulseira de Acesso',
        categoryId: 187,
        url: PulseiraDeAcesso
    },
    {
        id: 134,
        name: 'Totem de Informações',
        categoryId: 188,
        url: TotemdeInformacoes
    },
    // END --> DECORAÇÃO E COMUNICAÇÃO

    // CATEGORY --> AUDIOVISUAL E INFRAESTRUTURA
    // SUB CATEGORY --> Infraestrutura
    {
        id: 135,
        name: 'Palco / Praticável',
        categoryId: 191,
        url: PalcoPraticavel
    },
    {
        id: 136,
        name: 'Tendas',
        categoryId: 192,
        url: Tendas
    },
    {
        id: 137,
        name: 'Boxtruss',
        categoryId: 193,
        url: Boxtruss
    },
    {
        id: 135,
        name: 'Estacionamento',
        categoryId: 194,
        url: Estacionamento
    },

    // SUB CATEGORY --> Audiovisual
    {
        id: 138,
        name: 'Equipamento De Som',
        categoryId: 196,
        url: EquipamentoDeSom
    },
    {
        id: 139,
        name: 'Equipamento De Iluminação',
        categoryId: 197,
        url: EquipamentoDeIluminacao
    },
    {
        id: 140,
        name: 'Equipamento De Vídeo',
        categoryId: 198,
        url: EquipamentoDeVideo
    },
    {
        id: 141,
        name: 'Streaming / Transmissão Ao Vivo',
        categoryId: 199,
        url: StreamingTransmissaoAoVivo
    },
    {
        id: 142,
        name: 'Locação De Eletrônicos',
        categoryId: 200,
        url: LocacaoDeEletronicos
    },
    {
        id: 143,
        name: 'Internet e Wi-fi',
        categoryId: 201,
        url: InternetEWifi
    },
    // END --> AUDIOVISUAL E INFRAESTRUTURA

    // CATEGORY --> FOTOGRAFIA E FILMAGEM
    // SUB CATEGORY --> Fotografia
    {
        id: 144,
        name: 'Fotógrafo(a)',
        categoryId: 204,
        url: FotografoA
    },
    {
        id: 145,
        name: 'Ensaio Fotográfico',
        categoryId: 205,
        url: EnsaioFotografico
    },
    {
        id: 146,
        name: 'Edição de Fotos',
        categoryId: 206,
        url: EdicaoDeFotos
    },

    // SUB CATEGORY --> Vídeo
    {
        id: 147,
        name: 'Vídeo Maker',
        categoryId: 208,
        url: VideoMaker
    },
    {
        id: 148,
        name: 'Edição de Vídeo',
        categoryId: 209,
        url: EdicaoDeVídeo
    },
    {
        id: 149,
        name: 'Equipe de Filmagem',
        categoryId: 210,
        url: EquipeDeFilmagem
    },
    // END --> FOTOGRAFIA E FILMAGEM

]