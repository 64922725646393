import { IonIcon, IonPage } from '@ionic/react'
import { reloadOutline } from 'ionicons/icons'
import './loaderpage.css'

const style = {
  width: '100vw !important',
  height: '100vh !important',
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'center',
  background: 'white'
}

export const LoaderPage: React.FC = () => {
  return (
    <IonPage style={style}>
      <div className='loadercontent'>
        <IonIcon icon={reloadOutline} className='loadericon' size='large'></IonIcon>
        <p>Carregando...</p>
      </div>
    </IonPage>
  )
}