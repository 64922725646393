import { useEffect, useState } from 'react'
import { IFacility, INewFacility } from '../../shelf.types'
import { useFacilities } from '../../hooks/useFacilities'
import { IonAlert, IonCol, IonIcon, IonLabel, IonRow, IonToggle } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'

interface IShelfFacilities {
  categoryID: number | null
  facilitiesState: INewFacility[]
  setFacilitiesState: (value: INewFacility[]) => void
}

const SheflFacilities: React.FC<IShelfFacilities> = ({
  categoryID,
  setFacilitiesState,
  facilitiesState,
}: IShelfFacilities) => {
  // Hooks
  const { isLoading, getFacilitiesByCategory } = useFacilities()
  // States
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [facilities, setFacilities] = useState<IFacility[]>([])
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null)
  const [level, setLevel] = useState<string>('')

  useEffect(() => {
    getFacilities()
  }, [categoryID])

  async function getFacilities() {
    if (typeof categoryID === 'number') {
      const response = await getFacilitiesByCategory(categoryID)
      if (typeof response === 'object') {
        const facilitiesData = response.facilities
        setFacilities(facilitiesData)
      }
    }
  }

  function handleLevel(value: string) {
    setLevel(value)
    if (selectedFacility) {
      handleFacilitiy(selectedFacility, true, value, false)
    }
  }

  function handleFacilitiy(
    facilityID: string,
    isAdding: boolean,
    level?: string | null,
    isSelectingLevel?: boolean,
  ) {
    const facility: INewFacility = {
      facilityId: facilityID,
      level: level || 'optional',
    }

    if (isSelectingLevel) {
      setIsOpen(true)
      setSelectedFacility(facilityID)
    } else {
      handleFacilitiesState(facility, isAdding)
      setIsOpen(false)
    }
  }

  function handleFacilitiesState(newFacility: INewFacility, isAdding: boolean) {
    if (isAdding) {
      const newFacilities: INewFacility[] = [...facilitiesState, newFacility]
      setFacilitiesState(newFacilities)
    } else {
      const newFacilities: INewFacility[] = [...facilitiesState]
      setFacilitiesState(newFacilities.filter((e) => e.facilityId !== newFacility.facilityId))
    }
    closeAlert()
  }

  function closeAlert() {
    setLevel('')
    setIsOpen(false)
  }

  return (
    <div>
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {facilities.length > 0 ? (
            <IonRow>
              {facilities.map((facility) => {
                return (
                  <IonCol key={facility.id} size='12' sizeXs='12' sizeSm='6' sizeMd='4' sizeLg='3'>
                    <IonToggle
                      onIonChange={(e) =>
                        handleFacilitiy(facility.id, e.detail.checked, null, e.detail.checked)
                      }
                      labelPlacement='end'
                      checked={facilitiesState.some((e) => e.facilityId === facility.id)}
                    >
                      {facility.name}
                    </IonToggle>
                  </IonCol>
                )
              })}

              <IonAlert
                isOpen={isOpen}
                onDidDismiss={() => closeAlert()}
                header='Selecione se é obrigatório ou opcional'
                buttons={['Salvar']}
                inputs={[
                  {
                    label: 'Obrigatório',
                    type: 'radio',
                    value: 'mandatory',
                    checked: level === 'mandatory',
                    handler: () => handleLevel('mandatory'),
                  },
                  {
                    label: 'Opcional',
                    type: 'radio',
                    value: 'optional',
                    checked: level === 'optional',
                    handler: () => handleLevel('optional'),
                  },
                ]}
              ></IonAlert>
            </IonRow>
          ) : (
            <IonCol
              size='12'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '2rem',
                border: '1px solid #14c4a8',
                backgroundColor: '#c4ecec',
                padding: '2rem',
                borderRadius: '0.5rem',
              }}
            >
              <IonIcon
                icon={checkmarkCircleOutline}
                style={{ fontSize: '24px', marginRight: '1rem' }}
              />
              <IonLabel className='custom'>
                A categoria escolhida não possui necessidades técnicas.
              </IonLabel>
            </IonCol>
          )}
        </>
      )}
    </div>
  )
}

export default SheflFacilities
