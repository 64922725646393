import logMetricsToFirebase from './logMetricsToFirebase';

const logMetricsToFile = (metricName: string, value: number) => {
  const logMessage = `${metricName}: ${value}\n`;
  const filePath = './../log.txt';
};

import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
  getCLS((metric: { value: number }) => logMetricsToFirebase('CLS', metric.value));
  getFID((metric: { value: number }) => logMetricsToFirebase('FID', metric.value));
  getFCP((metric: { value: number }) => logMetricsToFirebase('FCP', metric.value));
  getLCP((metric: { value: number }) => logMetricsToFirebase('LCP', metric.value));
  getTTFB((metric: { value: number }) => logMetricsToFirebase('TTFB', metric.value));
});

const reportWebVitals = (onPerfEntry:any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// explanations

// TTFB: O Time to First Byte (TTFB), ou Tempo para o Primeiro Byte em português, é uma métrica utilizada para medir o tempo que leva para o navegador receber o primeiro byte de resposta de um servidor web, após uma solicitação ter sido feita pelo usuário. Em outras palavras, o TTFB representa o tempo que leva para o servidor processar a solicitação e enviar a primeira parte dos dados de volta ao navegador.
// LCP: focada em medir a velocidade de carregamento que é percebida pelo usuário.
// FID: “atraso na primeira entrada”, serve para medir quanto tempo o navegador leva para responder à primeira interação do usuário. Nesse meio tempo, o navegador faz muitas tarefas ao mesmo tempo, e para agilizar esse processo, é preciso focar no FID.
// CLS: principal da Web é o CLS, ou, deslocamento de layout cumulativo. Em resumo, essa métrica tem o intuito de determinar o quão estável os elementos da página carregam, vendo, com qual frequência, mudanças inesperadas acontecem no layout.
// FCP: A Primeira Exibição de Conteúdo, ou FCP, é outra métrica de performance essencial. A FCP mede o momento em que o primeiro conteúdo do DOM é renderizado – o que significa que o primeiro elemento HTML é exibido. Podem ser textos, imagens ou qualquer coisa que o usuário reconheça como parte da página web. A Primeira Exibição de Conteúdo é diferente da Primeira Exibição (FP), que mede quando qualquer elemento da página é renderizado.

export default reportWebVitals;
