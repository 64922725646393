const firebaseConfig = {
    apiKey: 'AIzaSyBY9T7XsK2G8CrVGVj9XBn-Bxz3y9t4V2Q',
    authDomain: 'celebrar-teste.firebaseapp.com',
    projectId: 'celebrar-teste',
    storageBucket: 'celebrar-teste.appspot.com',
    messagingSenderId: '146415347308',
    appId: '1:146415347308:web:f89d5b78f7cc1d92b41a49',
    measurementId: 'G-XTLDXP4ZPC'
  };
  
  export default firebaseConfig;