import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonItem,
  IonRow,
} from '@ionic/react'
import { Collapse, CollapseGroup } from '../../../../components/Collapse/collapse'
import { ISubItemsGroup } from '../../shelf.types'
import { pencilOutline, trashOutline } from 'ionicons/icons'
import { useState } from 'react'
import useToast from '../../../../hook/useToast'
import { useLocation } from 'react-router'

interface ISubItemsList {
  subItemsGroups: ISubItemsGroup[] | null
  setSubItemsGroups: (value: ISubItemsGroup[]) => void
  setIsSubItemsModalOpen: (value: boolean) => void
  setSelectedSubItem: (value: ISubItemsGroup | null) => void
  isEditItem: boolean
}

const ShelfSubItemList: React.FC<ISubItemsList> = ({
  subItemsGroups,
  setSubItemsGroups,
  setIsSubItemsModalOpen,
  setSelectedSubItem,
  isEditItem,
}: ISubItemsList) => {
  // Hooks
  const { presentToast } = useToast()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  async function deleteSubItemGroup(groupIndex: number) {
    if (subItemsGroups) {
      const newGroup = [...subItemsGroups]
      newGroup.splice(groupIndex, 1)
      setSubItemsGroups(newGroup)
      presentToast('Grupo removido com sucesso!', 'success')
    }
  }

  function handleEditGroup(subItemGroup: ISubItemsGroup) {
    setSelectedSubItem(subItemGroup)
    setIsSubItemsModalOpen(true)
  }

  return (
    <CollapseGroup>
      {subItemsGroups?.map((group: ISubItemsGroup, index: number) => {
        return (
          <Collapse
            title={`${group.name} (quantidade para escolha: ${group.quantity})`}
            key={group.id}
          >
            <IonRow>
              {group.subItems.map((subitem, index: number) => {
                return (
                  <IonCol key={index} size='12' sizeSm='12' sizeMd='4'>
                    <IonItem>
                      <IonCheckbox slot='start' checked disabled />
                      {subitem.name}
                    </IonItem>
                  </IonCol>
                )
              })}
            </IonRow>
            <IonButtons style={{ margin: '2rem auto' }}>
              {isEditItem ? (
                <IonButton fill='outline' onClick={() => handleEditGroup(group)}>
                  <IonIcon slot='start' icon={pencilOutline} />
                  Editar grupo
                </IonButton>
              ) : (
                <></>
              )}

              {location?.pathname?.includes('item') && (
                <IonButton
                  fill='outline'
                  color='danger'
                  size='small'
                  onClick={() => setIsOpen(true)}
                >
                  <IonIcon slot='start' icon={trashOutline} />
                  Excluir grupo
                </IonButton>
              )}
              <IonAlert
                isOpen={isOpen}
                header=' Excluir grupo'
                message='Essa ação não podera ser desfeita. Deseja mesmo excluir este grupo?'
                buttons={[
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                      ;() => setIsOpen(false)
                    },
                  },
                  {
                    text: 'Excluir',
                    role: 'delete',
                    handler: () => deleteSubItemGroup(index),
                  },
                ]}
                onDidDismiss={() => setIsOpen(false)}
              />
            </IonButtons>
          </Collapse>
        )
      })}
    </CollapseGroup>
  )
}

export default ShelfSubItemList
