import { createRef, useEffect } from 'react'
// Ionic Components
import { IonLabel } from '@ionic/react'
// Project Components
import { Container } from '../../../components/Container'
import { IShelfItem } from '../shelf.types'
import { ShelfForm } from './shelfForm/shelfForm'

interface IModal {
  shelfItem: IShelfItem | null
}

export const ShelfModal: React.FC<IModal> = ({ shelfItem }: IModal) => {
  const contentRef = createRef<HTMLIonContentElement>()

  function scrollToTop() {
    contentRef.current?.scrollToTop(500)
  }

  return (
    <>
      <Container>
        <IonLabel className='custom mb-20'>Informações básicas</IonLabel>
        <br />
        <br />

        <ShelfForm scrollToTop={scrollToTop} shelfItem={shelfItem} />
      </Container>
    </>
  )
}
