import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonAlert,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { Container } from '../../../components/Container'
import { useAuth } from '../hooks/useAuth'
import { useCompleteRegistration } from '../hooks/useCompleteRegistration'
import { useViaCep } from '../../../hook/useViaCep'
import './style.css'

const estados = [
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'TO', nome: 'Tocantins' },
]

interface IRegisterAddress {
  setCurrentStep?: any
}

const RegisterAddress: React.FC<IRegisterAddress> = ({ setCurrentStep }: IRegisterAddress) => {
  // Hooks
  const { cepData, error, getCepData } = useViaCep()
  const { address } = useCompleteRegistration()
  const [presentAlert] = useIonAlert()
  const { registerCompanyAddress } = useAuth()
  // States
  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [uf, setUf] = useState('')
  const [city, setCity] = useState('')
  const [neighborhood, setNeighbordhood] = useState('')
  const [complement, setComplement] = useState('')
  const [number, setNumber] = useState<string | null>('')

  useEffect(() => {
    if (address) {
      setCep(address?.cep)
      setStreet(address?.street)
      setNeighbordhood(address?.neighborhood)
      setCity(address?.city)
      setUf(address?.uf)
      setComplement(address?.complement)
      setNumber(address?.number)
    }
  }, [address])

  useEffect(() => {
    if (cep.length === 8) {
      getCepData(cep)
    }
  }, [cep])

  useEffect(() => {
    if (cepData?.uf) {
      setCep(cepData.cep)
      setStreet(cepData.logradouro)
      setUf(cepData.uf)
      setCity(cepData.localidade)
      setNeighbordhood(cepData.bairro)
      setComplement(cepData.complemento)
    } else if (error) {
      presentAlert({
        header: 'CEP não encontrado',
        subHeader: '',
        message: 'Por favor, informe um cep válido',
        buttons: ['OK'],
        cssClass: 'alert-card',
      })
      // Filtra o estado correspondente ao uf retornado pelo ViaCEP
      setCep('')
      setStreet('')
      setUf('')
      setCity('')
      setNeighbordhood('')
      setComplement('')
      setNumber('')
    }
  }, [cepData])

  function handleEstadoSelecionado(event: React.FormEvent<HTMLFormElement>) {
    setUf(event.currentTarget.value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setCurrentStep(4)
    e.preventDefault()
    registerCompanyAddress({
      cep: cep,
      street: street,
      uf: uf,
      city: city,
      neighborhood: neighborhood,
      complement: complement,
      number: number,
    })
  }

  return (
    <Container>
      <form onSubmit={(e) => handleSubmit(e)}>
        <IonGrid>
          <IonRow>
            <IonCol size='12' style={{ paddingRigth: '1rem', marginBottom: '0.5rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                CEP
              </IonLabel>
              <IonItem className=' '>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setCep((e.target as HTMLInputElement).value)}
                  value={cep}
                  type='text'
                  maxlength={9}
                ></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size='12' sizeMd='6' sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Estado
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonSelect value={uf} onIonChange={() => handleEstadoSelecionado}>
                  {estados.map((estado) => (
                    <IonSelectOption key={estado.sigla} value={estado.sigla}>
                      {estado.nome} ({estado.sigla})
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>

            <IonCol size='12' sizeMd='6' sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Cidade
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setCity((e.target as HTMLInputElement).value)}
                  value={city}
                  type='text'
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Rua
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setStreet((e.target as HTMLInputElement).value)}
                  value={street}
                  type='text'
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Bairro
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setNeighbordhood((e.target as HTMLInputElement).value)}
                  value={neighborhood}
                  type='text'
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='4' sizeMd='6' sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Número
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setNumber((e.target as HTMLInputElement).value)}
                  value={number}
                  type='text'
                ></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel style={{ fontSize: '1rem', marginLeft: '1rem' }} position='stacked'>
                Complemento
              </IonLabel>
              <IonItem className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setComplement((e.target as HTMLInputElement).value)}
                  value={complement}
                  type='text'
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' style={{ paddingRigth: '1rem' }}>
              <IonButton type='submit'>Continuar</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </Container>
  )
}

export default RegisterAddress
