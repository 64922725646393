export const formatCpf = (value: string): string => {
  // Remove all non-digit characters from the input value
  const cleanedValue = value.replace(/\D/g, '')

  // Check if the cleaned value is a valid CPF
  if (cleanedValue.length === 11) {
    // Format the CPF with dots and dashes
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  // Return the cleaned value if it's not a valid CPF
  return cleanedValue
}
