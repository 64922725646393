import React from 'react';
import { IonIcon } from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';

interface Props {
  value: number;
  onChange?: (newValue: number) => void;
}

const Rating: React.FC<Props> = ({ value, onChange }) => {
  const handleClick = (newValue: number) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const renderStar = (index: number) => {
    const filled = index < value;
    const iconName = filled ? star : starOutline;

    return (
      <IonIcon
        key={index}
        icon={iconName}
        onClick={() => handleClick(index + 1)}
        style={{ fontSize: '3rem', color: 'gold', cursor: 'pointer' }}
      />
    );
  };

  const stars = Array.from({ length: 10 }, (_, index) => renderStar(index));

  return <div>{stars}</div>;
};

export default Rating;