// Ionic components
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonAvatar,
  IonChip,
  IonRouterLink,
  IonPopover,
} from '@ionic/react'
import { caretDownOutline, logOutOutline, personCircleOutline } from 'ionicons/icons'


import './menu.css'
import { useAuth } from '../../features/auth/hooks/useAuth'
import { useCompleteRegistration } from '../../features/auth/hooks/useCompleteRegistration'

export const Menu: React.FC = () => {
  const { company } = useCompleteRegistration();
  const { supplierUserData, logOut } = useAuth();

  function handleLogOut() {
    logOut()
    history.go(0)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }} className='menuDesk'>
      <IonRouterLink href='/webStore'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Lojinhas</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonRouterLink href='/'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Painel de vendas</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonRouterLink href='/prateleira'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Prateleira</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonRouterLink href='/eventos'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Próximos eventos</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonRouterLink href='/carteira'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Carteira</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonRouterLink href='/categorias'>
        <IonItem button style={{ '--background': '#7A29F2' }}>
          <IonLabel style={{ color: 'white' }}>Categorias</IonLabel>
        </IonItem>
      </IonRouterLink>

      <IonItem slot='header' id='click-trigger' style={{ '--background': '#7A29F2' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IonChip style={{ cursor: 'pointer', color: 'white' }}>
            {company?.picture ? (
              <IonAvatar>
                <img src={company.picture} />
              </IonAvatar>
            ) : (
              <IonAvatar>
                <img
                  alt="Silhouette of a person's head"
                  src='https://ionicframework.com/docs/img/demos/avatar.svg'
                />
              </IonAvatar>
            )}
            <IonLabel color='white' style={{ display: 'flex', alignItems: 'center' }}>
              {supplierUserData?.email}
              <IonIcon slot='end' icon={caretDownOutline} style={{ marginLeft: '0.5rem' }} />
            </IonLabel>
          </IonChip>
        </div>
      </IonItem>
      <IonPopover trigger='click-trigger' triggerAction='click'>
        <IonRouterLink href='/perfil'>
          <IonItem button lines='none' detail={false} style={{ cursor: 'pointer' }}>
            <IonIcon slot='start' ios={personCircleOutline} md={personCircleOutline} />
            Ver perfil
          </IonItem>
        </IonRouterLink>

        <IonItem
          button
          lines='none'
          detail={false}
          onClick={handleLogOut}
          style={{ cursor: 'pointer' }}
        >
          <IonIcon slot='start' ios={logOutOutline} md={logOutOutline} />
          <IonLabel>Sair</IonLabel>
        </IonItem>
      </IonPopover>
    </div>
  )
}
