import React from 'react';
import {
  IonContent,
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
} from '@ionic/react';

import './newPassword.css';
import { Heading } from '../../../components/Heading';
import { Container } from '../../../components/Container';
import { NewPasswordForm } from '../../../features/auth/components/newPasswordForm';

const NewPassword: React.FC = () => {

  return (
    <IonPage>
       <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton style={{ '--color': 'var(--color-white)' }}>
              <IonBackButton className='icon-back' defaultHref="/" text="">
                <IonIcon name="arrow-back-outline"></IonIcon>
              </IonBackButton>
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton>
              <IonIcon slot="icon-only" name=""></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg-ice">
        <IonGrid>
        <Container >
          <IonRow className="ion-justify-content-center form">
            {/* <IonCol size="12">
              <IonImg className='celebrarLogoLogin' src="../assets/images/celebrar_logo.png" alt="The Wisconsin State Capitol building in Madison, WI at night"></IonImg>
            </IonCol> */}
            <IonCol size="12" size-sm="7" size-md="7">
              <Heading level='h2'>
                Redefinir senha
              </Heading>
              <p>Digite sua nova senha</p>
            </IonCol>
            <IonCol size="12" size-sm="7" size-md="7">
              <NewPasswordForm />
            </IonCol>          
          </IonRow>
          </Container>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NewPassword;
