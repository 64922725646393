import { useState } from 'react';
import { useIonToast } from '@ionic/react';

export function useToast() {
  const [present] = useIonToast();
  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');

  function presentToast(message: string, color: string) {
    present({
      message: message,
      duration: 3500,
      position: 'top',
      color: color,
      onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          handler: () => { setHandlerMessage('Dismiss clicked'); }
        }
      ]
    });
  };

  return {
    presentToast
  }
}
export default useToast;