import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { ISubItemsGroup, IValueRanges, IVariations } from '../../shelf.types'
import { useEffect, useState } from 'react'
import { Container } from '../../../../components/Container'
import ShelfSubItemForm from './shelfSubItemForm'
import { addCircleOutline } from 'ionicons/icons'
import { IParams } from '../../../../pages/shelf/CreateShelf'
import ShelfFormPrice from './shelfFormPrice'
import useToast from '../../../../hook/useToast'
import { ISelectedVariation } from './shelfForm'

interface IShelfVariations {
  variations: IVariations[]
  setVariations: (value: IVariations[]) => void
  setIsOpenExempleSubItems: (value: boolean) => void
  params?: IParams
  selectedVariation: ISelectedVariation | null
  priceMethod: string
  setPriceMethod: (value: string) => void
}

const ShelfVariation: React.FC<IShelfVariations> = ({
  variations,
  setVariations,
  setIsOpenExempleSubItems,
  selectedVariation,
  priceMethod,
  setPriceMethod,
}: IShelfVariations) => {
  // Hooks
  const { presentToast } = useToast()
  // States
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [valueRangesData, setValueRangesData] = useState<IValueRanges[]>([])
  const newSubItemsGroup: ISubItemsGroup = {
    name: '',
    quantity: 1,
    subItems: [{ name: '' }],
  }
  const [subItemsGroups, setSubItemsGroups] = useState([newSubItemsGroup])
  const [lastRowMaxValue, setLastRowMaxValue] = useState<number>(0)
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    if (selectedVariation) {
      const variationData = selectedVariation.variation
      setName(variationData.name)
      setDescription(variationData.description)
      setValueRangesData(variationData.valueRanges)
      setSubItemsGroups(variationData.subItemGroups)
      setIsOpen(true)
    }
  }, [selectedVariation])

  function handleMethodPrice(value: string) {
    if (value) {
      setPriceMethod(value)
    }
  }

  function handleVariation() {
    const newVariation: IVariations = {
      name: name,
      description: description,
      valueRanges:
        valueRangesData.length > 0
          ? valueRangesData
          : [{ initialUnit: 1, value: value, taxaDeRepasse: value * 0.9 }],
      valueType: 'unit',
      subItemGroups: subItemsGroups,
      isActive: true,
    }

    if (selectedVariation) handleEditVariation(newVariation)
    else handleAddVariation(newVariation)

    setLastRowMaxValue(0)
  }

  function handleAddVariation(newVariation: IVariations) {
    setVariations([...variations, newVariation])
    presentToast('Variação salva com sucesso!', 'success')
    setIsOpen(false)
  }

  function handleEditVariation(newVariation: IVariations) {
    if (selectedVariation) {
      const newVariations = [...variations]
      newVariations[selectedVariation.index] = newVariation
      setVariations(newVariations)
      presentToast('Item editado com sucesso!', 'success')
      setIsOpen(false)
    }
  }

  function closeForm() {
    clearForm()
    setIsOpen(false)
  }

  function clearForm() {
    setName('')
    setDescription('')
    setValueRangesData([])
    setSubItemsGroups([])
  }

  return (
    <IonRow>
      <IonModal onDidDismiss={() => closeForm()} isOpen={isOpen} className='custom-modal'>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Criar variação de item</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => closeForm()}>Fechar</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent style={{ padding: '2ren 0' }}>
          <Container>
            <IonRow>
              <IonCol size='12'>
                <div className='step' style={{ backgroundColor: '#FF0078' }}>
                  Etapa 1 - Informações do item
                </div>
                <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
                  Preencha todos os dados do seu item cuidadosamente. Certifique-se de incluir o
                  nome do item e a descrição detalhada.
                </p>
                <h6>
                  Nome do Item <span style={{ color: 'red' }}>*</span>
                </h6>
                <IonItem className='custom mb-20'>
                  <IonInput
                    placeholder='Insira o nome do seu item'
                    type='text'
                    name='name'
                    clearInput
                    className='custom'
                    value={name}
                    required
                    onIonInput={(event) =>
                      event.target.value && setName(event.target?.value.toString())
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg='12'>
                <h6>
                  Descrição <span style={{ color: 'red' }}>*</span>
                </h6>
                <IonItem className='custom'>
                  <IonTextarea
                    rows={5}
                    placeholder='Descrição do item'
                    name='description'
                    autoGrow
                    className='custom'
                    value={description}
                    required
                    onIonInput={(event) =>
                      event.target.value && setDescription(event.target?.value.toString())
                    }
                  />
                </IonItem>
                <div style={{ marginTop: '0.5rem', fontSize: '0.85rem' }}>
                  Para uma descrição completa, envie todas as informações sobre o seu serviço ou
                  produto. Lembre-se de cuidar da grafia das palavras e evite incluir contatos da
                  sua empresa, como telefone e e-mail.
                </div>
              </IonCol>

              <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />

              <IonCol sizeLg='9' size='12'>
                <div className='step' style={{ backgroundColor: '#FF0078' }}>
                  Etapa 2 - Complementos (Opcional)
                </div>
                <p style={{ marginTop: '-2.25rem', marginBottom: '3rem', maxWidth: '800px' }}>
                  Complementos são a maneira de personalizar seu item! Por exemplo, no seu cardápio
                  de coffee break, com variedades de salgados. Basta adicionar a seção “Salgados” e
                  incluir opções como “Pão de Queijo”, “Coxinha” e “Pão de Queijo vegano”.
                  <div
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                    onClick={() => setIsOpenExempleSubItems(true)}
                  >
                    Ver exemplo de preenchimento
                  </div>
                </p>
              </IonCol>
              <IonCol
                sizeLg='3'
                size='12'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <IonButton
                  className='button-picture'
                  color={'success'}
                  style={{ marginRight: '1rem' }}
                  onClick={() => setSubItemsGroups([...subItemsGroups, newSubItemsGroup])}
                >
                  <IonIcon icon={addCircleOutline} />
                  <div style={{ color: 'white', marginLeft: '0.5rem' }}>Adicionar nova seção</div>
                </IonButton>
              </IonCol>

              <IonCol size='12'>
                {subItemsGroups?.map((group, index) => {
                  return (
                    <ShelfSubItemForm
                      key={index}
                      index={index}
                      subitem={group}
                      subItemsGroup={subItemsGroups}
                      setSubItemsGroups={setSubItemsGroups}
                    />
                  )
                })}
              </IonCol>

              <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />

              {/* ================ PRICES ================ */}
              <IonCol size='12'>
                <div className='step' style={{ backgroundColor: '#FF0078' }}>
                  Etapa 3 - Precificação
                </div>
                <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
                  O modo de cobrança varia conforme o serviço: por exemplo, por unidade é sugerido
                  para brindes, por pessoa é sugerido para alimentação e por pacote é sugerido para
                  entretenimento.
                </p>
              </IonCol>

              <ShelfFormPrice
                priceMethod={priceMethod}
                handleMethodPrice={handleMethodPrice}
                lastRowMaxValue={lastRowMaxValue}
                setLastRowMaxValue={setLastRowMaxValue}
                setValueRangesData={setValueRangesData}
                valueRangesData={valueRangesData}
                setValue={setValue}
                value={value}
                variationsLength={variations.length}
              />

              <IonButton
                type='button'
                onClick={handleVariation}
                style={{ width: '100%', margin: '2rem 0rem' }}
                disabled={Boolean(
                  priceMethod.toLocaleLowerCase() === 'preço / por pacote'
                    ? value < 1
                    : valueRangesData.length < 1,
                )}
              >
                Salvar Variação
              </IonButton>
            </IonRow>
          </Container>
        </IonContent>
      </IonModal>

      <IonCol size='12'>
        <IonButton onClick={() => setIsOpen(true)}>Adicionar variação</IonButton>
      </IonCol>
    </IonRow>
  )
}

export default ShelfVariation
