import { useState } from 'react';
import api from '../../../api/api';
import useToast from '../../../hook/useToast';

// interface IUploadedUrlImages{
//   url: string
// }

export function useImageUpload() {
  const [uploadedUrlImages, setUploadedUrlImages] = useState<string[]>();
  const [uploadedUrlImagesReturned, setUploadedUrlImagesReturned] = useState<string[] | null>();

  /**
 * Uploads an array of files to the server and sets the uploaded image URLs in the state.
 * @param files An array of files to upload
 */
  async function uploadImage(files: File[] | null, sendTo: string, paymentId: string | undefined): Promise<string[]> {
    // Clear previously uploaded image URLs
    setUploadedUrlImages([]); // Limpa o array antes de adicionar novas URLs
  
    let uploadPromises: Promise<string>[] = [];
  
    if (sendTo === 'image') {
      uploadPromises = (files ?? []).map((file) => {
        const form = new FormData();
        form.append('image', file);
        // Make the API request to upload the image
        return api
          .post('/demands/supplier/offers/image', form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            // Set the uploaded image URL in the state
            return response.data.url;
          })
          .catch(() => ''); // Trate erros retornando uma string vazia
      });
    } else if (sendTo === 'pdf') {
      uploadPromises = (files ?? []).map((file) => {
        const form = new FormData();
        form.append('invoice', file);
  
        // Make the API request to upload the image
        return api
          .post(`/payments/supplier/${paymentId}/invoice`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            // Set the uploaded image URL in the state
            return response.data.url;
          })
          .catch(() => ''); // Trate erros retornando uma string vazia
      });
    }
  
    const urls = await Promise.all(uploadPromises);
    // Set the uploaded image URLs in the state
    setUploadedUrlImages(urls.filter(Boolean));
    setUploadedUrlImagesReturned(urls.filter(Boolean));
  
    return urls;
  }
  
  async function uploadPicture(file: File[] | null, sendTo: string) {
    // Clear previously uploaded image URLs
    setUploadedUrlImages([]); // Limpa o array antes de adicionar novas URLs
    // Loop through the files and upload each one
    if (sendTo === 'image') {
      await Promise.all(
        file?.map((file) => {
          const form = new FormData();
          form.append('avatar', file);
          // Make the API request to upload the image
          return api
            .post('/suppliers/avatar', form, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
              // Set the uploaded image URL in the state
              if (response !== undefined) {
                return response.data.url;
              }
            });
        }) ?? []
      ).then((urls) => {
        // Set the uploaded image URLs in the state
        setUploadedUrlImages(urls.filter(Boolean));
        setUploadedUrlImagesReturned(urls.filter(Boolean));
      });
    }
  }

  return {
    uploadPicture, uploadImage, uploadedUrlImages, uploadedUrlImagesReturned, setUploadedUrlImagesReturned
  }
}
