import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import './categories.css';
import { OverlayEventDetail } from '@ionic/core/components';
import { CategoriesPanel } from '../../features/categories/components/CategoriesPanel';
import { AreasPanel } from '../../features/categories/components/AreasPanel';
import { useCategories } from '../../features/categories/hooks/useCategories';
import { useHistory } from 'react-router';
import { CategoriesUserPanel } from '../../features/user/components/CategoriesUserPanel';


interface IUserCategories {
  setCurrentStep?: any
}

const UserCategories: React.FC<IUserCategories> = ({ setCurrentStep }: IUserCategories) => {
  const { getRegisteredCategories } = useCategories()
  const history = useHistory()
  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState<number>(1);
  const [isModalOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getRegisteredCategories()
  }, [])

  return (



    <IonGrid>
      <IonRow >
        <IonRow>
          <AreasPanel setCategoryId={setCategoryId} setModalIsOpen={setModalIsOpen} setCategoryName={setCategoryName} categoryId={categoryId} setCurrentStep={setCurrentStep} />
          <div id='step1' style={{ width: '100%' }}>
            {isModalOpen === true &&
              <CategoriesPanel categoryId={categoryId} setCurrentStep={setCurrentStep} />
            }
          </div>
        </IonRow>
        <IonCol size="12" >
          <h3>Categorias adicionadas:</h3>
          <CategoriesUserPanel />
        </IonCol>
      </IonRow>
      <IonRow >
      </IonRow>
    </IonGrid>


  );
};

export default UserCategories;
