import { useState } from 'react';
import { IonButton, IonCol, IonInput, IonItem, IonLabel, useIonAlert } from '@ionic/react';
import { useAuth } from '../hooks/useAuth';
import { useHistory, useParams } from 'react-router';

export interface IToken {
  token: string
}
export const NewPasswordForm: React.FC = () => {
  const { ResetNewPassword } = useAuth()
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmationPassword] = useState('');
  const [isEqualPassword, setIsEqualPassword] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState('');
  const [isComplex, setIsComplex] = useState(false);
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const [touchedConfirmedPassword, setTouchedConfirmedPassword] = useState(false);
  const token: IToken = useParams();


  /**
 * @param event 
 * handleLogin: This function is called when the user clicks the login button. 
 * It checks if the password and confirmed password match, and if so, calls the Register function and redirects 
 * the user to the home page. If they do not match, it presents an alert and clears the password and confirmed password fields.
 */
  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateForm();

  };

  const validateForm = () => {
    if (!isComplex) {
      presentAlert('Por favor, revise sua senha');
      setPassword('');
      return
    }
    if (!isEqualPassword) {
      presentAlert('Por favor, a confirmação de senha deve ser igual a senha informada');
      setConfirmationPassword('');
      return
    }
    ResetNewPassword({ newPassword: password, newPasswordConfirmation: confirmPassword, token: token.token })
    history.push('/');
  };

  /**
   * @param event 
   * handlePasswordChange: This function is called when the user types in the password field. It sets the state of touchedPassword 
   * to true and updates the password state value. It then calls the isPasswordComplex function to check if the password is complex enough.
   */
  const handlePasswordChange = (event: CustomEvent) => {
    const password = event.detail.value;
    setTouchedPassword(true);
    setPassword(password);
    isPasswordComplex(password);
  };

  /*
// isPasswordComplex: This function takes in a password and checks if it meets certain complexity requirements, 
// such as having at least 8 characters, containing lowercase and uppercase letters, numbers, and special characters. 
// If the password is valid, it sets the state of isComplex to true and calls the verifyPasswordValid and validateForm functions.
*/
  const isPasswordComplex = (password: string) => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[.'-_+=!@#$%^&*()]/;

    const isPasswordValid =
      password.length >= 8 &&
      lowercaseRegex.test(password) &&
      uppercaseRegex.test(password) &&
      numberRegex.test(password) &&
      specialCharRegex.test(password);

    if (isPasswordValid) {
      setPassword(password);
      verifyPasswordValid(password);
    }
    setIsComplex(isPasswordValid);
  };

  const verifyPasswordValid = (password: string) => {
    setPasswordValid(password);
  };

  /**
 * @param event 
 * handleConfirmedPasswordChange: This function is called when the user types in the confirmed password field. 
 * It sets the state of touchedConfirmedPassword to true and updates the confirmed password state value. 
 * It checks if the confirmed password matches the valid password, and then calls the validateForm function.
 */
  const handleConfirmedPasswordChange = (event: CustomEvent) => {
    const confirmedPassword = event.detail.value;
    setTouchedConfirmedPassword(true);
    setConfirmationPassword(confirmedPassword);
    setIsEqualPassword(confirmedPassword === passwordValid);
  };

  return (
    <>
      <IonCol size="12" >
        { /* Form login :) */}
        <form onSubmit={handleLogin}>
          <IonLabel>Nova senha</IonLabel>
          <IonItem className="custom mb-20" >
            <IonInput
              clearInput={true}
              onBlur={() => setTouchedPassword(true)}
              onClick={() => setTouchedPassword(true)}
              onIonInput={handlePasswordChange}
              value={password}
              type="password">
            </IonInput>
          </IonItem>
          {!isComplex && touchedPassword && <p>A senha deve ter pelo menos 8 caracteres e conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.</p>}
          <IonLabel>Confirme a senha</IonLabel>
          <IonItem className="custom mb-20">
            <IonInput
              clearInput={true}
              onClick={() => setTouchedConfirmedPassword(true)}
              onBlur={() => setTouchedConfirmedPassword(true)}
              onIonInput={handleConfirmedPasswordChange}
              value={confirmPassword}
              type="password">
            </IonInput>
          </IonItem>
          {!isEqualPassword && touchedConfirmedPassword && <p>A confirmação de senha deve ser igual a senha digitada.</p>}

          <IonButton className="custom" expand='block' type='submit'>
            Enviar
          </IonButton>
        </form>


      </IonCol>
    </>
  )

}