// Ionic components
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
  useIonAlert,
} from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
// Project components
import { formatDate } from '../../../utils/google-auth/dateFunctions'
import { IShelfItem, ISubItemsGroup, IToppings, IValueRanges } from '../shelf.types'
import { convertToBRACurrency } from '../../../utils/google-auth/currencyFunctions'
import { useEffect, useState } from 'react'
import { useShelf } from '../hooks/useShelf'
import useToast from '../../../hook/useToast'
import { useHistory } from 'react-router'
import { calendarNumberOutline, closeOutline, createOutline, trashOutline } from 'ionicons/icons'

import { categoriesImages } from '../../categories/components/CategoriesImages'
import { Container } from '../../../components/Container'
import { Heading } from '../../../components/Heading'
import { Link } from 'react-router-dom'
import ShelfSubItemList from './shelfForm/shlefSubItemList'
import ShelfVariationsList from './shelfForm/ShelfVariationsList'
import { ISelectedVariation } from './shelfForm/shelfForm'

interface IShelfCard {
  shelfItem: IShelfItem
  setSelectedItem: (shelfItem: IShelfItem) => void
}

export const ShelfCard: React.FC<IShelfCard> = ({ shelfItem, setSelectedItem }: IShelfCard) => {
  // Hooks
  const { deleteShelfItem } = useShelf()
  const { presentToast } = useToast()
  const history = useHistory()
  const [presentAlert] = useIonAlert()
  // States
  const [isImageOpen, setIsImageOpen] = useState(false)
  const [lastPart, setlastPart] = useState<string | undefined>()
  const [selectedvariation, setSelectedVariation] = useState<ISelectedVariation | null>(null)
  // SUBITEM
  const [isSubItemsModalOpen, setIsSubItemsModalOpen] = useState<boolean>(false)
  const [subItemsGroups, setSubItemsGroups] = useState(shelfItem?.subItemGroups)
  const [selectedSubItem, setSelectedSubItem] = useState<ISubItemsGroup | null>(null)

  useEffect(() => {
    setSubItemsGroups(shelfItem?.subItemGroups)
  }, [shelfItem?.subItemGroups])

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    return ev
  }

  function handleDeleteItem(itemId: string) {
    deleteShelfItem(itemId)
    presentToast('Item apagado', 'success')
    setTimeout(() => {
      history.go(0)
    }, 800)
  }

  useEffect(() => {
    const url = window.location.href
    const partUrl = url.split('/')
    setlastPart(partUrl.pop()) // Remove e retorna o último elemento do array
  }, [])

  return (
    <>
      {isImageOpen && (
        <IonModal
          isOpen={isImageOpen}
          className='modal-semi-fullscreen '
          onWillDismiss={(ev) => {
            setIsImageOpen(false), onWillDismiss(ev)
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='end'>
                <IonButton className='font-color-white' onClick={() => setIsImageOpen(false)}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            <Container>
              <IonRow className='ion-justify-content-center' style={{ overflowY: 'auto' }}>
                <IonCol
                  size='12'
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Heading level='h2'>{shelfItem?.name}</Heading>

                  <small>Criado em: {formatDate(shelfItem?.createdAt, 'long')}</small>
                </IonCol>
                {shelfItem?.images?.map((image: string, index: number) => {
                  return (
                    <IonCol size={'12'} sizeLg='6' key={index}>
                      <div
                        style={{
                          backgroundImage: `url('${image}')`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          height: '20rem',
                          borderRadius: '1rem',
                          width: '100%',
                        }}
                      />
                    </IonCol>
                  )
                })}
                <IonCol size='12'>
                  <IonRow style={{ marginTop: '1rem' }}>
                    <IonCol size='12' sizeLg='6'>
                      Tipo:{' '}
                      {shelfItem.serviceType === 'delivery' ? (
                        <IonChip color='primary'>Entrega</IonChip>
                      ) : (
                        <IonChip color='primary'>Serviço</IonChip>
                      )}
                    </IonCol>
                    {shelfItem.serviceType !== 'delivery' ? (
                      <IonCol size='12' sizeLg='6'>
                        Duração:<IonChip color='primary'>{shelfItem.serviceDuration}</IonChip>
                      </IonCol>
                    ) : null}
                    <IonCol size='12' sizeLg='6'>
                      Elegível para Web stores:{' '}
                      {shelfItem.isAvailableInWebstores === true ? (
                        <IonChip color='success'>Sim</IonChip>
                      ) : (
                        <IonChip color='danger'>Não</IonChip>
                      )}
                    </IonCol>
                    {shelfItem.links ? (
                      <IonCol size='12' sizeLg='6'>
                        Links : {shelfItem.links}
                      </IonCol>
                    ) : null}
                    <IonCol size='12' sizeLg='6'>
                      Método de cobrança :<IonChip color='primary'>{shelfItem.priceMethod}</IonChip>
                    </IonCol>
                  </IonRow>
                </IonCol>
                {shelfItem?.valueRanges[0]?.finalUnit ? (
                  <>
                    <IonCol>
                      <h3>Valores:</h3>
                      <IonRow>
                        <IonCol
                          size='3'
                          style={{
                            textAlign: 'center',
                            border: '1px solid grey',
                            backgroundColor: 'silver',
                          }}
                        >
                          Quantidade min
                        </IonCol>
                        <IonCol
                          size='3'
                          style={{
                            textAlign: 'center',
                            border: '1px solid grey',
                            backgroundColor: 'silver',
                          }}
                        >
                          Quantidade max
                        </IonCol>
                        <IonCol
                          size='3'
                          style={{
                            textAlign: 'center',
                            border: '1px solid grey',
                            backgroundColor: 'silver',
                          }}
                        >
                          Valor
                        </IonCol>
                        <IonCol
                          size='3'
                          style={{
                            textAlign: 'center',
                            border: '1px solid grey',
                            backgroundColor: 'silver',
                          }}
                        >
                          Valor do repasse
                        </IonCol>
                      </IonRow>
                      {shelfItem?.valueRanges.map((ranges: IValueRanges, index: number) => {
                        return (
                          <IonRow key={index}>
                            <IonCol
                              size='3'
                              style={{ textAlign: 'center', border: '1px solid grey' }}
                            >
                              <div>{ranges?.initialUnit}</div>
                            </IonCol>
                            <IonCol
                              size='3'
                              style={{ textAlign: 'center', border: '1px solid grey' }}
                            >
                              <div>{ranges?.finalUnit}</div>
                            </IonCol>
                            <IonCol
                              size='3'
                              style={{ textAlign: 'center', border: '1px solid grey' }}
                            >
                              <div>{convertToBRACurrency(ranges?.value)}</div>
                            </IonCol>
                            <IonCol
                              size='3'
                              style={{ textAlign: 'center', border: '1px solid grey' }}
                            >
                              <div>
                                {convertToBRACurrency(
                                  ranges?.value - (ranges?.value / 100) * ranges?.taxaDeRepasse,
                                )}
                              </div>
                            </IonCol>
                          </IonRow>
                        )
                      })}
                    </IonCol>
                  </>
                ) : (
                  <IonCol size='12'>
                    <h3>Valor:</h3>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {convertToBRACurrency(shelfItem?.valueRanges[0].value)}
                    </p>
                  </IonCol>
                )}
                <IonCol size='12'>
                  <h3>Descrição:</h3>
                  <p style={{ whiteSpace: 'pre-line' }}>{shelfItem?.description}</p>
                </IonCol>
                {/* TOPPINGS */}
                {shelfItem?.toppings?.map((topping: IToppings) => {
                  return (
                    <IonCol size='12' key={topping?.name}>
                      <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>
                            {topping?.name}{' '}
                            <small style={{ color: 'red' }}>
                              Maximo de opções a serem escolhidas {topping?.quantity}
                            </small>{' '}
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          {topping?.options?.map((option: string) => {
                            return (
                              <IonChip color='primary' key={option}>
                                {option}
                              </IonChip>
                            )
                          })}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  )
                })}
                {/* SUBITENS */}
                <IonCol size='12'>
                  <h3>Complementos:</h3>
                  <div style={{ zoom: '70%' }}>
                    <ShelfSubItemList
                      setIsSubItemsModalOpen={setIsSubItemsModalOpen}
                      subItemsGroups={subItemsGroups || []}
                      setSubItemsGroups={setSubItemsGroups}
                      setSelectedSubItem={setSelectedSubItem}
                      isEditItem={false}
                    />
                  </div>
                </IonCol>

                <IonCol size='12'>
                  <ShelfVariationsList
                    variations={shelfItem.variations}
                    selectedVariation={selectedvariation}
                  />
                </IonCol>
              </IonRow>
            </Container>
          </IonContent>
        </IonModal>
      )}
      <IonCard className='card-shelf'>
        <div style={{ padding: '1rem ' }}>
          {!shelfItem?.images[0] || shelfItem?.images[0] == 'string[]' ? (
            <div
              style={{
                backgroundImage: 'url(https://ionicframework.com/docs/img/demos/card-media.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '15rem',
                borderRadius: '2rem',
              }}
            />
          ) : (
            <>
              {shelfItem?.images && shelfItem?.images[0] && (
                <div
                  style={{
                    backgroundImage: `url('${shelfItem?.images[0]}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '15rem',
                    borderRadius: '2rem',
                    width: '100%',
                  }}
                />
              )}
            </>
          )}
        </div>
        <div style={{ margin: '0 2rem ' }}>
          <IonButton size='small' onClick={() => setIsImageOpen(true)} expand='block'>
            Ver detalhes
          </IonButton>
        </div>
        <IonCardContent
          style={{
            padding: '0 2rem',
            height: '10rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ margin: '1rem 0rem' }}>
            <strong
              style={{
                fontSize: '1.25rem',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 1,
              }}
            >
              {shelfItem?.name}
            </strong>
            <IonCardSubtitle style={{ display: 'flex', alignItems: 'center', color: 'silver' }}>
              <IonIcon icon={calendarNumberOutline} style={{ marginRight: '0.5rem' }} />
              Criado em: {formatDate(shelfItem?.createdAt)}
            </IonCardSubtitle>
          </div>
          <div>
            <p style={{ margin: '1rem 0rem' }}>
              {categoriesImages?.map((category: any, index) => {
                return (
                  <span key={index}>
                    {category.categoryId == shelfItem?.categoryId && (
                      <>
                        <strong>Categoria selecionada:</strong>
                        <br /> {category.name}
                      </>
                    )}
                  </span>
                )
              })}
            </p>
            {shelfItem?.valueRanges?.length >= 2 ? (
              <p>
                De <strong>{convertToBRACurrency(shelfItem?.valueRanges[0]?.value)}</strong> a{' '}
                <strong>
                  {convertToBRACurrency(
                    shelfItem?.valueRanges[shelfItem?.valueRanges?.length - 1].value,
                  )}
                </strong>
              </p>
            ) : (
              <p>
                <strong>{convertToBRACurrency(shelfItem?.valueRanges[0]?.value)}</strong>
              </p>
            )}
          </div>
        </IonCardContent>
        <div
          style={{ display: 'flex', padding: '0rem 1.75rem', alignItems: 'end', margin: '1rem 0' }}
        >
          <Link to={`/prateleira/editar-item/${shelfItem.id}`} style={{ textDecoration: 'none' }}>
            {/* <IonChip
              color='primary'
              onClick={() => setSelectedItem(shelfItem)}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <IonIcon style={{ fontSize: '1rem', marginRight: '1rem' }} icon={createOutline} />
              Editar
            </IonChip> */}
            <IonChip
              color='primary'
              // onClick={() => setSelectedItem(shelfItem)}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <IonIcon style={{ fontSize: '1rem', marginRight: '1rem' }} icon={createOutline} />
              Editar
            </IonChip>
          </Link>

          <IonChip
            color='danger'
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '2rem' }}
            onClick={() =>
              presentAlert({
                header: 'Deseja remover este item da prateleira?',
                buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Sim, remover',
                    role: 'confirm',
                    handler: () => {
                      handleDeleteItem(shelfItem?.id)
                    },
                  },
                ],
              })
            }
          >
            <IonIcon
              slot='start'
              style={{ fontSize: '1.5rem' }}
              color='danger'
              icon={trashOutline}
            />
            Excluir
          </IonChip>
        </div>
      </IonCard>
    </>
  )
}
