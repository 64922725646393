import { useState } from 'react'
import api from '../../../api/api'

export function useOpportunitiesLocation() {
  const [res, setRes] = useState()
  const addLocation = async (state: string): Promise<boolean> => {
    try {
      const response = await api.post<any>(`/suppliers/actingState/${state}/add`)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const removeLocation = async (state: string): Promise<boolean> => {
    try {
      const response = await api.post<any>(`/suppliers/actingState/${state}/remove`)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return {
    addLocation,
    removeLocation,
  }
}
