import { useState } from 'react'
import api from '../../../api/api'
import { IAreas, IAreasParam } from '../categories.types'

export function useCategories() {
  const [areas, setAreas] = useState([])
  const [categories, setCategories] = useState<IAreasParam>()
  const [userCategories, setUserCategories] = useState<IAreas[]>()

  /** getAreas():
   * Makes a GET request to the API endpoint /categories/areas
   * Sets the result of this response as areas using the setAreas hook
   */
  function getAreas() {
    api.get('/categories/areas').then((response) => setAreas(response.data))
  }

  /** getCategoriesByParam(id: number):
   * @param id
   * Makes a GET request to the API endpoint /categories/areas/{id} where {id} is substituted with the input argument id
   * Sets the result of this response as categories using the setCategories hook
   */
  function getCategoriesByParam(id: number) {
    api.get('/categories/areas/' + id).then((response) => setCategories(response.data))
  }

  /** getRegisteredCategories():
   * Makes a GET request to the API endpoint /categories/supplier/registeredCategories
   * Sets the result of this response, which is an array, as userCategories using the setUserCategories hook.
   * Note that the current user ID is likely stored elsewhere in the application, otherwise this function would not know which
   * registered categories to retrieve
   */
  async function getRegisteredCategories(): Promise<IAreas[]> {
    return api.get<IAreas[]>('/categories/supplier/registeredCategories')
      .then((response) => {
        setUserCategories([...response.data]);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  /** addCategoryForUser(categoryIdy: number):
   * @param categoryIdy
   * First makes a POST request to the API endpoint /categories/supplier/add/{categoryId}, where {categoryId} is replaced with the
   * specified input categoryId. This assigns said category to the currently logged-in user
   * After the POST request completes successfully, calls getRegisteredCategories() to update memory with the new list of registered
   * user categories
   */
  async function addCategoryForUser(categoryIdy: number) {
    await api.post('/categories/supplier/add/' + categoryIdy).then(() => {
      getRegisteredCategories()
    })
  }

  /** deleteCategoriesByParam(id: number):
   * @param id
   * First makes a POST request to the API endpoint /categories/supplier/remove/{id} where {id} is replaced with the specified input id.
   * This removes said category from the currently logged-in user's registered categories
   * After the POST request completes successfully, calls getRegisteredCategories() to update memory with the revised list of
   * registered user categories
   */
  async function deleteCategoriesByParam(id: number) {
    await api.post('/categories/supplier/remove/' + id).then(() => getRegisteredCategories())
  }

  return {
    areas,
    categories,
    userCategories,
    getAreas,
    getCategoriesByParam,
    addCategoryForUser,
    deleteCategoriesByParam,
    getRegisteredCategories,
  }
}
