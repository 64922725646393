import {
  IonAlert,
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from '@ionic/react'
import { IValueRanges } from '../../shelf.types'
import { addCircleOutline, alertCircle, alertCircleOutline, trashOutline } from 'ionicons/icons'
import { convertToBRACurrency } from '../../../../utils/google-auth/currencyFunctions'
import { useEffect, useState } from 'react'
import useToast from '../../../../hook/useToast'

interface IShelfPrices {
  priceMethod: string | null
  handleMethodPrice: (value: string) => void
  valueRangesData: IValueRanges[]
  lastRowMaxValue: number
  setLastRowMaxValue: (value: number) => void
  setValueRangesData: (value: IValueRanges[]) => void
  setValue: (value: number) => void
  value: number
  variationsLength?: number
}

type ValueRange = {
  initialUnit: number
  finalUnit: number
  value: number
  taxaDeRepasse: number
}

const ShelfFormPrice: React.FC<IShelfPrices> = ({
  priceMethod,
  handleMethodPrice,
  valueRangesData,
  lastRowMaxValue,
  setLastRowMaxValue,
  setValueRangesData,
  value,
  setValue,
  variationsLength,
}: IShelfPrices) => {
  // Hooks
  const [present] = useIonToast()
  const { presentToast } = useToast()
  // States
  const [initialUnit, setInitialUnit] = useState<number | null>(1)
  const [finalUnit, setFinalUnit] = useState<number | null>(null)
  const [taxaDeRepasse, setTaxaDeRepasse] = useState<number>(10)
  const [minValue, setMinValue] = useState<number>()
  const [maxValue, setMaxValue] = useState<number>()
  const [isOpenInfo, setIsOpenInfo] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const totalToast = () => {
    present({
      message:
        'O valor do repasse será o valor da NF se o orçamento for aprovado, ou seja, este é o valor que você receberá em sua conta bancária.',
      duration: 5000,
      position: 'top',
      icon: alertCircleOutline,
    })
  }

  useEffect(() => {
    if (valueRangesData.length > 0) {
      const newlastRowFinalUnit = valueRangesData[valueRangesData.length - 1].finalUnit

      newlastRowFinalUnit ? setLastRowMaxValue(newlastRowFinalUnit) : setLastRowMaxValue(0)

      if (newlastRowFinalUnit && minValue) {
        setInitialUnit(newlastRowFinalUnit + 1)
      }
    } else {
      if (minValue) {
        setInitialUnit(minValue)
      }
    }
    if (maxValue) {
      setFinalUnit(maxValue)
    }
  }, [maxValue, minValue, valueRangesData])

  function handleUnitPrice(value: string) {
    const sanitizedValue = value.replace(/[^0-9.,]/g, '')
    const formattedValue = sanitizedValue.replace(/,/g, '.')
    const parsedValue = formattedValue !== '' ? parseFloat(formattedValue) : 0
    setValue(parsedValue)
  }

  const handleAddValueRange = () => {
    if (minValue === undefined || maxValue === undefined || value === undefined) {
      presentToast('Preencha os valores para continuar', 'warning')
      clearValues()
    } else if (minValue <= 0) {
      presentToast('O valor mínimo deve ser maior que zero', 'warning')
      clearValues()
    } else if (maxValue <= minValue) {
      presentToast('O valor máximo não pode ser menor ou igual ao valor mínimo', 'warning')
      clearValues()
    } else if (value === 0) {
      presentToast('Preencha um valor diferente de zero para continuar', 'warning')
      clearValues()
    } else if (minValue <= lastRowMaxValue) {
      presentToast(`O valor mínimo não pode ser menor que ${lastRowMaxValue}`, 'warning')
      clearValues()
    } else if (maxValue <= lastRowMaxValue) {
      presentToast('O valor máximo não pode ser menor ou igual ao valor mínimo', 'warning')
      clearValues()
    } else {
      defaultRow()
    }
  }

  function clearValues() {
    setMinValue(undefined)
    setMaxValue(undefined)
    setValue(0)
  }

  const handleDeleteValueRange = () => {
    setLastRowMaxValue(0)
    setValueRangesData([])
    setMinValue(undefined)
    setMaxValue(undefined)
    setInitialUnit(0)
    setValue(0)
    setTaxaDeRepasse(10)
  }

  function defaultRow() {
    {
      const newValueRange: ValueRange = {
        initialUnit: initialUnit || 1,
        finalUnit: finalUnit ? finalUnit : 0,
        value,
        taxaDeRepasse,
      }
      setValueRangesData([...valueRangesData, newValueRange])
      setMinValue(undefined)
      setMaxValue(undefined)
      setInitialUnit(finalUnit! + 1)
      setValue(0)
      setTaxaDeRepasse(10)
    }
  }

  return (
    <>
      <IonCol size='12' sizeLg='4'>
        <div style={{ marginBottom: '1rem ' }}>
          <IonLabel className='custom mb-20'>
            Modo de cobrança <span style={{ color: 'red' }}>*</span>
          </IonLabel>
        </div>
        <IonItem className='custom '>
          <IonSelect
            interface='popover'
            name='priceMethod'
            placeholder='Selecione o modo de cobrança'
            value={priceMethod}
            onIonChange={(event) => event.target.value && handleMethodPrice(event.target?.value)}
            disabled={Boolean(variationsLength && variationsLength > 0)}
          >
            <IonSelectOption value={'Preço / Por pessoa'}>Preço / Por pessoa</IonSelectOption>
            <IonSelectOption value={'Preço / Por pacote'}>Preço / Por pacote</IonSelectOption>
            <IonSelectOption value={'Preço / Por unidade'}>Preço / Por unidade</IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonCol>

      <IonCol size='12'>
        <div style={{ marginTop: '2rem' }}>
          <h6 style={{ fontWeight: 'bold' }}>Cadastre seu item e aumente suas vendas.</h6>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'blue',
              cursor: 'pointer',
              marginBottom: '1rem',
            }}
            onClick={() => setIsOpen(true)}
          >
            {priceMethod !== '' && (
              <>
                <IonIcon
                  icon={alertCircleOutline}
                  style={{ marginRight: '0.25rem', color: 'blue' }}
                />{' '}
                <small style={{ color: 'blue' }}>
                  Entenda como personalizar suas faixas de preço
                </small>
              </>
            )}
          </div>
          {priceMethod !== 'Preço / Por pacote' && (
            <IonAlert
              isOpen={isOpen}
              header={`'Faixa de '${priceMethod?.toLowerCase()}'`}
              message={`'O valor é calculado com base ${
                priceMethod === 'Preço / Por unidade'
                  ? 'na quantidade de unidades solicitadas'
                  : 'no número de participantes do evento'
              }. Você pode personalizar as faixas de preço informando a quantidade mínima e máxima de ${
                priceMethod === 'Preço / Por unidade'
                  ? 'unidades para seus produtos'
                  : 'pessoas que pode atender'
              }. Crie diferentes opções de valores para impulsionar a oferta dos seus produtos e/ou serviços.!'`}
              buttons={['Fechar']}
              onDidDismiss={() => setIsOpen(false)}
            />
          )}
          {priceMethod === 'Preço / Por pacote' && (
            <small style={{ color: '#0000007f' }}>
              O valor é definido conforme o pacote de serviços contratados
            </small>
          )}
        </div>
      </IonCol>

      {priceMethod !== 'Preço / Por pacote' && (
        <>
          <IonCol size='12' sizeLg='2 '>
            <h6 style={{ fontSize: '0.90em' }}>Quantidade mínima</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                type='number'
                clearInput
                className='custom'
                value={minValue}
                placeholder={`Min: ${
                  minValue === undefined && !valueRangesData[0] ? 1 : initialUnit!
                } `}
                min={minValue === undefined && !valueRangesData[0] ? 1 : finalUnit! + 1}
                onIonInput={(event) => setMinValue(Number(event.target.value))}
              />
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeLg='2 '>
            <h6 style={{ fontSize: '0.90em' }}>Quantidade máxima</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                type='number'
                clearInput
                className='custom'
                value={maxValue}
                placeholder={`Min: ${
                  minValue === undefined && initialUnit === 1
                    ? 2
                    : minValue! > 1
                    ? minValue! + 1
                    : initialUnit! + 1
                }`}
                min={
                  minValue === undefined && initialUnit === 1
                    ? 2
                    : minValue! > 1
                    ? minValue! + 1
                    : initialUnit! + 2
                }
                onIonInput={(event) =>
                  setMaxValue(event.target.value === '' ? undefined : Number(event.target.value))
                }
              />
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeLg='2 '>
            <h6 style={{ fontSize: '0.90em' }}>Valor</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                type='text'
                clearInput
                className='custom'
                value={value}
                min={1}
                onIonInput={(event) => {
                  const inputValue = event?.target?.value?.toString()
                  if (inputValue !== undefined) {
                    handleUnitPrice(inputValue)
                  }
                }}
                onKeyDown={(event) => {
                  const allowedKeys = [8, 9, 13, 27, 188, 190] // Teclas permitidas: backspace, tab, enter, escape, vírgula, ponto
                  const key = event.key
                  const isDigit = /\d/.test(key) // Verifica se é um dígito numérico
                  const isAllowedKey = allowedKeys.includes(event.keyCode)
                  if (!isDigit && !isAllowedKey) {
                    event.preventDefault()
                  }
                }}
              />
            </IonItem>
          </IonCol>
          <IonCol
            size='12'
            sizeLg='6'
            style={{ display: 'flex', alignItems: 'center', paddingTop: '1.5rem' }}
          >
            <IonButton
              onClick={handleAddValueRange}
              color='success'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <IonIcon src={addCircleOutline} style={{ marginRight: '0.5rem' }} />
              <div style={{ color: 'white', marginTop: '0rem' }}>
                {valueRangesData[0] ? 'Adicionar outra faixa de preço' : 'Adicionar faixa de preço'}
              </div>
            </IonButton>
          </IonCol>

          <IonCol size='12' style={{ padding: '0' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '1rem' }}>Valores:</h3>
            <IonRow className='header-row'>
              <IonCol size='3' className='header-col'>
                Quantidade min
              </IonCol>
              <IonCol size='3' className='header-col'>
                Quantidade max
              </IonCol>
              <IonCol size='3' className='header-col'>
                Valor
              </IonCol>
              <IonCol size='3' className='header-col'>
                Valor do repasse
              </IonCol>
            </IonRow>
          </IonCol>

          {valueRangesData.length ? (
            valueRangesData.map((ranges: IValueRanges, index: number) => (
              <IonCol size='12' key={index} style={{ padding: '0' }}>
                <IonRow key={index} className='data-row'>
                  <IonCol size='3' className='data-col'>
                    <div>{ranges?.initialUnit}</div>
                  </IonCol>
                  <IonCol size='3' className='data-col'>
                    <div>{ranges?.finalUnit}</div>
                  </IonCol>
                  <IonCol size='3' className='data-col'>
                    <div className='value-text'>{convertToBRACurrency(ranges?.value)}</div>
                  </IonCol>
                  <IonCol size='3' className='data-col'>
                    <div className='repasse-text'>
                      {convertToBRACurrency(
                        ranges?.value - (ranges?.value / 100) * ranges?.taxaDeRepasse,
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>
            ))
          ) : (
            <div
              style={{
                color: 'red',
                textAlign: 'center',
                width: '100%',
                padding: '3rem',
                backgroundColor: '#fff',
                border: '1px solid silver',
              }}
            >
              <IonIcon
                icon={alertCircleOutline}
                style={{ fontSize: '1.5rem', marginBottom: '-0.25rem', marginRight: '0.5rem' }}
              />
              Atenção! Para prosseguir, é necessário adicionar pelo menos uma faixa de preço.
            </div>
          )}

          <IonCol size='6' style={{ marginTop: '2rem' }}>
            {valueRangesData.length > 0 ? (
              <IonButton fill='clear' onClick={() => handleDeleteValueRange()} color='danger'>
                <IonIcon src={trashOutline} />
                <div style={{ color: 'red', marginTop: '0rem', marginLeft: '0.25rem' }}>
                  Refazer faixas de preço
                </div>
              </IonButton>
            ) : null}
          </IonCol>
        </>
      )}
      {priceMethod === 'Preço / Por pacote' && (
        <IonCol sizeLg='4'>
          <h6>Valor de venda por pacote</h6>
          <IonItem className='custom mb-20'>
            <IonInput
              type='text'
              name='value'
              placeholder='Valor de venda'
              clearInput
              value={value}
              className='custom'
              required
              min={1}
              onIonInput={(event) => {
                const inputValue = event?.target?.value?.toString()
                if (inputValue !== undefined) {
                  handleUnitPrice(inputValue)
                }
              }}
              onKeyDown={(event) => {
                const allowedKeys = [8, 9, 13, 27, 188, 190] // Teclas permitidas: backspace, tab, enter, escape, vírgula, ponto
                const key = event.key
                const isDigit = /\d/.test(key) // Verifica se é um dígito numérico
                const isAllowedKey = allowedKeys.includes(event.keyCode)

                if (!isDigit && !isAllowedKey) {
                  event.preventDefault()
                }
              }}
            />
          </IonItem>
          <div
            style={{ display: 'flex', alignItems: 'start', cursor: 'pointer' }}
            onClick={() => setIsOpenInfo(true)}
          >
            <IonIcon
              icon={alertCircle}
              color='warning'
              style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}
            />
            <div>
              Valor do repasse por período: {convertToBRACurrency(value - value / taxaDeRepasse)}.
            </div>
          </div>
        </IonCol>
      )}
    </>
  )
}

export default ShelfFormPrice
