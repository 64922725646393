import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ISubItems, ISubItemsGroup } from '../../shelf.types'
import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
} from '@ionic/react'
import './shelfForm.css'
import { addCircleOutline, trashOutline } from 'ionicons/icons'

interface IShelfSubItem {
  index?: number
  subitem: ISubItemsGroup | null
  subItemsGroup: ISubItemsGroup[]
  setSubItemsGroups?: (value: ISubItemsGroup[]) => void
}

interface IParams {
  itemid: string
}

const ShelfSubItemForm: React.FC<IShelfSubItem> = ({
  index,
  subitem,
  subItemsGroup,
  setSubItemsGroups,
}: IShelfSubItem) => {
  // Hooks
  const params: IParams = useParams()
  // States
  const [groupName, setGroupName] = useState<string>('')
  const [quantity, setQuantity] = useState<number>(1)
  const [options, setOptions] = useState<ISubItems[]>([{ name: '' }])

  useEffect(() => {
    if (subitem) {
      setGroupName(subitem?.name)
      setQuantity(subitem?.quantity)
      setOptions(subitem?.subItems)
    }
  }, [subitem])

  function handleChangeName(value: string) {
    const newSubItemsGroup = Object.assign(subItemsGroup)
    if (typeof index === 'number' && index > -1) {
      newSubItemsGroup[index].name = value
      setGroupName(value)
      handleUpdateSubitems(newSubItemsGroup)
    }
  }

  function handleChangeQuantity(value: number) {
    const newSubItemsGroup = Object.assign(subItemsGroup)
    if (typeof index === 'number' && index > -1) {
      newSubItemsGroup[index].quantity = value
      setQuantity(value)
      handleUpdateSubitems(newSubItemsGroup)
    }
  }

  function handleChangeOptions(value: string, optionIndex: number) {
    const newSubItemsGroup = Object.assign(subItemsGroup)
    const newOptions = Object.assign(options)
    newOptions[optionIndex].name = value
    if (typeof index === 'number' && index > -1) {
      newSubItemsGroup[index].subItems = newOptions
      setOptions(newOptions)
      handleUpdateSubitems(newSubItemsGroup)
    } else {
      setOptions(newOptions)
    }
  }

  function handleDeleteOption(value: string) {
    let newOptions = Object.assign(options)
    const newSubItemsGroup = Object.assign(subItemsGroup)
    newOptions = newOptions.filter((option: ISubItems) => option.name !== value)
    if (typeof index === 'number' && index > -1) {
      newSubItemsGroup[index].subItems = newOptions
      setOptions(newOptions)
      handleUpdateSubitems(newSubItemsGroup)
    }
  }

  function handleDeleteGroup(index: number) {
    const newSubItemsGroup = [...subItemsGroup]
    newSubItemsGroup.splice(index, 1)
    handleUpdateSubitems(newSubItemsGroup)
  }

  function handleUpdateSubitems(newSubItemsGroup: ISubItemsGroup[]) {
    if (setSubItemsGroups) setSubItemsGroups(newSubItemsGroup)
  }

  return (
    <div className={'subitem-group'}>
      <form>
        <IonRow>
          <IonCol size='12' sizeLg='7'>
            <h6>Nome do grupo</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                type='text'
                className='custom'
                value={groupName}
                onIonInput={(e) => handleChangeName(e.target.value?.toString() || '')}
                defaultValue={subitem?.name}
                placeholder='Insira o nome da seção'
                required={Boolean(params.itemid)}
              />
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeLg='5'>
            <h6>Quantidade máxima</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                type='number'
                className='custom'
                min={1}
                value={quantity}
                onIonInput={(e: CustomEvent<InputChangeEventDetail>) => {
                  const inputValue = (e.detail.value as string) || ''
                  handleChangeQuantity(parseInt(inputValue))
                }}
                defaultValue={subitem?.quantity}
                placeholder='Insira a quantidade'
                required={Boolean(params.itemid)}
              />
            </IonItem>
          </IonCol>

          <IonCol size='12'>
            <IonRow>
              {options?.map((option: ISubItems, index: number) => {
                return (
                  <IonCol key={index} size='12' sizeLg={'4'}>
                    <h6>{`Opção ${index + 1}`}</h6>
                    <IonItem className='custom mb-20'>
                      <IonInput
                        className='custom'
                        required={Boolean(params.itemid)}
                        defaultValue={option.name}
                        value={option.name}
                        placeholder='Insira o nome da opção'
                        onIonInput={(e) => {
                          handleChangeOptions(e.target.value?.toString() || '', index)
                        }}
                      />
                      <p
                        onClick={() => handleDeleteOption(option.name)}
                        style={{ color: 'var(--color-red)', cursor: 'pointer' }}
                      >
                        Apagar
                      </p>
                    </IonItem>
                  </IonCol>
                )
              })}
            </IonRow>
          </IonCol>
        </IonRow>
      </form>
      <div className='group-footer'>
        <IonButton onClick={() => setOptions([...options, { name: '' }])} fill='clear'>
          <IonIcon icon={addCircleOutline} style={{ marginRight: '0.5rem' }} />
          Adicionar opção
        </IonButton>

        <IonButton
          onClick={() => (typeof index === 'number' ? handleDeleteGroup(index) : null)}
          fill='clear'
          color='danger'
        >
          <IonIcon icon={trashOutline} style={{ marginRight: '0.5rem' }} />
          Excluir seção
        </IonButton>
      </div>
    </div>
  )
}

export default ShelfSubItemForm
