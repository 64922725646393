import { IonButton, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { IVariations } from '../../shelf.types'
import { alertCircleOutline, pencilOutline, trashOutline } from 'ionicons/icons'
import { convertToBRACurrency } from '../../../../utils/google-auth/currencyFunctions'
import { ISelectedVariation } from './shelfForm'
import { useLocation } from 'react-router'
import { useEffect } from 'react'

interface IVariationsList {
  variations: IVariations[]
  setVariations?: (value: IVariations[]) => void
  selectedVariation?: ISelectedVariation | null
  openEditForm?: (index: number) => void
}

const ShelfVariationsList: React.FC<IVariationsList> = ({
  variations,
  setVariations,
  openEditForm,
}: IVariationsList) => {
  // hooks
  const location = useLocation()

  useEffect(() => {
    console.log(location.pathname)
  }, [])

  function removeVariation(index: number) {
    if (setVariations) {
      const newVariations = [...variations]
      newVariations.splice(index, 1)
      setVariations(newVariations)
    }
  }

  return (
    <IonList inset={true} style={{ borderRadius: '8px' }}>
      {variations.length < 1 && location.pathname.includes('item') ? (
        <p
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            color: 'var(--color-red)',
          }}
        >
          <span
            style={{
              marginRight: '0.5rem',
              marginTop: '0.5rem',
              fontSize: '1.5rem',
              color: 'var(--color-red)',
            }}
          >
            <IonIcon icon={alertCircleOutline} />
          </span>
          Atenção! insira ao menos uma variação para poder salvar este item
        </p>
      ) : (
        variations.map((variation, index) => {
          return (
            <IonItem key={index} lines='full'>
              <IonLabel style={{ fontWeight: '700' }}>
                {variation.name}{' '}
                <span
                  style={{
                    color: 'var(--color-primary-light)',
                    fontSize: '0.8rem',
                    fontWeight: 400,
                  }}
                >{`a partir de ${convertToBRACurrency(variation?.valueRanges[0]?.value)}`}</span>
              </IonLabel>
              {openEditForm && (
                <IonLabel style={{ justifyContent: 'end', display: 'flex' }}>
                  <IonButton fill='clear' onClick={() => openEditForm(index)}>
                    <IonIcon icon={pencilOutline} style={{ marginRight: '0.5rem' }} />
                    Editar
                  </IonButton>
                  <IonButton fill='clear' color='danger' onClick={() => removeVariation(index)}>
                    <IonIcon icon={trashOutline} />
                    Excluir
                  </IonButton>
                </IonLabel>
              )}
            </IonItem>
          )
        })
      )}
    </IonList>
  )
}

export default ShelfVariationsList
