import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  useIonAlert,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../features/auth/hooks/useAuth'
import { cpf as cpfVal, cnpj as cnpjVal } from 'cpf-cnpj-validator'
import { useCompleteRegistration } from '../../../features/auth/hooks/useCompleteRegistration'
import { formatCNPJ } from '../../../utils/formatCNPJ'
import { formatCpf } from '../../../utils/formatCPF'
import { Container } from '../../../components/Container/container'
import './style.css'

interface IRegisterCompany {
  setCurrentStep?: any
}

const RegisterCompany: React.FC<IRegisterCompany> = ({ setCurrentStep }: IRegisterCompany) => {
  // Hooks
  const { registerCompany } = useAuth()
  const { company } = useCompleteRegistration()
  const [presentAlert] = useIonAlert()
  // States
  const [cnpj, setCnpj] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [knownName, setKnownName] = useState('')
  const [managerName, setManagerName] = useState('')
  const [managerCpf, setManagerCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [indicatedBy] = useState('')

  useEffect(() => {
    if (company?.props) {
      setCnpj(formatCNPJ(company?.props?.cnpj))
      setCompanyName(company?.props?.name)
      setPhone(company?.props?.phone)
      setKnownName(company?.props?.knownName)
      setManagerName(company?.props?.managerName)
      setManagerCpf(formatCpf(company?.props?.managerCpf))
    }
  }, [company])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setCurrentStep(3)

    e.preventDefault()

    const formatedCPF = managerCpf.replace(/\D/g, '')
    const formatedCNPJ = cnpj.replace(/\D/g, '')

    // Validate CPF and CNPJ
    if (!cpfVal.isValid(formatedCPF)) {
      presentAlert({
        header: 'CPF Inválido',
        subHeader: '',
        message: 'Por favor informe um cpf válido',
        buttons: ['OK'],
        cssClass: 'alert-card',
      })
      setManagerCpf('')
    } else if (!cnpjVal.isValid(formatedCNPJ)) {
      presentAlert({
        header: 'CNPJ Inválido',
        subHeader: '',
        message: 'Por favor informe um cnpj válido',
        buttons: ['OK'],
        cssClass: 'alert-card',
      })
      setCnpj('')
    } else {
      registerCompany({
        cnpj: formatedCNPJ,
        companyName: companyName,
        knownName: knownName,
        indicatedBy: indicatedBy,
        managerName: managerName,
        managerCpf: formatedCPF,
        phone: phone,
      })
    }
    window.location.reload()
  }

  const handleCpfChange = (event: CustomEvent<KeyboardEvent>) => {
    const value = (event.target as HTMLInputElement).value
    const formattedCpf = value
      .replace(/\D/g, '') // remove all non-numeric characters
      .replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (match, p1, p2, p3, p4) => {
        let result = p1
        if (p2) result += `.${p2}`
        if (p3) result += `.${p3}`
        if (p4) result += `-${p4}`
        return result
      })
    setManagerCpf(formattedCpf)
  }

  const handleCnpjChange = (event: CustomEvent<KeyboardEvent>) => {
    const value = (event.target as HTMLInputElement).value
    const formattedCnpj = value
      .replace(/\D/g, '') // remove all non-numeric characters
      .replace(/(\d{2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/, (match, p1, p2, p3, p4, p5) => {
        let result = p1
        if (p2) result += `.${p2}`
        if (p3) result += `.${p3}`
        if (p4) result += `/${p4}`
        if (p5) result += `-${p5}`
        return result
      })
    setCnpj(formattedCnpj)
  }

  const handlePhoneChange = (event: CustomEvent<KeyboardEvent>) => {
    const value = (event.target as HTMLInputElement).value
    setPhone(value)
  }

  return (
    <Container>
      <IonGrid>
        {/* Form login :) */}
        <form onSubmit={(e) => handleSubmit(e)}>
          <IonRow style={{ marginTop: '3rem' }}>
            <IonCol sizeSm='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                Nome completo do responsável:
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setManagerName((e.target as HTMLInputElement).value)}
                  value={managerName}
                  type='text'
                  required
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='6' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                CNPJ da sua empresa<span style={{ color: 'red' }}>*</span>
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  type='tel'
                  inputmode='numeric'
                  // pattern="\d*"
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => handleCnpjChange(e)}
                  value={cnpj}
                  maxlength={18}
                  required
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='6' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                CPF do responsável:<span style={{ color: 'red' }}>*</span>
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  onIonInput={(e: CustomEvent) => handleCpfChange(e)}
                  required
                  type='tel'
                  clearInput={true}
                  inputmode='numeric'
                  placeholder='CPF'
                  value={managerCpf}
                  maxlength={14}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                Razão social:<span style={{ color: 'red' }}>*</span>
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setCompanyName((e.target as HTMLInputElement).value)}
                  value={companyName}
                  type='text'
                  required
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                Nome fantasia:<span style={{ color: 'red' }}>*</span>
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  placeholder=''
                  clearInput={true}
                  onIonInput={(e: CustomEvent) => setKnownName((e.target as HTMLInputElement).value)}
                  value={knownName}
                  type='text'
                  required
                ></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size='12' sizeLg='6' style={{ paddingRigth: '1rem' }}>
              <IonLabel className='zoomOut' position='stacked' style={{ marginLeft: '1rem' }}>
                Telefone do responsável:<span style={{ color: 'red' }}>*</span>
              </IonLabel>
              <IonItem style={{ marginTop: '0.5rem' }} className=' mb-20'>
                <IonInput
                  onIonInput={(e: CustomEvent) => handlePhoneChange(e)}
                  required
                  type='tel'
                  clearInput={true}
                  inputmode='numeric'
                  placeholder='Telefone'
                  value={phone}
                  maxlength={14}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol
              size='12'
              sizeLg='6'
              style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}
            >
              <IonButton type='submit'>Continuar</IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </Container>
  )
}

export default RegisterCompany
