import { useState } from 'react'

interface IViaCep {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: number
  gia: number
  ddd: number
  siafi: number
}

function useViaCep() {
  const [cepData, setCepData] = useState<IViaCep>()
  const [error, setError] = useState(false)
  // This function takes a string argument called cep which represents a CEP (Brazilian Zip Code)
  function validateCep(cep: string) {
    // Remove any non-numeric characters from the cep string
    cep = cep.replace(/[^0-9]/gi, '')
    // If the resulting cep string has a length of 8, it is considered valid and is returned
    if (cep.length === 8) {
      return cep
    }
  }

  // This function fetches data of a Brazilian zip code through the ViaCEP API, using axios library.
  function getCepData(cep: string) {
    fetch(`https://viacep.com.br/ws/${validateCep(cep)}/json/`,{ mode: 'cors' })
  
      // If the response is successful, we set the fetched data into a state variable and reset the error flag.
      .then(response => response.json())
      .then(data => setCepData(data))
      // If an error happens during the request, we show/handle the error and log it into the console.
      .catch((err: any) => {
        setError(true)
      })

    // const script = document.createElement('script');
    // script.src = `https://viacep.com.br/ws/${cep}/json/?callback=handleResponse`;
    // document.body.appendChild(script);
  }

  return { cepData, error, getCepData }
}



export { useViaCep }
