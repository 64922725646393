import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import { useEffect } from 'react';
import { IAreas } from '../categories.types';
import { useCategories } from '../hooks/useCategories';
import AlimentaçãoEBebidas from '../../../assets/img/AlimentaçãoEBebidas.png';
import MusicaEEntretenimento from '../../../assets/img/MusicaEEntretenimento.png';
import BrindesPersonalizados from '../../../assets/img/BRINDES.png';
import Audiovisualeinfraestrutura from '../../../assets/img/audiovisual.png';
import StaffTecnicaEOperacional from '../../../assets/img/StaffTecnicaEOperacional.png';
import FotografiaEFilmagem from '../../../assets/img/FotografiaEFilmagem.png';
import LogisticaEMovimentos from '../../../assets/img/LogisticaEMovimentos.png';
import Decoracao from '../../../assets/img/Decoracao.png';
import ProduçãoEConteudo from '../../../assets/img/ProduçãoEConteudo.png';
import Espacos from '../../../assets/img/Espacos.png';
import './style.css'

interface ICategoriesProps {
  setCategoryName: (value: string) => void;
  setModalIsOpen: (value: boolean) => void;
  setCategoryId: (value: number) => void;
  categoryId: number
  setCurrentStep?: any

}




export const AreasPanel: React.FC<ICategoriesProps> = ({ setCategoryName, setModalIsOpen, setCategoryId, categoryId, setCurrentStep }: ICategoriesProps) => {
  const { areas, getAreas } = useCategories();


  function handleSort(categorie: IAreas) {
    // Set the categoryName to be used in rendering the Modal;
    setCategoryName(categorie.name);
    // Set modalIsOpen to true, to render the Modal component.
    setModalIsOpen(true);
    // Set the current categoryId value to categorie.id.
    setCategoryId(categorie.id);
    setCurrentStep(1)
  }


  useEffect(() => {
    getAreas();
  }, [])

  const imagesAreas = [
    {
      name: 'Alimentação e bebidas',
      image: AlimentaçãoEBebidas
    },
    {
      name: 'Música e entretenimento',
      image: MusicaEEntretenimento
    },
    {
      name: 'Brindes personalizados',
      image: BrindesPersonalizados
    },
    {
      name: 'Audiovisual e infraestrutura',
      image: Audiovisualeinfraestrutura
    },
    {
      name: 'Staff técnica e operacional',
      image: StaffTecnicaEOperacional
    },
    {
      name: 'Fotografia e filmagem',
      image: FotografiaEFilmagem
    },
    {
      name: 'Logística e movimentos',
      image: LogisticaEMovimentos
    },
    {
      name: 'Decoração e comunicação',
      image: Decoracao
    },
    {
      name: 'Produção e conteúdo',
      image: ProduçãoEConteudo
    },
    {
      name: 'Espaços para eventos',
      image: Espacos
    },
  ]

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }} className='containerAreas'>
        {areas.map((categories: IAreas, index) => {
          const foundImage = imagesAreas.find(image => image.name === categories.name);
          const imageUrl = foundImage ? foundImage.image : '';
          return (
            <IonCard onClick={() => handleSort(categories)} key={index} className={categoryId === categories.id ? 'zoomOutAreas selected' : 'zoomOutAreas'}>
              <img alt="Category image" src={`${imageUrl}`} style={{ width: '100%' }} />
              <IonCardHeader style={{ padding: '1rem 0.5rem' }}>
                <IonCardTitle className={categoryId === categories.id ? 'areaName selected' : 'areaName'}>{categories.name}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          );
        })}
      </div>
    </div>












  )

}