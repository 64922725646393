import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react'
import logo from '../../assets/img/logo-celebrar_Prancheta.png'
import { Container } from '../../components/Container'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { IShelfItem } from '../../features/shelf/shelf.types'
import { useShelf } from '../../features/shelf/hooks/useShelf'
import { ShelfForm } from '../../features/shelf/components/shelfForm/shelfForm'

export interface IParams {
  itemid: string
}

const CreateShelf: React.FC = () => {
  // Hooks
  const { getShelfItems } = useShelf()
  const params: IParams = useParams()
  // States
  const [selectedItem, setSelectedItem] = useState<IShelfItem | null>(null)

  useEffect(() => {
    getItemToEdit()
  }, [params])

  async function getItemToEdit() {
    if (params) {
      const shelfItems: IShelfItem[] | boolean = await getShelfItems()
      let shelfItemSelected
      if (typeof shelfItems === 'object') {
        shelfItemSelected = shelfItems.filter((item) => item.id === params.itemid)[0]
        setSelectedItem(shelfItemSelected)
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <img
              src={logo}
              alt='Logo Celebrar'
              width={160}
              height={24}
              className='ms-2 mb-1 logoCelebrar'
              style={{ marginLeft: '1rem ' }}
            />
            <IonButtons slot='start' />
            <IonMenuButton autoHide={true} className='menuBtn' />
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Container>
          <IonRow class='ion-justify-content-center'>
            <ShelfForm shelfItem={selectedItem} params={params} />
          </IonRow>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default CreateShelf
