import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import firebaseConfig from './firebaseConfig';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const logMetricsToFirebase = async (metricName: string, value: number) => {
  // try {
  //   const metricsRef = collection(db, 'metrics');
  //   await addDoc(metricsRef, {
  //     metricName,
  //     value,
  //     timestamp: serverTimestamp(),
  //   });
  //   console.log('Métrica salva com sucesso no Firebase!');
  // } catch (error) {
  //   console.error('Erro ao salvar métrica no Firebase:', error);
  // }
};

export default logMetricsToFirebase;
