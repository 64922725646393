import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Container } from '../../../components/Container'
import { useCompleteRegistration } from '../../../features/auth/hooks/useCompleteRegistration'
import RegisterAddress from '../../../features/auth/components/RegisterAddress'
import UserCategories from '../../categories/userCategories'
import RegisterCompany from '../../../features/auth/components/RegisterCompany'
import { useOpportunitiesLocation } from '../../../features/opportunities/hooks/useOpportunitiesLocation'
import { cameraOutline, createOutline } from 'ionicons/icons'
import useToast from '../../../hook/useToast'
import logo from '../../../assets/img/logo-celebrar_Prancheta.png'
import { useCategories } from '../../../features/categories/hooks/useCategories'
import { useImageUpload } from '../../../features/imageUpload/hooks/useImageUpload'
import api from '../../../services/api'

const FinishRegistration: React.FC = () => {
  const { userCategories, getRegisteredCategories } = useCategories()

  const { company, address } = useCompleteRegistration();
  const { addLocation } = useOpportunitiesLocation();
  const history = useHistory();
  const { presentToast } = useToast();
  const { uploadPicture, setUploadedUrlImagesReturned, uploadedUrlImagesReturned } =
    useImageUpload();

  const [location, setLocation] = useState('');
  const [companyName, setName] = useState<string | undefined>('');
  const [companyDescription, setDescription] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  let uploadedImages: any;


  useEffect(() => {
    if (selectedImage) {
      handleAddOfferItem()
    }
  }, [selectedImage])

  useEffect(() => {
    if (!userCategories) {
      getRegisteredCategories()
    }
    if (userCategories) {
      if (userCategories[0]?.id) {
        setCurrentStep(2)
        if (company?.props?.cnpj) {
          setCurrentStep(3)
          if (address?.cep) {
            setCurrentStep(4)
          }
        }
      }
    } else {
      setCurrentStep(0)
    }
  }, [userCategories, company?.props?.cnpj, address, currentStep])

  useEffect(() => {
    setTimeout(() => {
      scrollToStep(currentStep)
    }, 100)
  }, [currentStep])

  const scrollToStep = (step: number) => {
    const element = document.getElementById(`step${step}`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const states: any = [
    { uf: 'AC', name: 'Acre' },
    { uf: 'AL', name: 'Alagoas' },
    { uf: 'AP', name: 'Amapá' },
    { uf: 'AM', name: 'Amazonas' },
    { uf: 'BA', name: 'Bahia' },
    { uf: 'CE', name: 'Ceará' },
    { uf: 'DF', name: 'Distrito Federal' },
    { uf: 'ES', name: 'Espírito Santo' },
    { uf: 'GO', name: 'Goiás' },
    { uf: 'MA', name: 'Maranhão' },
    { uf: 'MT', name: 'Mato Grosso' },
    { uf: 'MS', name: 'Mato Grosso do Sul' },
    { uf: 'MG', name: 'Minas Gerais' },
    { uf: 'PA', name: 'Pará' },
    { uf: 'PB', name: 'Paraíba' },
    { uf: 'PR', name: 'Paraná' },
    { uf: 'PE', name: 'Pernambuco' },
    { uf: 'PI', name: 'Piauí' },
    { uf: 'RJ', name: 'Rio de Janeiro' },
    { uf: 'RN', name: 'Rio Grande do Norte' },
    { uf: 'RS', name: 'Rio Grande do Sul' },
    { uf: 'RO', name: 'Rondônia' },
    { uf: 'RR', name: 'Roraima' },
    { uf: 'SC', name: 'Santa Catarina' },
    { uf: 'SP', name: 'São Paulo' },
    { uf: 'SE', name: 'Sergipe' },
    { uf: 'TO', name: 'Tocantins' },
  ]

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      const selectedImageArray = Array.from(files)

      const areAllImages = selectedImageArray.every((file) => file.type.startsWith('image/'))

      if (areAllImages) {
        // Replace the existing selected images array with the new selected images
        setSelectedImage(selectedImageArray)

        // Create an array of preview URLs from all selected images
        const previewImagesArray = selectedImageArray.map((image) => URL.createObjectURL(image))

        // Replace the existing preview images array with the new preview images
        setPreviewImages(previewImagesArray)
      } else {
        presentToast('Somente imagens são permitidas.', 'danger')
      }
    }
  }

  async function handleAddOfferItem() {
    if (selectedImage.length > 0) {
      uploadedImages = await uploadPicture(selectedImage, 'image')
    } else {
      setUploadedUrlImagesReturned(null)
    }
  }
  const handleImageDelete = (index: number) => {
    // Create a copy of selectedImage array to avoid mutation
    const newSelectedImage = [...selectedImage]
    // Remove the image at the specified index using splice method
    newSelectedImage.splice(index, 1)
    // Update state with the modified newSelectedImage array
    setSelectedImage(newSelectedImage)

    // Create a copy of previewImages array to avoid mutation
    const newPreviewImages = [...previewImages]
    // Remove the image at the specified index using splice method
    newPreviewImages.splice(index, 1)
    // Update state with the modified newPreviewImages array
    setPreviewImages(newPreviewImages)

    // Revoke (release) the memory used by the objectURL generated for the removed image to improve performance
    URL.revokeObjectURL(previewImages[index])
  }

  async function handleAddLocation() {
    if (location !== '') {
      const res = await addLocation(location)
      if (res === true) {
        presentToast('Estado adicionado com sucesso!', 'success')
        history.go(0)
      } else {
        presentToast('Ops, ocorreu um erro, tente novamente mais tarde', 'danger')
        history.go(0)
      }
    }
  }

  function finishUpdateInfos(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const companyInformations = {
      name: companyName,
      bio: companyDescription,
    }
    if (uploadedUrlImagesReturned) {
      if (uploadedUrlImagesReturned !== undefined) {
        api
          .post('/suppliers/profile', companyInformations)
          .then((response) => {
            if (response.status === 200) {
              history.go(0)
            } else {
              console.log('error', response)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      api
        .post('/suppliers/profile', companyInformations)
        .then((response) => {
          if (response.status === 200) {
            history.go(0)
          } else {
            console.log('error', response)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    handleAddLocation()
    history.push('/')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <img
              src={logo}
              alt='Logo Celebrar'
              width={160}
              height={24}
              className='ms-2 mb-1 logoCelebrar'
            />
            <IonButtons slot='start' />
            <IonMenuButton autoHide={true} className='menuBtn' />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Container>
          <IonRow>
            <IonCol size='12'>
              <Container>
                <IonRow style={{ margin: '3rem 0' }}>
                  <IonTitle style={{ fontSize: '2rem' }}>
                    Selecione a sua área de atuação
                  </IonTitle>
                </IonRow>
                <UserCategories setCurrentStep={setCurrentStep} />
              </Container>
            </IonCol>
          </IonRow>
          <IonRow>
            {currentStep >= 2 && (
              <IonCol
                size='12'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2rem 0',
                  flexDirection: 'column',
                }}
                id='step2'
              >
                {!company?.picture && !company?.supplierBio && !company?.supplierName ? (
                  <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 3 de 5</span>
                ) : (
                  <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 3 de 4</span>
                )}
                <h2>Dados da empresa</h2>
                <IonCard style={{ maxWidth: '40rem', paddingBottom: '3rem' }}>
                  <RegisterCompany setCurrentStep={setCurrentStep} />
                </IonCard>
              </IonCol>
            )}
            {currentStep >= 3 && (
              <IonCol
                size='12'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '2rem 0',
                  flexDirection: 'column',
                }}
                id='step3'
              >
                {!company?.picture && !company?.supplierBio && !company?.supplierName ? (
                  <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 4 de 5</span>
                ) : (
                  <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 4 de 4</span>
                )}
                <h2>Endereço da empresa</h2>
                <IonCard style={{ maxWidth: '40rem', padding: '3rem 0' }}>
                  <RegisterAddress setCurrentStep={setCurrentStep} />
                </IonCard>
              </IonCol>
            )}
            {currentStep >= 4 &&
              !company?.picture &&
              !company?.supplierBio &&
              !company?.supplierName && (
                <IonCol
                  size='12'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '2rem 0',
                    flexDirection: 'column',
                  }}
                  id='step4'
                >
                  <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 5 de 5</span>
                  <h2>
                    Antes explorar as oportunidades, <br /> como deseja ser identificado?
                  </h2>
                  <IonCard style={{ maxWidth: '40rem', paddingBottom: '3rem' }}>
                    <Container>
                      <div style={{ overflow: 'auto', width: '100%' }}>
                        <form onSubmit={finishUpdateInfos} name='item' id='item'>
                          <IonRow>
                            <IonCol size='12'>
                              <div
                                style={{
                                  backgroundColor: '#ffffff',
                                  marginBottom: '1rem',
                                  borderRadius: '0.25rem',
                                }}
                              >
                                <label
                                  className='file-input-container'
                                  style={{ textAlign: 'center' }}
                                >
                                  <input
                                    type='file'
                                    onChange={handleImageUpload}
                                    style={{ opacity: 0 }}
                                  />
                                  {!previewImages.length && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '1rem',
                                      }}
                                    >
                                      <IonAvatar>
                                        <img
                                          alt="Silhouette of a person's head"
                                          src='https://ionicframework.com/docs/img/demos/avatar.svg'
                                        />
                                      </IonAvatar>
                                    </div>
                                  )}
                                  <div
                                    className='button-picture mb-20'
                                    style={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    Escolha uma foto{' '}
                                    <IonIcon
                                      color='primary'
                                      style={{ marginLeft: '0.5rem' }}
                                      icon={cameraOutline}
                                    />
                                  </div>
                                </label>
                                <IonGrid>
                                  <div>
                                    {previewImages?.map((previewImage, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {index === 0 ? (
                                            <div>
                                              <div
                                                style={{
                                                  backgroundImage: `url("${previewImage}")`,
                                                  backgroundPosition: 'center',
                                                  backgroundSize: 'cover',
                                                  height: '15rem',
                                                  width: '15rem',
                                                  borderRadius: '50%',
                                                }}
                                              />
                                              <IonIcon
                                                icon={createOutline}
                                                size='medium'
                                                onClick={() => handleImageDelete(index)}
                                                color='primary'
                                              ></IonIcon>
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </IonGrid>
                              </div>
                            </IonCol>
                            <IonCol size='12'>
                              <IonItem className=''>
                                <IonLabel position='floating'>Nome da empresa</IonLabel>
                                <IonInput
                                  type='text'
                                  name='name'
                                  placeholder=''
                                  clearInput
                                  className=''
                                  required
                                  onIonInput={(event) =>
                                    event.target.value && setName(event.target?.value.toString())
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size='12'>
                              <IonItem className='' style={{ position: 'relative' }}>
                                <IonLabel position='floating'>
                                  Fale um pouco sobre sua empresa
                                </IonLabel>
                                <IonTextarea
                                  name='description'
                                  clearOnEdit
                                  autoGrow
                                  rows={1}
                                  required
                                  onIonInput={(event) =>
                                    event.target.value &&
                                    setDescription(event.target?.value.toString())
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size='12'>
                              <IonItem className=' mb-20'>
                                <IonLabel position='floating'>
                                  Aqui você pode escolher de onde quer receber as oportunidades
                                </IonLabel>
                                <IonSelect
                                  onIonChange={(event) => setLocation(event.target.value)}
                                  onIonDismiss={() => {
                                    if (location === '') {
                                      presentToast('Selecione um estado para prosseguir', 'warning')
                                    }
                                  }}
                                  okText='Filtrar'
                                  cancelText='Cancelar'
                                >
                                  {states.map((estado: any) => (
                                    <IonSelectOption key={estado.uf} value={estado.uf}>
                                      {estado.name}
                                    </IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                            </IonCol>
                              <IonButton color='primary' type='submit' style={{ width: '100%' }}>
                                Ir para oportunidades
                              </IonButton>
                            <br />
                            <br />
                          </IonRow>
                        </form>
                      </div>
                    </Container>
                  </IonCard>
                </IonCol>
              )}
          </IonRow>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default FinishRegistration
