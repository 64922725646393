import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonModal,
  IonTitle,
  IonButton,
  IonImg,
} from '@ionic/react'
import './nextEvents.css'
import { NextEventsPanel } from '../../features/nextEvents/components/NextEventsPanel'
import { Heading } from '../../components/Heading'
import { useNextEvents } from '../../features/nextEvents/hooks/useNextEvents'
import logo from '../../assets/img/logo-celebrar_Prancheta.png'
import { Container } from '../../components/Container'
import NfImage from '../../assets/NotasFiscais.png'

const NextEvents: React.FC = () => {
  const { nextEvents, getNextEvents, legacyEvents } = useNextEvents()
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    getNextEvents()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <img
              src={logo}
              alt='Logo Celebrar'
              width={160}
              height={24}
              className='ms-2 mb-1 logoCelebrar'
              style={{ marginLeft: '1rem ' }}
            />
            <IonButtons slot='start' />
            <IonMenuButton autoHide={true} className='menuBtn' />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding bg-ice'>
        <Container>
          <IonRow>
            <IonCol size='12'>
              <Heading level='h2'>Próximos Eventos Confirmados</Heading>
            </IonCol>

            {(nextEvents && nextEvents.length > 0) || (legacyEvents && legacyEvents.length > 0) ? (
              <NextEventsPanel legacyEvents={legacyEvents} nextEvents={nextEvents} />
            ) : (
              <IonCol size="12">
                <p>Ops, parece que você não tem nenhum evento disponivel</p>
              </IonCol>
            )}
          </IonRow>
        </Container>
      </IonContent>
      {/* <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} className="custom-modal">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Atenção</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding alert-modal"  >
          <div style={{ backgroundColor: '#502484', width: '100%', height: 'auto', minHeight: '100%' }}
          >
            <IonRow style={{ paddingTop: '5rem' }}>
              <IonCol size='12' sizeLg='4'>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <IonImg src={NfImage} style={{ width: '20rem' }} />
                </div>
              </IonCol>
              <IonCol size='12' sizeLg='6' style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: '#fff', padding: '2rem' }}>
                  <b>Atenção, Fornecedores!</b>
                  <br />
                  <br />
                  <b>Prazo Importante:</b> As Notas Fiscais emitidas em 2023 devem ser enviadas até <b>10/12/2023.</b>
                  <br />
                  Aquelas emitidas após essa data precisarão ser canceladas.
                  <br />
                  <br />
                  <b>Vendas Após 10/12/2023:</b> Se houver vendas após esta data, enviem as Notas Fiscais somente a partir de <b>01/01/2024</b>
                </div>
              </IonCol>
              <IonCol size='12' style={{ display: 'flex', justifyContent: 'center' }}>
                <IonButton onClick={() => setIsOpen(false)}>
                  Fechar
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonContent>
      </IonModal> */}
    </IonPage>
  )
}

export default NextEvents
