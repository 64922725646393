import { IonItem, IonList, IonToggle } from '@ionic/react';
import { useCategories } from '../hooks/useCategories';
import { useEffect } from 'react';
import { useToast } from '../../../hook/useToast';
import { ICategory, ISections } from '../categories.types';
import { Heading } from '../../../components/Heading';
import { Container } from '../../../components/Container';
import { useCompleteRegistration } from '../../auth/hooks/useCompleteRegistration';

interface ICategoriesProps {
  categoryId: number
  setCurrentStep?: any
}
interface ICategoriesAddList {
  id: number
}

interface IToggleEvent {
  checked: boolean,
  value: string
}

export interface ICategoriesImages {
  id: number,
  name: string,
  categoryId: number,
  url: string
}
export const CategoriesPanel: React.FC<ICategoriesProps> = ({ categoryId, setCurrentStep }: ICategoriesProps) => {
  const { categories: areas, userCategories, getCategoriesByParam, addCategoryForUser, deleteCategoriesByParam, getRegisteredCategories } = useCategories();
  const { presentToast } = useToast();
  const { company } = useCompleteRegistration()



  /**
   * The handleSortAdd function is an asynchronous function that accepts a categorie argument of type ICategoriesAddList.
   * The function adds the category for the user by calling the addCategoryForUser async function and then gets all the registered categories by calling getRegisteredCategories() function. 
   * After this, it presents a success toast message by calling presentToast() function.
   * @param categorie Accepts an object of type ICategoriesAddList.
   */
  async function handleSortAdd(categorie: ICategoriesAddList) {
    await addCategoryForUser(categorie.id)
      .then(() => getRegisteredCategories())
    presentToast('Categoria Adicionada', 'success');
    setCurrentStep(2)

  }


  async function handleSortDelete(categorie: ICategoriesAddList) {
    // Delete a category by ID
    await deleteCategoriesByParam(categorie.id)
      // Once the deletion is complete, update the list of registered categories with getRegisteredCategories()
      .then(() => getRegisteredCategories())
    // Show a toast notification when the category is deleted. Here, the success is represented by danger color.
    presentToast('Categoria Deletada', 'danger');
    if (userCategories?.length) {
      setCurrentStep(2)
    } else {
      setCurrentStep(1)
    }
  }


  // category: An object that represents a category.
  // details: An object containing details about the toggle event, such as whether it's checked or not.
  function handleToogleChange(category: ICategoriesAddList, details: IToggleEvent) {
    if (details.checked) handleSortAdd(category)
    else handleSortDelete(category)
  }

  useEffect(() => {
    getCategoriesByParam(categoryId);
    getRegisteredCategories();
  }, [categoryId])


  return (
    <Container>
      <div style={{ margin: '3rem 0rem' }}>
        {
          !company?.picture && !company?.supplierBio && !company?.supplierName ?
            <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 2 de 5</span>
            :
            <span style={{ fontSize: '0.7rem', color: 'grey' }}>Etapa 2 de 4</span>
        }
        <Heading level='h3'>
          Selecione abaixo as <br /> categorias que você atua
        </Heading>
      </div>
      {areas?.sections.map((sections: ISections, index) => {
        return (
          <IonList style={{ margin: '0' }} key={index}>
            {sections.categories.map((childCategorie: ICategory) => {
              return (
                <IonItem key={childCategorie.id} >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div>{childCategorie.name}</div>
                    <IonToggle
                      color='success'
                      checked={userCategories?.some(cat => cat.id === childCategorie.id)}
                      onIonChange={(event) => handleToogleChange(childCategorie, event.detail)}
                    />
                  </div>
                </IonItem >
              )
            })}
          </IonList>
        )
      })}

    </Container >
  )

}