import React, { useState, ReactNode } from 'react'
import { IonItem, IonLabel, IonIcon, IonToggle, IonRow, IonCol } from '@ionic/react'
import { caretDownOutline, caretForwardOutline } from 'ionicons/icons'
import { Container } from '../Container'

interface ICollapseGroup {
  children: ReactNode
}

interface ICollapseProps {
  title: string
  children: ReactNode
}

const CollapseGroup: React.FC<ICollapseGroup> = ({ children }: ICollapseGroup) => {
  return <IonRow style={{ margin: '2rem auto' }}>{children}</IonRow>
}

const Collapse: React.FC<ICollapseProps> = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <IonCol size='12' style={{ backgroundColor: 'white' }}>
      <IonItem onClick={toggleCollapse} button>
        <IonIcon slot='start' icon={isCollapsed ? caretForwardOutline : caretDownOutline} />
        <IonLabel>{title}</IonLabel>
        <IonToggle slot='end' checked={!isCollapsed} />
      </IonItem>
      <Container>{!isCollapsed && <div>{children}</div>}</Container>
    </IonCol>
  )
}

export { CollapseGroup, Collapse }
