import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonCol,
  IonModal,
  IonButton,
  IonTitle,
  useIonAlert,
} from '@ionic/react'
import './shelf.css'
import { Heading } from '../../components/Heading'
import { Container } from '../../components/Container'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import { ShelfModal } from '../../features/shelf/components/shelfModal'
import { useShelf } from '../../features/shelf/hooks/useShelf'
import { ShelfCard } from '../../features/shelf/components/shelfCard'
import { IShelfItem } from '../../features/shelf/shelf.types'
import { useAuth } from '../../features/auth/hooks/useAuth'
import { useHistory } from 'react-router'
import logo from '../../assets/img/logo-celebrar_Prancheta.png'
import { Link } from 'react-router-dom'

const Shelf: React.FC = () => {
  // Hooks
  const { shelfItems, getShelfItems } = useShelf()
  const { supplierUserData, getSupplierData } = useAuth()
  const [presentAlert] = useIonAlert()
  const history = useHistory()

  // States
  const [isModalOpen, setModalIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IShelfItem | null>(null)

  const enableLastOportunity = false

  useEffect(() => {
    getShelfItems()
  }, [])

  useEffect(() => {
    if (selectedItem) setModalIsOpen(true)
  }, [selectedItem])

  useEffect(() => {
    if (!isModalOpen) setSelectedItem(null)
  }, [isModalOpen])

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setModalIsOpen(false)
  }
  function handleSort() {
    getSupplierData()
    if (supplierUserData?.isRegistrationComplete ?? false) {
      setModalIsOpen(true)
    } else {
      presentAlert({
        header: 'Completar Cadastro!',
        subHeader: 'Para adicionar um item na prateleira você precisa finalizar o cadastro',
        cssClass: 'bg-ice',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Finalizar Cadastro',
            role: 'confirm',
            handler: () => {
              history.push('/cadastro')
            },
          },
        ],
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <img
              src={logo}
              alt='Logo Celebrar'
              width={160}
              height={24}
              className='ms-2 mb-1 logoCelebrar'
              style={{ marginLeft: '1rem ' }}
            />
            <IonButtons slot='start' />
            <IonMenuButton autoHide={true} className='menuBtn' />
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className='ion-padding bg-ice'>
        <Container>
          <IonRow className='ion-justify-content-center'>
            <IonCol size='12' size-lg='8'>
              <Heading level='h2'>prateleira</Heading>
              <p style={{ maxWidth: 700 }}>
                Na Prateleira, você disponibiliza seus produtos e/ou serviços de forma automática e simples para as oportunidades de vendas disponíveis e também para as Lojinhas de eventos.
              </p>
            </IonCol>
            <IonCol size='12' size-lg='3' style={{ marginTop: '2rem' }}>
              <Link to={'/prateleira/cadastrar-item'} style={{ textDecoration: 'none' }}>
                <IonButton
                  style={{ color: 'var(--color-white)' }}
                  // onClick={() => handleSort()}
                  expand='block'
                >
                  Adicionar Item
                </IonButton>
              </Link>
              {/* <IonButton
                style={{ color: 'var(--color-white)' }}
                onClick={() => handleSort()}
                expand='block'
              >
                Adicionar Item
              </IonButton> */}
              {enableLastOportunity && (
                  <IonButton href='/' fill='outline' expand='block'>
                    Ir para oportunidades
                  </IonButton>
              )}
            </IonCol>
          </IonRow>

          <IonRow className='ion-justify-content-center '>
            <IonCol sizeLg='12'>
              <IonModal
                className='bg-ice modal-fullscreen'
                isOpen={isModalOpen}
                onWillDismiss={(ev) => onWillDismiss(ev)}
              >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot='start'>
                      <IonButton
                        style={{ '--color': 'var(--color-white)' }}
                        onClick={() => setModalIsOpen(false)}
                      >
                        Fechar
                      </IonButton>
                    </IonButtons>
                    <IonTitle style={{ color: 'var(--color-white)' }}>
                      Adicionar Item a Prateleira
                    </IonTitle>
                  </IonToolbar>
                </IonHeader>

                <IonContent className='ion-padding'>
                  <IonRow className='ion-justify-content-center'>
                    <IonCol sizeLg='6'>
                      <ShelfModal shelfItem={selectedItem} />
                    </IonCol>
                  </IonRow>
                </IonContent>
              </IonModal>
            </IonCol>
          </IonRow>

          <IonRow>
            {shelfItems.map((item, index) => {
              return (
                <IonCol
                  size='12'
                  size-sm='12'
                  size-md='6'
                  size-lg='3'
                  key={index}
                  className='zoomOut'
                >
                  <ShelfCard shelfItem={item} setSelectedItem={setSelectedItem} />
                </IonCol>
              )
            })}
          </IonRow>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default Shelf
