import { useEffect, useState } from 'react'
import api from '../../../services/api'
import { IBalance, IPayments } from '../wallet.types'

interface IRequest {
  paymentIds: string[]
  paymentMethodId: string
}

function usePayments() {
  const [payments, setPayments] = useState<IPayments[]>([])
  const [avaliableBalance, setAvaliableBalance] = useState<IBalance>()

  useEffect(() => {
    getBalance()
    if(!avaliableBalance) getPayments()
  }, [])

  function getPayments() {
    api
      .get('/payments/supplier')
      .then((res) => setPayments(res.data))
      .catch((err) => console.error(err))
  }

  function getBalance() {
    api
      .get('/payments/supplier/availableBalance')
      .then((res) => setAvaliableBalance(res.data))
      .catch((err) => console.error(err))
  }

  const requestPayment = async (request: IRequest): Promise<boolean> => {
    try {
      api.post('/payments/supplier/requestWithdrawal', request)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  return {
    avaliableBalance,
    payments,
    requestPayment,
  }
}

export { usePayments }
