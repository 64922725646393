// Ionic components

import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonContent, IonTextarea, IonButton, IonLabel } from '@ionic/react'
import { Container } from '../../components/Container'
import { useEffect, useState } from 'react';
import Rating from '../../features/assessment/components/Rating';
import api from '../../api/api'
import { useParams } from 'react-router';
import logo from '../../assets/img/logo-celebrar_Prancheta.png'


const Assessment: React.FC = () => {
  const { id, supplierId, score } = useParams<{ id: string, score?: string, supplierId: string }>();
  const [ratingValue, setRatingValue] = useState(Number(score) || 10);
  const [comment, setComment] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [isError, setIsError] = useState(false)

  const handleRatingChange = (newValue: any) => {
    setRatingValue(newValue);
  }

  const handleCommentChange = (event: CustomEvent,) => {
    setComment(event.detail.value as string);
  }

  function submitNps() {
    api.post(`/reviews/${id}/supplier/${supplierId}`, { score: ratingValue, comments: comment })
      .then(() => window.location.reload())
      .catch(() => setIsError(true))
  }

  useEffect(() => {
    api.get(`reviews/${id}/supplier/${supplierId}`)
      .catch(({ response }) => {
        if (response.status === 404) {
          setNotFound(true)
        } else if (response.status === 409) {
          setAlreadyAnswered(true)
        } else {
          setIsError(true)
        }
      })
  }, []);

  return (
    <IonPage >
      <IonHeader>
        <IonToolbar>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <img
              src={logo}
              alt="Logo Celebrar"
              width={160}
              height={24}
              className="ms-2 mb-1 logoCelebrar"
            />
            <IonButtons slot="start" />
            <IonMenuButton autoHide={true} className='menuBtn' />

          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg-ice">
        {notFound ? (
          <h2 style={{ textAlign: 'center', marginTop: '3rem' }}>Página de avaliação não encontrada</h2>
        ) : alreadyAnswered ? (
          <h2 style={{ textAlign: 'center', marginTop: '3rem' }}>Recebemos sua resposta, obrigado pela sua avaliação!</h2>
        ) : isError ? (
          <h2 style={{ textAlign: 'center', marginTop: '3rem' }}>Ops, ocorreu um erro ao carregar a página. Tente novamente mais tarde.</h2>
        ) : (
          <Container>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', width: '98vw' }}>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '60vh' }}>
                <h2>De 0 a 10, o quanto você recomendaria a celebrar para um amigou ou familiar?</h2>
                <h1 style={{ marginTop: '3rem' }}>{ratingValue}</h1>
                <Rating value={ratingValue} onChange={handleRatingChange} />
                <IonLabel position="floating" style={{ textAlign: 'center', width: '100%', marginTop: '2rem' }}>Ajude-nos a melhorar, conte um pouco sobre sua nota:</IonLabel>
                <IonTextarea
                  placeholder="Coloque aqui seu comentario"
                  rows={6}
                  style={{ backgroundColor: 'white', maxWidth: '40rem', padding: '0.5rem', margin: '3rem 0rem' }}
                  onIonChange={(event) => handleCommentChange(event)}
                ></IonTextarea>
                <IonButton shape='round' onClick={submitNps} >Enviar</IonButton>
              </div>

            </div>
          </Container>
        )}
      </IonContent>
    </IonPage >
  )
}

export default Assessment
