import { ReactNode } from 'react'
// Styles
import './heading.css'

interface IHeadingProps {
  level: 'h1' | 'h2' | 'h3',
  children: ReactNode
}

export const Heading: React.FC<IHeadingProps> = ({ level = 'h2', children }: IHeadingProps) => {

  const HElement = level

  return (
      <HElement className='heading'>
        {children}
      </HElement>
  )
}