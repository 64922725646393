import { lazy } from 'react'
import { IonRouterOutlet, IonSplitPane } from '@ionic/react'

import { Route } from 'react-router-dom'
import { IonReactRouter } from '@ionic/react-router'
import { Menu } from '../components/Menu/menu'
import NextEvents from '../pages/nextEvents/NextEvents'
import Shelf from '../pages/shelf/Shelf'
import FinishRegistration from '../pages/auth/fullRegister/finishRegistration'
import { MenuMobile } from '../components/Menu/menuMobile'
import CreateShelf from '../pages/shelf/CreateShelf'
// Pages
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'))
const Categories = lazy(() => import('../pages/categories/Categories'))
const User = lazy(() => import('../pages/user/User'))
const Demand = lazy(() => import('../pages/demand/Demand'))
const Wallet = lazy(() => import('../pages/wallet/Wallet'))
const Assessment = lazy(() => import('../pages/assessment/Assessment'))
const previewLayout = lazy(() => import('../pages/previewLayout/previewLayout'))
const WebStore = lazy(() => import('../pages/webStore/WebStore'))
// Register
const NotFound = lazy(() => import('../pages/errors/NotFound'))

const MainRoutes: React.FC = () => {
  return (
    <IonReactRouter forceRefresh={true}>
      <Menu />
      <MenuMobile />
      <IonSplitPane contentId='main'>
        <IonRouterOutlet id='main'>
          <Route path='/' component={Dashboard} exact />
          <Route path='/categorias' component={Categories} exact />
          {/* <Route path='/cadastro/endereco' component={RegisterAddress} exact />
          <Route path='/cadastro/empresa' component={RegisterCompany} exact /> */}
          <Route path='/cadastro' component={FinishRegistration} exact />
          <Route path='/perfil' component={User} exact />
          <Route path='/eventos' component={NextEvents} exact />
          <Route path='/oportunidade/:id' component={Demand} exact />
          <Route path='/carteira' component={Wallet} exact />
          <Route path='/prateleira' component={Shelf} exact />
          <Route path='/prateleira/cadastrar-item' component={CreateShelf} exact />
          <Route path='/prateleira/editar-item/:itemid' component={CreateShelf} exact />
          <Route path='/webStore' component={WebStore} exact />
          {/* <Route path='/orcamentos' component={BudgetRequest} exact /> */}
          <Route path='/exemplo' component={previewLayout} exact />
          <Route component={NotFound} />
        </IonRouterOutlet>
      </IonSplitPane>
      <Route path='/avaliacao/:id/:supplierId/:score' component={Assessment} exact />
    </IonReactRouter>
  )
}

export default MainRoutes
