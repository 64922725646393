import { useEffect } from 'react';
import { useCategories } from '../../categories/hooks/useCategories';
import { IAreas } from '../../categories/categories.types';
import { IonButton, IonCard, IonRouterLink, IonRow, IonGrid, IonCol, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import useToast from '../../../hook/useToast';
import { categoriesImages } from '../../categories/components/CategoriesImages';
import { ICategoriesImages } from '../../categories/components/CategoriesPanel';
import './style.css'

export const CategoriesUserPanel: React.FC = () => {
  const { userCategories, getRegisteredCategories, deleteCategoriesByParam } = useCategories();
  const { presentToast } = useToast();

  // This function deletes a category for the current user by calling the deleteCategoriesByParam api with the category id. 
  // Once the category is deleted, it calls the getRegisteredCategories function to retrieve the updated list of registered 
  // categories for the user. Finally, it presents a toast with a message indicating that the category has been deleted.
  async function handleSortDelete(userCategories: IAreas) {
    await deleteCategoriesByParam(userCategories.id)
      .then(() => getRegisteredCategories())
    presentToast('Categoria Deletada', 'danger');
  }

  useEffect(() => {
    getRegisteredCategories();
  }, [])

  return (
    <IonGrid>
      <IonRow>
        {userCategories?.map((userCategory: IAreas) => {
          return (
            <IonCol key={userCategory.id} size='12' sizeMd='4' sizeSm='12' sizeLg='3'>
              <IonRouterLink>
                {categoriesImages.map((image: ICategoriesImages, index: number) => {
                  return image.categoryId === userCategory.id && (
                    <IonCard className='cardHeight' key={index}>
                      <img alt="Silhouette of mountains" src={image.url} style={{ width: '100%' }} />
                      <IonCardHeader>
                        <IonCardTitle style={{ fontSize: '1.25rem', minHeight: '3rem' }}>{userCategory.name}</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent style={{}}>
                        <IonButton
                          color="danger"
                          size='small'
                          expand='block'
                          onClick={() => handleSortDelete(userCategory)}>
                          Excluir
                        </IonButton>
                      </IonCardContent>
                    </IonCard>
                  )
                })}
              </IonRouterLink>
            </IonCol>
          )
        })}

      </IonRow>
    </IonGrid>
  )

}