const abbreviatedMonthName = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
]

function dateIsValid(date: any) {
  // Valid date format '0000-00-00'
  return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date)
}

function formatDate(date: string, format?: 'short' | 'long' | 'compare') {
  const dateObject = new Date(date);

  if (dateIsValid(dateObject)) {
    // Use métodos UTC para obter o dia, mês e ano no fuso horário UTC
    const day = dateObject.getUTCDate();
    const monthIndex = dateObject.getUTCMonth();
    const monthName = abbreviatedMonthName[monthIndex];
    const year = dateObject.getUTCFullYear();

    // Restaure a hora local para obter a data correta em relação ao fuso horário do sistema
    dateObject.setHours(0, 0, 0, 0);

    // Format the return
    const longFormat = `${day} de ${monthName} de ${year}`;
    const shortFormat = `${day} de ${monthName}`;
    const compareFormat = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}:00:00:00`;

    if (format) {
      return (
        (format === 'long' && longFormat) ||
        (format === 'short' && shortFormat) ||
        (format == 'compare' && compareFormat)
      );
    } else {
      return shortFormat;
    }
  } else {
    console.error('Date invalid');
  }
}

function calculateTimePassed(dateValue: string) {
  let timePassedMessage, timePassedClass

  if (dateIsValid(new Date(dateValue))) {
    const date = new Date(dateValue)
    const now = new Date()
    const microSecondsDiff = now.getTime() - date.getTime()
    const hoursPassed = Math.floor(microSecondsDiff / (1000 * 60 * 60))
    const daysPassed = Math.floor(hoursPassed / 24)

    switch (true) {
      case hoursPassed < 1:
        timePassedClass = 'green'
        timePassedMessage = '< 1 hora'
        break
      case hoursPassed === 1:
        timePassedClass = 'green'
        timePassedMessage = '1 hora'
        break
      case hoursPassed < 24:
        timePassedClass = 'green'
        timePassedMessage = `${hoursPassed} horas`
        break
      case hoursPassed < 24 * 2:
        timePassedClass = 'gold'
        timePassedMessage = '1 dia'
        break
      case hoursPassed < 24 * 4:
        timePassedClass = 'gold'
        timePassedMessage = `${daysPassed} dias`
        break
      default:
        timePassedClass = 'red'
        timePassedMessage = `${daysPassed} dias`
    }

    return { timePassedMessage, timePassedClass }
  } else {
    timePassedMessage = 'date invalid'
    timePassedClass = 'date invalid'
    console.error('Date invalid: ' + dateValue)
    return { timePassedMessage, timePassedClass }
  }
}

export { dateIsValid, formatDate, calculateTimePassed }
