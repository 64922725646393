import {
  CheckboxChangeEventDetail,
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react'
import { addCircleOutline, alertCircleOutline, closeCircle } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import {
  INewFacility,
  IShelfItem,
  IShelfNewItem,
  ISubItemsGroup,
  IValueRanges,
  IVariations,
} from '../../shelf.types'
import { useImageUpload } from '../../../imageUpload/hooks/useImageUpload'
import { useShelf } from '../../hooks/useShelf'
import useToast from '../../../../hook/useToast'
import { useCategories } from '../../../categories/hooks/useCategories'
import { categoriesImages } from '../../../categories/components/CategoriesImages'
import '../style.css'
import { useHistory } from 'react-router'
import ShelfSubItemForm from './shelfSubItemForm'
import { Container } from '../../../../components/Container'
import { IParams } from '../../../../pages/shelf/CreateShelf'
import ShelfFormPrice from './shelfFormPrice'
import './style.css'
import SheflFacilities from './shelfFacilities'
import ShelfVariation from './shelfVariations'
import ShelfVariationsList from './ShelfVariationsList'

interface IOfferProps {
  scrollToTop?: () => void
  shelfItem?: IShelfItem | null
  params?: IParams
}

type ValueRange = {
  initialUnit: number
  finalUnit: number
  value: number
  taxaDeRepasse: number
}

export interface ISelectedVariation {
  index: number
  variation: IVariations
}

export const ShelfForm: React.FC<IOfferProps> = ({ shelfItem, params }: IOfferProps) => {
  // Hooks
  const { postShelf, editShelf } = useShelf()
  const { presentToast } = useToast()
  const history = useHistory()
  // States
  const { userCategories, getRegisteredCategories } = useCategories()
  const [isOpenExempleSubItems, setIsOpenExempleSubItems] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [userLinks, setUserLinks] = useState<string[]>([])
  const [priceMethod, setPriceMethod] = useState<string>('')
  const [serviceType, setServiceType] = useState<string>('')
  const [selectedCategory, setCategory] = useState<any>('')
  const [InputServiceDuration, setServiceDuration] = useState<string>(
    shelfItem?.serviceDuration || '',
  )
  const [facilities, setFacilities] = useState<INewFacility[]>([])

  // upload image
  const [selectedImages, setSelectedImages] = useState<File[]>([])
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const { uploadImage, uploadedUrlImagesReturned } = useImageUpload()

  const [valueRangesData, setValueRangesData] = useState<IValueRanges[]>(
    shelfItem?.valueRanges || [],
  )
  const [initialUnit, setInitialUnit] = useState<number | null>(1)
  const [finalUnit, setFinalUnit] = useState<number | null>(null)
  const [value, setValue] = useState<number>(0)
  const [valueTypeSelected, setValueTypeSelected] = useState('')
  const [taxaDeRepasse, setTaxaDeRepasse] = useState<number>(10)
  const [lastRowMaxValue, setLastRowMaxValue] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [quantityAsNumberOfGuests, setQuantityAsNumberOfGuests] = useState<boolean>(true)
  const [hasVariation, setHasVariation] = useState<boolean>(false)
  const [isAvailableInWebstores, setIsAvailableInWebstores] = useState(false)
  const newSubItemsGroup: ISubItemsGroup = {
    name: '',
    quantity: 1,
    subItems: [{ name: '' }],
  }
  const [variations, setVariations] = useState<IVariations[]>([])
  const [selectedvariation, setSelectedVariation] = useState<ISelectedVariation | null>(null)
  const [subItemsGroups, setSubItemsGroups] = useState(
    shelfItem?.subItemGroups || [newSubItemsGroup],
  )

  useEffect(() => {
    getRegisteredCategories()
    return () => {
      previewImages.forEach((previewImage) => {
        URL.revokeObjectURL(previewImage)
      })
    }
  }, [])

  useEffect(() => {
    if (shelfItem) {
      setName(shelfItem?.name)
      setDescription(shelfItem?.description)
      setUserLinks(shelfItem?.links)
      setValueRangesData(shelfItem?.valueRanges)
      setPriceMethod(`Preço / ${shelfItem.priceMethod}`)
      setServiceType(shelfItem?.serviceType || '')
      matchingCategory()
      setFacilities(shelfItem.itemNeeds)
      setVariations(shelfItem.variations)
      if (shelfItem?.variations.length > 0) {
        setHasVariation(true)
      }
      if (shelfItem?.images && shelfItem?.images.length > 0) {
        setPreviewImages(shelfItem?.images)
      }
      if (shelfItem.valueRanges && shelfItem.valueType === 'unit')
        setValueRangesData(shelfItem.valueRanges)
      if (shelfItem.valueRanges && shelfItem.valueType === 'single')
        setValue(shelfItem.valueRanges[0].value)
      if (shelfItem.subItemGroups) setSubItemsGroups(shelfItem.subItemGroups)
      if (shelfItem.serviceDuration) {
        setServiceDuration(shelfItem?.serviceDuration)
      }
      if (shelfItem.isAvailableInWebstores) {
        setIsAvailableInWebstores(shelfItem?.isAvailableInWebstores || false)
      }
      if (shelfItem.priceMethod === 'Por pacote') {
        setValue(shelfItem.valueRanges[0].value)
      }
    }
  }, [shelfItem])

  function matchingCategory() {
    const matchingCategory = categoriesImages.find((e) => e.categoryId === shelfItem?.categoryId)
    if (matchingCategory) {
      setCategory(matchingCategory.categoryId)
    }
  }

  useEffect(() => {
    if (!selectedCategory) {
      matchingCategory()
    }
  }, [selectedCategory, shelfItem])

  useEffect(() => {
    setInitialUnit(0)
    if (!shelfItem) setValue(0)
    setTaxaDeRepasse(10)
    if (priceMethod === ' ') {
      setInitialUnit(1)
      setValueTypeSelected('single')
    } else {
      setValueTypeSelected('unit')
    }
    if (priceMethod === 'Preço / Por pessoa' || priceMethod === 'Preço / Por unidade') {
      setQuantityAsNumberOfGuests(false)
    } else {
      setQuantityAsNumberOfGuests(true)
    }
  }, [priceMethod])

  useEffect(() => {
    if (priceMethod === 'Preço / Por pacote') {
      const newValueRange: ValueRange = {
        initialUnit: initialUnit || 1,
        finalUnit: finalUnit !== null ? finalUnit : 0,
        value,
        taxaDeRepasse,
      }
      setValueRangesData([newValueRange])
    }
  }, [value])

  useEffect(() => {
    if (serviceType === 'delivery') {
      setServiceDuration('Não se aplica ')
    }
  }, [serviceType])

  useEffect(() => {
    if (uploadedUrlImagesReturned) {
      let _priceMethod = ''
      if (priceMethod === 'Preço / Por pessoa') {
        _priceMethod = 'Por pessoa'
      } else if (priceMethod === 'Preço / Por pacote') {
        _priceMethod = 'Por pacote'
      } else if (priceMethod === 'Preço / Por unidade') {
        _priceMethod = 'Por unidade'
      } else {
        _priceMethod = 'Por periodo'
      }
      if (
        selectedCategory &&
        ((hasVariation && variations.length > 0) || valueRangesData.length > 0)
      ) {
        item = {
          name: name,
          description: description,
          categoryId: Number(selectedCategory),
          images: shelfItem
            ? [
                ...new Set(
                  [
                    previewImages.filter((e) => !e.includes('blob')),
                    uploadedUrlImagesReturned,
                  ].flat(),
                ),
              ]
            : uploadedUrlImagesReturned,
          links: userLinks,
          priceMethod: _priceMethod,
          valueRanges: valueRangesData,
          valueType: valueTypeSelected,
          setQuantityAsNumberOfGuests: quantityAsNumberOfGuests,
          serviceDuration: InputServiceDuration,
          isAvailableInWebstores: isAvailableInWebstores,
          serviceType: serviceType,
          subItemGroups: subItemsGroups[0]?.name ? subItemsGroups : [],
          variations: variations,
          itemNeeds: facilities,
        }
        submitShelf()
      }
    }
  }, [uploadedUrlImagesReturned])

  async function submitShelf() {
    if (shelfItem) {
      const isSuccess = await editShelf(shelfItem.id, item)
      if (isSuccess) {
        const hasWebstores = Boolean(shelfItem.webstores?.length > 0)
        if (hasWebstores) {
          presentToast(
            'A solicitação de edição do item foi realizada. Em até 48 horas, uma de nossas especialistas irá analisar a sua solicitação!',
            'warning',
          )
        } else {
          presentToast('Item salvo com sucesso!', 'success')
        }
        setTimeout(
          () => {
            resetForm()
            history.push('/prateleira')
          },
          hasWebstores ? 3000 : 1500,
        )
      }
    } else {
      const itemId = await postShelf(item)
      if (itemId) {
        presentToast('Item salvo com sucesso!', 'success')
        setTimeout(() => {
          resetForm()
          history.go(-1)
          history.push('/prateleira')
        }, 1000)
      }
    }
  }

  function resetForm() {
    setValueRangesData([])
    setInitialUnit(1)
    setValue(0)
    setTaxaDeRepasse(10)
    clearForm()
  }

  const checkAcceptanceTerm = (event: CustomEvent<CheckboxChangeEventDetail>) => {
    const isAccepted = event.detail.checked
    setIsAvailableInWebstores(isAccepted)
  }

  // Declare a variable "item" of type IOfferItem
  let item: IShelfNewItem

  // Define a function that deletes the image at a specific index from the list of selected images and preview images that were uploaded
  const handleImageDelete = (index: number) => {
    // Create a copy of selectedImages array to avoid mutation
    const newSelectedImages = [...selectedImages]
    // Remove the image at the specified index using splice method
    newSelectedImages.splice(index, 1)
    // Update state with the modified newSelectedImages array
    setSelectedImages(newSelectedImages)

    // Create a copy of previewImages array to avoid mutation
    const newPreviewImages = [...previewImages]
    // Remove the image at the specified index using splice method
    newPreviewImages.splice(index, 1)
    // Update state with the modified newPreviewImages array
    setPreviewImages(newPreviewImages)

    // Revoke (release) the memory used by the objectURL generated for the removed image to improve performance
    URL.revokeObjectURL(previewImages[index])
  }

  // Event handler for image upload button
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      const selectedImagesArray = Array.from(files)

      const areAllImages = selectedImagesArray.every((file) => file.type.startsWith('image/'))

      if (areAllImages) {
        // Add newly selected images to existing selected images array
        setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...selectedImagesArray])

        // Create an array of preview URLs from all selected images
        const previewImagesArray = selectedImagesArray.map((image) => URL.createObjectURL(image))

        // Add newly created preview URLs to existing preview images array
        setPreviewImages((prevPreviewImages) => [...prevPreviewImages, ...previewImagesArray])
      } else {
        presentToast('Somente imagens são permitidas.', 'danger')
      }
    }
  }

  // Offer items
  function validateOfferItem(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const form = event.currentTarget
    const inputs: any = Array.from(form.querySelectorAll('input'))
    const textareas: any = Array.from(form.querySelectorAll('textarea'))
    const selects: any = Array.from(form.querySelectorAll('select'))
    const checkbox: any = Array.from(form.getElementsByClassName('checkbox'))
    const fields: any = [inputs, textareas, selects, checkbox].flat()

    let error = false

    fields.forEach((field: any) => {
      if (!error) {
        switch (true) {
          case priceMethod !== 'Preço / Por pacote' &&
            field.value === '' &&
            field.required === true:
            presentToast('Por favor, preencha todos os campos obrigatórios!', 'danger')
            error = true
            break
          case priceMethod === '':
            presentToast('Por favor, selecione um método de cobrança', 'danger')
            error = true
            break
          case valueRangesData === undefined || valueRangesData === null:
            presentToast('Por favor, preencha os preços!', 'danger')
            error = true
            break
          case serviceType === '':
            presentToast('Por favor, preencha o tipo de serviço!', 'danger')
            error = true
            break
          case selectedCategory === '':
            presentToast('Por favor, preencha a categoria!', 'danger')
            error = true
            break
          case !hasVariation && priceMethod === 'Preço / Por pacote' && value < 1:
            presentToast('Por favor, preencha um valor maior que zero!', 'danger')
            error = true
            break
          case hasVariation && variations.length < 1:
            presentToast('Por favor, insira ao menos uma variação para salvar', 'danger')
            error = true
            break
        }
      }
    })

    if (!error) {
      handleAddOfferItem()
    }
  }

  async function handleAddOfferItem() {
    // const uploadedImages:any = await uploadImage(selectedImages);
    if (selectedImages.length > 0) {
      await uploadImage(selectedImages, 'image', '')

      // Restante do código aqui
    } else {
      if (shelfItem) {
        await uploadImage(selectedImages, 'image', '')
        // presentToast('Nenhuma foto alterada', 'warning')
        return
      }
      presentToast('Nenhuma imagem selecionada', 'danger')
    }
  }

  function handleMethodPrice(value: string) {
    if (value) {
      setPriceMethod(value)
    }
  }
  function handleServiceType(value: string) {
    if (value) {
      setServiceType(value)
    }
  }

  function handleCategory(category: string) {
    setCategory(category)
  }

  function handleUserLinks(value: string) {
    const dataValue = value
    setUserLinks([dataValue])
  }
  function handleServiceDuration(value: string) {
    const dataValue = value
    setServiceDuration(dataValue)
  }

  function openEditForm(index: number) {
    const variationsData = [...variations]
    const variation = {
      index: index,
      variation: variationsData[index],
    }
    setSelectedVariation(variation)
  }

  function clearForm() {
    setName('')
    setDescription('')
    setUserLinks([''])
  }

  return (
    <form onSubmit={validateOfferItem} name='item' id='item' className='zoom-out'>
      <Container>
        <IonRow style={{ marginBottom: '4rem' }}>
          <IonCol size='12' sizeLg='12'>
            <h1 style={{ textAlign: 'left' }}> Novo item na prateleira </h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <div className='step'>Etapa 1 - Categoria</div>
            <div style={{ margin: '1rem 0rem' }}>
              <IonLabel className='custom'>
                Categoria do item <span style={{ color: 'red' }}>*</span>
              </IonLabel>
            </div>

            <IonItem className='custom mb-20'>
              <IonSelect
                interface='popover'
                name='priceMethod'
                placeholder='Selecione uma categoria'
                onIonChange={(event) => event.target.value && handleCategory(event.target?.value)}
                value={selectedCategory}
                defaultValue={selectedCategory}
              >
                {userCategories?.map((category, index) => {
                  return (
                    <IonSelectOption key={index} value={category.id} style={{ width: '15rem ' }}>
                      {category.name}
                    </IonSelectOption>
                  )
                })}
              </IonSelect>
            </IonItem>
            <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />
          </IonCol>
          <IonCol size='12'>
            <div className='step'>Etapa 2 - Informações do item</div>
            <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
              Preencha todos os dados do seu item cuidadosamente. Certifique-se de incluir o nome do
              item e a descrição detalhada.
            </p>
            <h6>
              Nome do Item <span style={{ color: 'red' }}>*</span>
            </h6>
            <IonItem className='custom mb-20'>
              <IonInput
                placeholder='Insira o nome do seu item'
                type='text'
                name='name'
                clearInput
                className='custom'
                value={name}
                required
                onIonInput={(event) =>
                  event.target.value && setName(event.target?.value.toString())
                }
              />
            </IonItem>
          </IonCol>
          <IonCol sizeLg='12'>
            <h6>
              Descrição <span style={{ color: 'red' }}>*</span>
            </h6>
            <IonItem className='custom'>
              <IonTextarea
                rows={5}
                placeholder='Descrição do item'
                name='description'
                autoGrow
                className='custom'
                value={description}
                required
                onIonInput={(event) =>
                  event.target.value && setDescription(event.target?.value.toString())
                }
              />
            </IonItem>
            <div style={{ marginTop: '0.5rem', fontSize: '0.85rem' }}>
              Para uma descrição completa, envie todas as informações sobre o seu serviço ou
              produto. Lembre-se de cuidar da grafia das palavras e evite incluir contatos da sua
              empresa, como telefone e e-mail.
            </div>
          </IonCol>

          <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />
          <IonCol>
            <div className='step'>Etapa 3 - Variações de itens</div>
            <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
              Nesta etapa, crie orçamentos distintos para as variações do seu item. Por exemplo, se
              você oferece um cardápio de happy hour com opções de bebida alcoólica e não alcoólica,
              inclua orçamentos específicos para cada caso, com detalhes dos itens e faixas de preço
              correspondentes.
            </p>
          </IonCol>

          {shelfItem && shelfItem.variations?.length > 0 ? null : (
            <IonCol size='12'>
              <IonToggle
                checked={hasVariation}
                onIonChange={() => setHasVariation(!hasVariation)}
              />{' '}
              Item possui variação?
            </IonCol>
          )}

          {hasVariation && (
            <>
              <IonCol size='12'>
                <ShelfVariationsList
                  variations={variations}
                  setVariations={setVariations}
                  selectedVariation={selectedvariation}
                  openEditForm={openEditForm}
                />
              </IonCol>

              <IonCol size='12'>
                <ShelfVariation
                  variations={variations}
                  setVariations={setVariations}
                  setIsOpenExempleSubItems={setIsOpenExempleSubItems}
                  params={params}
                  selectedVariation={selectedvariation}
                  priceMethod={priceMethod}
                  setPriceMethod={setPriceMethod}
                />
              </IonCol>
            </>
          )}

          {hasVariation && (
            <>
              <IonCol size='12' sizeLg='4'>
                <div style={{ marginBottom: '1rem ' }}>
                  <IonLabel className='custom mb-20'>
                    Tipo de serviço <span style={{ color: 'red' }}>*</span>
                  </IonLabel>
                </div>
                <IonItem className='custom mb-20'>
                  <IonSelect
                    interface='popover'
                    name='serviceType'
                    placeholder='Selecione o tipo de serviço'
                    value={serviceType}
                    onIonChange={(event) =>
                      event.target.value && handleServiceType(event.target?.value)
                    }
                  >
                    <IonSelectOption value='delivery'>Entrega</IonSelectOption>
                    <IonSelectOption value='service'>Serviço</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>

              {serviceType === 'service' ? (
                <IonCol size='12' sizeLg='4'>
                  <div style={{ marginBottom: '1rem ' }}>
                    <IonLabel className='custom mb-20'>
                      Duração <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                  </div>
                  <IonItem className='custom mb-20'>
                    <IonSelect
                      interface='popover'
                      name='serviceDuration'
                      placeholder='Selecione a duração'
                      value={InputServiceDuration}
                      onIonChange={(event) =>
                        event.target.value && handleServiceDuration(event.target.value.toString())
                      }
                    >
                      <>
                        <IonSelectOption value='1h'>1h</IonSelectOption>
                        <IonSelectOption value='2h'>2h</IonSelectOption>
                        <IonSelectOption value='3h'>3h</IonSelectOption>
                        <IonSelectOption value='4h'>4h</IonSelectOption>
                        <IonSelectOption value='5h'>5h</IonSelectOption>
                        <IonSelectOption value='6h'>6h</IonSelectOption>
                      </>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              ) : null}
            </>
          )}

          <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />

          {!hasVariation && (
            <>
              {/* ================ SUBITEMS GROUPS ================ */}
              <IonCol sizeLg='9' size='12'>
                <div className='step'>Etapa 4 - Complementos (Opcional)</div>
                <p style={{ marginTop: '-2.25rem', marginBottom: '3rem', maxWidth: '800px' }}>
                  Complementos são a maneira de personalizar seu item! Por exemplo, no seu cardápio
                  de coffee break, com variedades de salgados. Basta adicionar a seção “Salgados” e
                  incluir opções como “Pão de Queijo”, “Coxinha” e “Pão de Queijo vegano”.
                  <div
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                    onClick={() => setIsOpenExempleSubItems(true)}
                  >
                    Ver exemplo de preenchimento
                  </div>
                  <IonModal
                    isOpen={isOpenExempleSubItems}
                    onDidDismiss={() => setIsOpenExempleSubItems(false)}
                  >
                    <IonHeader>
                      <IonToolbar>
                        <IonTitle>Exemplo de preenchimento</IonTitle>
                        <IonButtons slot='end'>
                          <IonButton onClick={() => setIsOpenExempleSubItems(false)}>
                            Fechar
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <Container>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={process.env.PUBLIC_URL + '/assets/images/preenchimento.gif'}
                            alt='Descrição do GIF'
                            style={{ width: '15rem', height: 'auto' }}
                          />
                        </div>
                      </Container>
                    </IonContent>
                  </IonModal>
                </p>
              </IonCol>
              <IonCol
                sizeLg='3'
                size='12'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <IonButton
                  className='button-picture'
                  color={'success'}
                  style={{ marginRight: '1rem' }}
                  onClick={() => setSubItemsGroups([...subItemsGroups, newSubItemsGroup])}
                >
                  <IonIcon icon={addCircleOutline} />
                  <div style={{ color: 'white', marginLeft: '0.5rem' }}>Adicionar nova seção</div>
                </IonButton>
              </IonCol>

              {subItemsGroups?.map((group, index) => {
                return (
                  <ShelfSubItemForm
                    key={index}
                    index={index}
                    subitem={group}
                    subItemsGroup={subItemsGroups}
                    setSubItemsGroups={setSubItemsGroups}
                  />
                )
              })}

              {/* ================ PRICES ================ */}
              <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />
              <IonCol size='12'>
                <div className='step'>Etapa 5 - Precificação</div>
                <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
                  O modo de cobrança varia conforme o serviço: por exemplo, por unidade é sugerido
                  para brindes, por pessoa é sugerido para alimentação e por pacote é sugerido para
                  entretenimento.
                </p>
              </IonCol>
              <IonCol size='12' sizeLg='4'>
                <div style={{ marginBottom: '1rem ' }}>
                  <IonLabel className='custom mb-20'>
                    Tipo de serviço <span style={{ color: 'red' }}>*</span>
                  </IonLabel>
                </div>
                <IonItem className='custom mb-20'>
                  <IonSelect
                    interface='popover'
                    name='serviceType'
                    placeholder='Selecione o tipo de serviço'
                    value={serviceType}
                    onIonChange={(event) =>
                      event.target.value && handleServiceType(event.target?.value)
                    }
                  >
                    <IonSelectOption value='delivery'>Entrega</IonSelectOption>
                    <IonSelectOption value='service'>Serviço</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              {serviceType === 'service' ? (
                <IonCol size='12' sizeLg='4'>
                  <div style={{ marginBottom: '1rem ' }}>
                    <IonLabel className='custom mb-20'>
                      Duração <span style={{ color: 'red' }}>*</span>
                    </IonLabel>
                  </div>
                  <IonItem className='custom mb-20'>
                    <IonSelect
                      interface='popover'
                      name='serviceDuration'
                      placeholder='Selecione a duração'
                      value={InputServiceDuration}
                      onIonChange={(event) =>
                        event.target.value && handleServiceDuration(event.target.value.toString())
                      }
                    >
                      <>
                        <IonSelectOption value='1h'>1h</IonSelectOption>
                        <IonSelectOption value='2h'>2h</IonSelectOption>
                        <IonSelectOption value='3h'>3h</IonSelectOption>
                        <IonSelectOption value='4h'>4h</IonSelectOption>
                        <IonSelectOption value='5h'>5h</IonSelectOption>
                        <IonSelectOption value='6h'>6h</IonSelectOption>
                      </>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              ) : null}

              <ShelfFormPrice
                priceMethod={priceMethod}
                handleMethodPrice={handleMethodPrice}
                lastRowMaxValue={lastRowMaxValue}
                setLastRowMaxValue={setLastRowMaxValue}
                setValueRangesData={setValueRangesData}
                valueRangesData={valueRangesData}
                setValue={setValue}
                value={value}
              />
              <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />
            </>
          )}

          {/* ================ Facilities ================ */}
          <IonCol size='12'>
            <div className='step'>{`Etapa ${hasVariation ? 4 : 6} - Necessidades técnicas`}</div>
            <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
              Selecione as necessidades técnicas essenciais para a entrega do seu serviço ou
              produto.
            </p>
          </IonCol>

          <IonCol size='12'>
            <SheflFacilities
              categoryID={selectedCategory}
              facilitiesState={facilities}
              setFacilitiesState={setFacilities}
            />
          </IonCol>

          <hr style={{ width: '100%', backgroundColor: '#dbdada', margin: '3rem 0rem' }} />
          <IonCol size='12'>
            <div className='step'>{`Etapa ${hasVariation ? 5 : 7} - Imagens e links`}</div>
            <p style={{ marginTop: '-2.25rem', marginBottom: '3rem' }}>
              Incluir imagens e fotos dos produtos e serviços é fundamental para os clientes poderem
              ter uma referência visual do que está sendo oferecido. Use, sempre que possível, fotos
              autorais.
            </p>
          </IonCol>

          <IonCol size='12'>
            {!previewImages.length && (
              <p style={{ color: 'red' }}>
                <IonIcon
                  icon={alertCircleOutline}
                  style={{ fontSize: '1.5rem', marginBottom: '-0.25rem', marginRight: '0.5rem' }}
                />
                Atenção! Para continuar, é necessário adicionar pelo menos uma imagem.
              </p>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '0.25rem',
                padding: '1rem 0rem',
                width: '100%',
              }}
            >
              <label
                className='file-input-container'
                style={{ marginLeft: '1rem', cursor: 'pointer' }}
              >
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {previewImages.length > 1 && (
                    <IonButton
                      className='button-picture'
                      color={'success'}
                      style={{ marginRight: '1rem' }}
                    >
                      <IonIcon icon={addCircleOutline} />
                      <div style={{ color: 'white', marginLeft: '0.5rem' }}>
                        {previewImages.length} Imagens selecionadas
                      </div>
                    </IonButton>
                  )}
                  {previewImages.length == 1 && (
                    <IonButton
                      className='button-picture '
                      color={'success'}
                      style={{ marginRight: '1rem' }}
                    >
                      <IonIcon icon={addCircleOutline} />
                      <div style={{ color: 'white', marginLeft: '0.5rem' }}>
                        {previewImages.length} Imagem selecionada
                      </div>
                    </IonButton>
                  )}
                  {!previewImages.length && (
                    <IonButton
                      className='button-picture '
                      color={'success'}
                      style={{ marginRight: '1rem' }}
                    >
                      <IonIcon icon={addCircleOutline} />
                      <div style={{ color: 'white', marginLeft: '0.5rem' }}>
                        {' '}
                        Nenhuma imagem selecionada
                      </div>
                    </IonButton>
                  )}
                  <input
                    type='file'
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                </div>
              </label>
            </div>
          </IonCol>
          <IonCol size='12'>
            <IonRow>
              {previewImages?.map((previewImage, index) => {
                return (
                  <>
                    {previewImage != 'string[]' && (
                      <IonCol size='4' key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0.25rem',
                            borderRadius: '0.5rem',
                            height: '8rem',
                            backgroundImage: `url('${previewImage}')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <IonIcon
                            icon={closeCircle}
                            style={{ cursor: 'pointer' }}
                            size='medium'
                            onClick={() => handleImageDelete(index)}
                            color='danger'
                          ></IonIcon>
                        </div>
                      </IonCol>
                    )}
                  </>
                )
              })}
            </IonRow>
          </IonCol>

          <IonCol size='12' sizeLg='6'>
            <h6>Compartilhe os links para suas redes sociais e portfólio.</h6>
            <IonItem className='custom mb-20'>
              <IonInput
                placeholder='Links de suas redes sociais e portfólio'
                type='text'
                name='userLinks'
                clearInput
                value={userLinks[0]}
                className='custom'
                onIonInput={(event) =>
                  event.target.value && handleUserLinks(event.target.value.toString())
                }
              />
            </IonItem>
          </IonCol>
          <IonCol size='12'>
            <div style={{ marginTop: '-1rem', fontSize: '0.85rem' }}>
              Os links de referência podem ser úteis para complementar a descrição do serviço, como,
              por exemplo, adicionando links de videos em um orçamento para serviços de música ao
              vivo.
            </div>
          </IonCol>

          <IonCol
            size='12'
            style={
              isAvailableInWebstores === true
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2rem',
                    border: '1px solid #14c4a8',
                    backgroundColor: '#c4ecec',
                    padding: '2rem',
                    borderRadius: '0.5rem',
                  }
                : {
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2rem',
                    border: '1px solid #FF960A',
                    backgroundColor: '#ffd30d35',
                    padding: '2rem',
                    borderRadius: '0.5rem',
                  }
            }
          >
            <IonCheckbox
              checked={isAvailableInWebstores}
              onIonChange={checkAcceptanceTerm}
              style={{ padding: '0.45rem 0.45rem', marginRight: '0.5rem' }}
            />

            <IonLabel className='custom'>
              Autorizo a venda deste item de forma automática, com uma antecedência mínima de 5
              dias, para locais onde já realizei visita técnica.
            </IonLabel>
          </IonCol>
          <div
            style={{ color: 'blue', marginTop: '0.5rem', cursor: 'pointer' }}
            onClick={() => setIsOpen(true)}
          >
            <IonIcon
              icon={alertCircleOutline}
              style={{ fontSize: '1.5rem', marginBottom: '-0.25rem', marginRight: '0.5rem' }}
            />
            <span style={{ color: 'blue', textDecoration: 'underline' }}>
              Entenda como isso pode potencializar suas vendas
            </span>
          </div>
          <IonAlert
            isOpen={isOpen}
            message={
              'Com as Lojinhas você terá a chance de divulgar seus produtos e serviços recorrentemente para grandes empresas, conquistando mais vendas e impulsionando o crescimento da sua empresa.'
            }
            buttons={['Fechar']}
            onDidDismiss={() => setIsOpen(false)}
          />

          <IonButton
            type='submit'
            style={{ width: '100%', margin: '2rem 0rem' }}
            disabled={
              hasVariation
                ? Boolean(variations.length < 1)
                : Boolean(
                    valueRangesData.length < 1 ||
                      (priceMethod.toLocaleLowerCase() === 'preço / por pacote' && value < 1),
                  )
            }
          >
            Salvar item
          </IonButton>
        </IonRow>
      </Container>
    </form>
  )
}
