import SignRoutes from './signRoutes';
import MainRoutes from './mainRoutes';
import { useAuth } from '../features/auth/hooks/useAuth';
import { Suspense } from 'react';
import { LoaderPage } from '../components/LoaderPage';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  return (
    <Suspense fallback={<LoaderPage />}>
      {signed ? <MainRoutes /> : <SignRoutes />}
    </Suspense>
  )
};

export default Routes;