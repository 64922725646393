// Ionic components
import {
  IonContent,
  IonItem,
  IonLabel,
  IonMenu,
  IonRouterLink,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { useHistory } from 'react-router-dom'

import './menu.css'
import { useAuth } from '../../features/auth/hooks/useAuth'

export const MenuMobile: React.FC = () => {
  const { logOut } = useAuth();
  const history = useHistory();

  function handleLogOut() {
    logOut()
    history.go(0)
  }

  return (
    <>
      <IonMenu contentId='main-content' side='end'>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='bg-white'>
          <IonRouterLink href='/webStore'>
            <IonItem >
              <IonLabel>Lojinhas</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/'>
            <IonItem button style={{ cursor: 'pointer' }}>
              <IonLabel>Painel de vendas</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/prateleira'>
            <IonItem button style={{ cursor: 'pointer' }}>
              <IonLabel>Prateleira</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/eventos'>
            <IonItem button style={{ cursor: 'pointer' }}>
              <IonLabel>Próximos eventos</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/carteira'>
            <IonItem button style={{ cursor: 'pointer' }}>
              <IonLabel>Carteira</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/categorias'>
            <IonItem button style={{ cursor: 'pointer' }}>
              <IonLabel>Categorias</IonLabel>
            </IonItem>
          </IonRouterLink>

          <IonRouterLink href='/perfil'>
            <IonItem button style={{ cursor: 'pointer' }}>
              Ver perfil
            </IonItem>
          </IonRouterLink>

          <IonItem
            button
            lines='none'
            detail={false}
            onClick={handleLogOut}
            style={{ cursor: 'pointer' }}
          >
            <IonLabel>Sair</IonLabel>
          </IonItem>
        </div>
      </IonMenu>
      <IonPage id='main-content'>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          Tap the button in the toolbar to open the menu.
        </IonContent>
      </IonPage>
    </>
  )
}
