import { useState } from 'react'
import api from '../../../api/api'
import { IFacilities, INewFacility } from '../shelf.types'

export function useFacilities(){
  // States
  const [isLoading, setIsLoading] = useState(false)

  const getFacilitiesByCategory = async (categoryID: number):Promise<IFacilities | boolean> =>{
    setIsLoading(true)
    try{
      const response = await api.get<IFacilities>(`/categories/${categoryID}/facilities`)
      const facilities = response.data
      if(facilities){
        return facilities
      } else return false
    } catch (err){
      console.error(err)
      return false
    } finally{
      setIsLoading(false)
    }
  }

  const addFacility= async (shelfItemID: string, facility: INewFacility):Promise<boolean> =>{
    setIsLoading(true)
    try{
      await api.post<INewFacility>(`/supplierShelf/supplier/items/${shelfItemID}/needs`, facility)
      return true
    } catch (err){
      console.error(err)
      return false
    } finally{
      setIsLoading(false)
    }
  }

  const removeFacility= async (shelfItemID: string, facilityID: string):Promise<boolean> =>{
    setIsLoading(true)
    try{
      await api.delete(`supplierShelf/supplier/items/${shelfItemID}/needs/${facilityID}`)
      return true
    } catch (err){
      console.error(err)
      return false
    } finally{
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    getFacilitiesByCategory,
    addFacility,
    removeFacility
  }
}