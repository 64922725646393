import { useState } from 'react';
import api from '../../../api/api';
import { ILegacyEvent, INextEvents } from '../nextEvents.types';


export function useNextEvents() {
  const [nextEvents, setNextEvents] = useState<INextEvents[]>();
  const [legacyEvents, setLegacyEvents] = useState<ILegacyEvent[]>()

  // This code defines a function called getNextEvents. This function uses an instance of axios to get data from an API endpoint, 
  // which returns confirmed events that are associated to the supplier.
  function getNextEvents() {
    api.get('/events/supplier/confirmations').then(response => {
      setNextEvents(response.data.confirmations)
      setLegacyEvents(response.data.legacyConfirmations)
    });
  }

  return {
    nextEvents, getNextEvents, legacyEvents
  }
}
