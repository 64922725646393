import { useEffect, useState } from 'react'
import api from '../../../api/api'
import { IAddress } from '../company.types'

interface ICompanyData {
  picture: string
  supplierName: string
  supplierBio: string
  props: IProps
}

interface IProps {
  supplierId: number
  name: string
  knownName: string
  cnpj: string
  managerName: string
  managerCpf: string
  address: IAddress
  phone: string
}

export function useCompleteRegistration() {
  const [company, setCompany] = useState<ICompanyData | undefined>()
  const [address, setAddress] = useState<IAddress>()

  useEffect(() => {
    const companyData:string | null = window.sessionStorage.getItem('company')
    if(companyData){
      const companyObject = JSON.parse(companyData)
      if(companyObject.props) {
        setCompany(companyObject)
        if(companyObject.props.address) setAddress(companyObject.props.address)
        else getCompany()
      } else getCompany()
    } 
    else getCompany()
  }, [])
  
  const getCompany = async (): Promise<ICompanyData | undefined> => {
    try {
      const companyData = (await api.get<ICompanyData>('/suppliers/company')).data
      if (companyData) {      
        setCompany(companyData)
        window.sessionStorage.setItem('company', JSON.stringify(companyData))
        setAddress(companyData.props.address)
      }
      return companyData
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  return {
    company,
    address,
    getCompany,
  }
}
