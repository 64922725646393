import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { ReactNode } from 'react'

interface IContainerProps {
  children: ReactNode
}

export const Container: React.FC<IContainerProps> = ({ children }: IContainerProps) => {
  return (
    <IonGrid >
      <IonRow >
        <IonCol size='auto' />
        <IonCol sizeLg='10' sizeSm='11' style={{ margin: 'auto' }}>{children}</IonCol>
        <IonCol size='auto' />
      </IonRow>
    </IonGrid>
  )
}