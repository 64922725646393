import { useState } from 'react'
import api from '../../../api/api'
import { IShelfItem, IShelfNewItem, IShelfRequests, ISubmittedOffer } from '../shelf.types'

export function useShelf() {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [submittedOffer, setSubmittedOffer] = useState<ISubmittedOffer[]>([])
  const [shelfItems, setShelfItems] = useState<IShelfItem[]>([])
  const [shelfRequests, setShelfRequests] = useState<IShelfRequests[]>([])
  const [shelfItem, setShelfItem] = useState<IShelfNewItem>()

  const getShelfItems = async():Promise<boolean | IShelfItem[]> => {
    setIsLoading(true)
      try {
        const res = await api.get('/supplierShelf/supplier/items')
        setShelfItems(res.data)
        return res.data
      } catch (error) {
        console.error(error)
        return false
      } finally {
        setIsLoading(false)
      }
  }

  // This code defines a function called getNextEvents. This function uses an instance of axios to get data from an API endpoint,
  // which returns confirmed events that are associated to the supplier.

  const postShelf = async (item: IShelfNewItem):Promise<string | boolean> => {
    setIsLoading(true)
    try{
      const itemID = await api.post('/supplierShelf', item)
      if(itemID.data.id) return itemID.data.id
      else return false
    } catch (err) {
      console.error(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const editShelf = async (itemId: string, item: IShelfNewItem):Promise<boolean> => {
    setIsLoading(true)
    try{
      await api.post(`/supplierShelf/supplier/items/${itemId}/edit`, item)
      return true
    } catch (err) {
      console.error(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  function deleteShelfItem(itemId: string) {
    api
      .delete(`/supplierShelf/supplier/items/${itemId}`)
      .then(() => getShelfItems())
      .catch((err) => console.error(err))
  }

  // -------------- Requests
  function getShelfRequests() {
    api
      .get('/supplierShelf/requests')
      .then((res) => setShelfRequests(res.data))
      .catch((err) => console.error(err))
  }

  function acceptRequest(shelfRequestId: string, expirationDate: string) {
    api
      .post(`/supplierShelf/requests/${shelfRequestId}/accept`, { expirationDate: expirationDate })
      .then(() => getShelfRequests())
      .catch((err) => console.error(err))
  }

  function rejectRequest(shelfRequestId: string) {
    api
      .post(`/supplierShelf/requests/${shelfRequestId}/reject`)
      .then(() => getShelfRequests())
      .catch((err) => console.error(err))
  }

  // SUBMITED OFFERS
  function getSubmittedOffer() {
    api
      .get('/demands/supplier/offers')
      .then((res) => setSubmittedOffer(res.data))
      .catch((err) => console.error(err))
  }

  return {
    isLoading,
    shelfItems,
    shelfItem,
    shelfRequests,
    setShelfItem,
    postShelf,
    editShelf,
    deleteShelfItem,
    getShelfItems,
    getShelfRequests,
    setSubmittedOffer,
    getSubmittedOffer,
    submittedOffer,
    acceptRequest,
    rejectRequest,
  }
}
